import { Card, Col, Row } from "antd";
import React from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import AddDepenseForm from "./AddDepenseForm";
import UpdateDepenseForm from "./UpdateDepenseForm";

function AddDepensePage() {
  const history = useHistory();
  const location = useLocation();
console.log(location.state?.record);
  const goback = () => {
    history.push("/Depensev2");
  }
  return (
    <Card headStyle={{ backgroundColor: "#dee0e9" }} title={
      <>
        <Row> 
          <Col span={10}><span onClick={() => goback()} className="goback"> Retour </span></Col>
          <Col span={14}>{location.state?.record ? <>Mise à jour Charge({location.state?.record.titre}) </> : <> Ajouter une Charge </>}</Col>
        </Row> 
      </>
    }>
      {location.state?.record ?
        <UpdateDepenseForm depense={location.state.record}></UpdateDepenseForm> :
        <AddDepenseForm></AddDepenseForm>
      }
        

    </Card>
  );
}
export default AddDepensePage;
