import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons";
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    InputNumber,
    Modal,
    Row,
    Select,
    Spin,
    Tooltip
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Can from "../../../Can/Can";
import RapportHelperService from "../../../services/bilan.service";
import { default as exportMessages, default as messages } from "../../../UIcomponent/messages/messages";
import AppTable from "../../../UIcomponent/Table/AppTable";
import NotAuthorized from "../../NotAuthorizedPage/NotAuthorizedPage";

function BalanceConfig() {
    const { user: currentUser } = useSelector((state) => state.auth);
    const editor = useRef(null)
    const [rubriqueModal, setRubriqueModal] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [rubriqueForm] = Form.useForm();
    const [dataTable, setDataTable] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [updateId, setUpdateId] = useState();

    const options = [{code: 0 , text:"Aucune condition"}, {code: 1 , text:"Si la valeur est positif"}, {code: -1 , text:"Si la valeur est negatif"}]

    useEffect(() => {
      getRapportHelpers();
      return () => {
        clearStates();
      };
    }, []);
  
    const clearStates = () => {
      setDataTable([]);
      setEditMode(false);
      setUpdateId(null);
    };

    const getRapportHelpers = async () => {
      setLoading(true);
      const response = await RapportHelperService.getRapportHelpers();
      if (response.status === 200 || 204) initTableData(response.data);
      else messages.errorMessage();
      setLoading(false);
    };
    
    const RapportHelperColumn = [
      {
        title: "Titre",
        dataIndex: "attribut",
        sorter: (a, b) => a.attribut.localeCompare(b.attribut),
        width: "85%",
      },{
        title: "Action",
        key: "action",
        render: (text, record) => (
          <div>
            <Tooltip title="Mettre à jour">
              {(!!currentUser|| Can("10_3")) && (
                <Button
                  className="mx-1"
                  type="dashed"
                  shape="circle"
                  onClick={() => updateMode(record)}
                  icon={<EditOutlined />}
                />
              )}
            </Tooltip>
            <Tooltip title="Supprimer">
              {(!!currentUser|| Can("10_4")) && (
                <Button
                  className="mx-1"
                  type="dashed"
                  shape="circle"
                  onClick={() => confirmDelete(record)}
                  icon={<DeleteOutlined />}
                />
              )}
            </Tooltip>
          </div>
        ),
      },
    ];
  
    const confirmDelete = (record) => {
      Modal.confirm({
        title: "Attention",
        icon: <ExclamationCircleOutlined />,
        content: "Êtes-vous sûr de vouloir supprimer ce section ? ",
        onOk: () => deleteItem(record),
        okText: "Oui",
        cancelText: "Non",
      });
    };
    const updateMode = (record) => {
      setRubriqueModal(true);
      rubriqueForm.setFieldsValue(record);
      setUpdateId(record.bilan_id);
      setEditMode(true);
    };
  
    const initTableData = (data) => {
      setDataTable(data);
    };
  
    const deleteItem = async (item) => {
      console.log(item);
      const response = await RapportHelperService.deleteHelper(item.bilan_id);
      if (response.status === 200 || 204) {
        messages.addSuccess("Suppression avec succès ! ");
        getRapportHelpers(response.data);
      } else messages.errorMessage();
    };
  
    const closingModal = () => {
      getRapportHelpers();
      setRubriqueModal(false);
      setConfirmLoading(false);
      clearData();
    };
  
    const addHelper = async (values) => {
      setLoading(true);
      const data = rubriqueForm.getFieldsValue()
      const newNote = {attribut: data.attribut, val : 0  , description : data.description , tableName: "helper"}
      const response = editMode
        ? await RapportHelperService.updateHelperNote(updateId, newNote) 
        : await RapportHelperService.addNewNote( newNote );
      if (response.status === 200 || 204) {
        if (editMode) updateSuccess();
        else addSuccess();
        
        getRapportHelpers()
      }else exportMessages.errorMessage();
      setLoading(false);
    };
  
    const closeRubriqueModal = () => {
      setRubriqueModal(false);
      setConfirmLoading(false);
      clearData();
    };
  
    const clearData = () => {
      rubriqueForm.resetFields();
      setEditMode(false);
      setUpdateId(null);
    };
  
    const addSuccess = () => {
      messages.addSuccess("Rubrique ajoutée avec succès ");
      closingModal();
    };
    const updateSuccess = () => {
      messages.updateSuccess("Mise à jour de la rubrique avec success");
      closingModal();
    };
    const errorMessage = () => {
      setConfirmLoading(false);
      messages.errorMessage();
    };
  
    return (
      <Spin spinning={isLoading} size="large">
        {(!!currentUser|| Can("10_1")) ? (
          <Card
            headStyle={{ backgroundColor: "#dee0e9" }}
            title="Liste des comptes par rubrique "
            style={{ width: "100%" }}
            extra={
              (!!currentUser|| Can("10_2")) && (
                <Button
                  className="mx-2"
                  shape="round"
                  type="primary"
                  onClick={() => setRubriqueModal(true)}
                >
                  Ajouter une Rubrique
                </Button>
              )
            }
          >
            <AppTable data={dataTable} columns={RapportHelperColumn} />
          </Card>
        ) : (
          <NotAuthorized />
        )}
        <Modal
          title="Ajouter une rubrique"
          centered
          okText="Enregistrer"
          cancelText="Annuler"
          getContainer={false}
          confirmLoading={confirmLoading}
          visible={rubriqueModal}
          onOk={rubriqueForm.submit}
          onCancel={closeRubriqueModal}
          width={700}
        >
          <Form layout="vertical" form={rubriqueForm} onFinish={addHelper}>
            <Row>
              <Col span={24} className="px-2">
                <Form.Item
                  label="Nom de la rubrique"
                  name="rubrique"
                  rules={[
                    {
                      required: true,
                      message:
                        "Champ obligatoire avec maximum de caractère 100 !",
                      max: 99,
                    },
                  ]}
                >
                  <Input
                    size="large"
                    type="text"
                    maxLength={100}
                  />
                </Form.Item>
              </Col>
              <Col span={12} className="px-2">
                <Form.Item
                  label="Numéro de compte commance par"
                  name="startWith"
                  rules={[
                    {
                      required: true,
                      message:"Champ obligatoire avec maximum de caractère 100 !",
                      max: 99,
                    },
                  ]}
                >
                    <InputNumber
                        style={{ width: "100%" }}
                        size="large"
                        min={0}
                    />
                </Form.Item>
              </Col>
              <Col span={12} className="px-2">
                <Form.Item
                  label="Condition"
                  name="condition"
                  rules={[
                    {
                      required: true,
                      message:"Champ obligatoire !",
                    },
                  ]}
                >
                    <Select size="large">
                        {options.map((c) => (
                            <Select.Option
                                key={c.code}
                                value={c.code}
                            >
                                {c.text}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Spin>
    );
}
export default BalanceConfig;
