import authHeader from "./auth-header";

import axios from "axios";
import { environment } from "../env/config.env";
const API_URL = environment.api;
let   EntrepriseId = (localStorage.getItem('entreprise'))
const addPaiementMoyen = (paiement_type) => {
  return axios.post(API_URL + "moyenpaiment/"+ EntrepriseId, paiement_type, {
    headers: authHeader(),
  });
};

const getPaiementMoyens = () => {
  EntrepriseId = (localStorage.getItem('entreprise'))
  return axios.get(API_URL + "moyenpaiments/" + EntrepriseId, { headers: authHeader() });
};

const getPaiementMoyen = (id) => {
  return axios.get(API_URL + "moyenpaiment/" + id, { headers: authHeader() });
};

const updatePaiementMoyen = (id, paiement_type) => {
  return axios.put(API_URL + "moyenpaiment/" + id, paiement_type, {
    headers: authHeader(),
  });
};

const deletePaiementMoyen = (id) => {
  return axios.delete(API_URL + "moyenpaiment/" + id, {
    headers: authHeader(),
  });
};

const exportMethodes = {
  addPaiementMoyen,
  getPaiementMoyens,
  getPaiementMoyen,
  updatePaiementMoyen,
  deletePaiementMoyen,
};
export default exportMethodes;
