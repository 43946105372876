import { Table } from "antd";
import { Empty } from "antd";

const AppTable = ({ data, columns, expandableRow, clas }) => {
  const locale = {
    emptyText: (
      <Empty description={"Le tableau est vide pour le moment "}></Empty>
    ),
  };
  const onChange = (pagination, filters, sorter, extra) => {};
  return (
    <Table
      className={clas}
      expandable={{ expandableRow }}
      onChange={onChange}
      showSorterTooltip={false}
      rowKey="nom"
      locale={locale}
      columns={columns}
      dataSource={data}
      pagination={{ position: ["bottomCenter"], size: "small" , pageSize: 5 }}
    />
  );
};
export default AppTable;
