import {
  Button,
  Modal,
  Card,
  Form,
  Tooltip,
  Input,
  Row,
  Col,
  Spin,
  Select,
} from "antd";
import {
  ExclamationCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import AppTable from "../../../UIcomponent/Table/AppTable";
import FrequenceService from "../../../services/frequence.service";
import messages from "../../../UIcomponent/messages/messages";
import Can from "../../../Can/Can";
import NotAuthorized from "../../NotAuthorizedPage/NotAuthorizedPage";
import { useSelector } from "react-redux";

function Frequence() {
  const [addFrequenceModal, setaddFrequenceModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [moyenForm] = Form.useForm();
  const [dataTable, setDataTable] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    getFrequences();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const getFrequences = async () => {
    setLoading(true);
    const response = await FrequenceService.getAllFrequence();
    if (response.status === 200 || 204) initTableData(response.data);
    else messages.errorMessage();
    setLoading(false);
  };

  const FrequenceColumn = [
    {
      title: "Période",
      width: "35%",
      dataIndex: "periode",
      sorter: (a, b) => a.periode - b.periode,
    },
    {
      title: "Type",
      width: "40%",

      dataIndex: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            {(!!currentUser|| Can("13_3")) && (
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => updateMode(record)}
                icon={<EditOutlined />}
              />
            )}
          </Tooltip>
          <Tooltip title="Supprimer">
            {(!!currentUser|| Can("13_4")) && (
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => confirmDelete(record)}
                icon={<DeleteOutlined />}
              />
            )}
          </Tooltip>
        </div>
      ),
    },
  ];

  const confirmDelete = (record) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer cette fréquence ? ",
      onOk: () => deleteItem(record),
      okText: "Oui",
      cancelText: "Non",
    });
  };
  const updateMode = (record) => {
    setaddFrequenceModal(true);
    moyenForm.setFieldsValue(record);
    setUpdateId(record.frequence_id);
    setEditMode(true);
  };

  const initTableData = (data) => {
    setDataTable(data);
  };

  const deleteItem = async (item) => {
    const response = await FrequenceService.deleteFrequence(item.frequence_id);
    if (response.status === 204 || 200) {
      messages.addSuccess("Suppression avec succès ! ");
      getFrequences();
    } else messages.errorMessage();
  };

  const closingModal = () => {
    getFrequences();
    setaddFrequenceModal(false);
    setConfirmLoading(false);
    clearData();
  };

  const addFrequence = async (values) => {
    setConfirmLoading(true);
    setaddFrequenceModal(true);
    setLoading(true);
    setConfirmLoading(true);
    const response = editMode
      ? await FrequenceService.updateFrequence(updateId, values)
      : await FrequenceService.addFrequence(values);
    if (response.status === 200 || 204) {
      if (editMode) updateSuccess();
      else addSuccess();
    } else errorMessage();
    closingModal();
  };

  const closeContratModal = () => {
    setaddFrequenceModal(false);
    clearData();
  };

  const clearData = () => {
    moyenForm.resetFields();
    setEditMode(false);
    setUpdateId(null);
  };

  const addSuccess = () => {
    messages.addSuccess("Fréquences ajouté avec succès");
    closingModal();
  };
  const updateSuccess = () => {
    messages.updateSuccess("Mise à jour success");
    closingModal();
  };
  const errorMessage = () => {
    setConfirmLoading(false);
    messages.errorMessage();
  };

  return (
    <Spin spinning={isLoading} size="large">
      {(!!currentUser|| Can("13_1")) ? (
        <Card
          headStyle={{ backgroundColor: "#dee0e9" }}
          title="Liste des Fréquences "
          style={{ width: "100%" }}
          extra={
            (!!currentUser|| Can("13_2")) && (
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                onClick={() => setaddFrequenceModal(true)}
              >
                Ajouter une fréquence
              </Button>
            )
          }
        >
          <AppTable data={dataTable} columns={FrequenceColumn} />
        </Card>
      ) : (
        <NotAuthorized />
      )}
      <Modal
        title={editMode ? "Modifier une fréquence" : "Ajouter une fréquence"}
        centered
        okText="Enregistrer"
        cancelText="Annuler"
        getContainer={false}
        confirmLoading={confirmLoading}
        visible={addFrequenceModal}
        onOk={moyenForm.submit}
        onCancel={closeContratModal}
        width={600}
      >
        <Form layout="vertical" form={moyenForm} onFinish={addFrequence}>
          <Row>
            <Col span={24} className="px-2">
              <Form.Item
                label="Période"
                name="periode"
                rules={[
                  {
                    required: true,
                    message: "Champ obligatoire avec maximum de caractère 15 !",
                    max: 14,
                  },
                ]}
              >
                <Input
                  placeholder="Exemple : 1 Mois"
                  size="large"
                  type="number"
                  maxLength={15}
                />
              </Form.Item>
            </Col>
            <Col span={24} className="px-2">
              <Form.Item
                label="Type"
                name="type"
                rules={[{ required: true, message: "Champ obligatoire !" }]}
              >
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  defaultValue="--Sélectionner un type--"
                >
                  <Select.Option value="jours">Jour(s)</Select.Option>
                  <Select.Option value="mois">Mois</Select.Option>
                  <Select.Option value="année">Année(s)</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Spin>
  );
}
export default Frequence;
