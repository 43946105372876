import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    LoadingOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import "antd/dist/antd.css";
import messagealert from "../../UIcomponent/messages/messages";
import "../Profit_Center/profitCenter.css";
import {
    Space,
    Modal,
    Card,
    Button,
    Row,
    Col,
    Input,
    Form,
    Tabs,
    Select,
    Tooltip,
    Spin,
    Table,
    Switch,
    InputNumber,
    Badge,
    Avatar,
    Popover,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllProfitCenters } from "../../actions/profitCenter";
import categorieService from "../../services/categorie.service";
import Can from "../../Can/Can";
import profitCenterService from "../../services/profitCenter.service";

import ProfitCenter from "../Profit_Center/ProfitCenter";
import profitCentreService from "../../services/profitCenter.service";
import NotAuthorized from "../NotAuthorizedPage/NotAuthorizedPage";
import categorieTypeService from "../../services/categorie-type.service";
import { List } from "antd/lib/form/Form";

const Categorie = ({
    cats,
    showModalAddProf,
    prof_id,
    dataSous,
    parentId,
    showMoreDetailsProf,
    getAllProfitCenter,
    catParent,
}) => {
    const { user: currentUser } = useSelector((state) => state.auth);

    const { Option } = Select;
    let location = useLocation();
    const locationPa = location.pathname !== "/profitcenter";
    const dispatch = useDispatch();
    const { TextArea } = Input;

    const { TabPane } = Tabs;
    const [categorie, setCategorie] = useState();
    const [niveau, setNiveau] = useState();
    const [catsToAdd, setCatsToAdd] = useState();
    const [niveauSous, setNiveauSous] = useState();
    const [selectedValuesProfCenter, setSelectedValuesProfCenter] = useState();
    const [selectedValuesSousCats, setSelectedValuesSousCats] = useState();
    const [etatProf, setEtatProf] = useState(true);
    const [categorieTypes, setCategorieTypes] = useState();
    const [mode, setMode] = useState("");
    const [id, setId] = useState();
    const [moyenFormProf] = Form.useForm();
    const [addProfToCateg, setAddProfToCateg] = useState(false);
    const [moyenForm] = Form.useForm();
    const [moyenSousForm] = Form.useForm();
    const [sousCategories, setSousCategories] = useState();
    const [parentCategorie, setParentCategorie] = useState();
    const [visible, setVisible] = useState(false);
    const [visibleModalDelete, setVisibleModalDelete] = useState(false);
    const [profitCenters, setProfitCenters] = useState([]);
    const [upadtaAdd, setUpadtaAdd] = useState(false);
    const [upadtaAddSousCateg, setUpadtaAddSousCateg] = useState(false);
    const [types, setTypes] = useState(
        [
            "Nourriture",
            "Loisirs",
            "Voiture",
            "Assurances",
            "Impôts",
            "Abonnements",
            "Loyer et charges",
            "Les produits d’entretien",
            "Santé",
        ].map((el) => {
            return (
                <Option label={el} value={el}>
                    {el}
                </Option>
            );
        })
    );
    const [upadtaAddButton, setUpadtaAddButton] = useState(false);

    const [recordData, setrecordData] = useState({});
    const [categos, setCategos] = useState();
    const [catNiveau, setCatNiveau] = useState(0);
    const [addProfitCenter, setAddProfitCenter] = useState(false);
    const [addSousCategoires, setAddSousCategoires] = useState(false);

    const [loading, setLoading] = useState(true);
    const [provinceData, setprovinceData] = useState();

    const getCategorieTypes = async () => {
        const types = await (
            await categorieTypeService.getTypeCategories()
        ).data.map((el) => (
            <Option value={el.category_type_id} label={el.categorie_type_nom}>
                {el.categorie_type_nom}
            </Option>
        ));
        setCategorieTypes(types);
    };

    const getAllProfitCentertoCateg = async () => {
        const profCens = await profitCentreService.getProfitCenters();

        return profCens.data.reverse();
    };
    const niveaux = [0, 1, 2, 3, 4, 5];
    const showModalAddSousCateg = () => {
        let minimum;
        minimum = recordData
            ? recordData.categorie_niveau
            : moyenForm.getFieldValue("categorie_niveau")
            ? moyenForm.getFieldValue("categorie_niveau")
            : 0;
        let newNiveau = niveaux
            .filter((el) => el > minimum)
            .map((el) => (
                <Option label={el} value={el}>
                    {el}
                </Option>
            ));
        setNiveauSous(newNiveau);

        setUpadtaAddSousCateg(true);
    };
    const getAllCategorie = async () => {
        const response = await categorieService.getCategories();
        const categories = response.data;
        categories.reverse();
        const filtredCategories = [];
        console.log(categories);
        for (const categorie of categories) {
            if(categorie.etat){
                let type = categorie.categorie_type;
                categorie.categorie_type_nom = type.categorie_type_nom;
                filtredCategories.push(categorie);
            }
        }

        setCategos(filtredCategories);
        setLoading(false);
        return getAllCategorie;
    };

    const getProfitCenters = () => {
        getAllProfitCentertoCateg().then((ellm) => {
            const provinceDat = ellm.map((el) => (
                <Option label={el.profitCenter_nom} value={el.profitCenter_id}>
                    {el.profitCenter_nom}
                </Option>
            ));

            setprovinceData(provinceDat);
        });
    };
    const categories = cats
        ? cats
        : dataSous
        ? dataSous
        : categos && categos.filter((el) => el.categorieParent === null);

    const catsToAddfunc = async (allCats, niveau, cat) => {
        let newCatstoAdd = allCats.filter((el) => {
            let test;
            let test1;
            if (el.categorie_niveau > niveau) test = true;
            if (el.categorieParent === null) {
                test1 = true;
            } else if (cat) {
                if (el.categorieParent.category_id === cat.category_id) {
                    test1 = true;
                } else {
                    test1 = false;
                }
            }
            return test && test1;
        });
        let newCatstoAdd1 = newCatstoAdd.map((el) => {
            return (
                <Option label={el.categorie_nom} value={el.category_id}>
                    {el.categorie_nom}
                </Option>
            );
        });
        return newCatstoAdd1;
    };

    let category;
    const addSouscate = async (cat) => {
        let msg;
        if (mode !== "add") {
            msg = await categorieService.addsousCat(
                recordData.category_id,
                cat
            );
            if ([200 || 201].includes(msg.status)) {
                messagealert.addSuccess("sous Catégorie a été bien ajoutée");

                getAllCategorie().then((el) => {
                    catsToAddfunc(
                        el,
                        recordData.categorie_niveau,
                        recordData
                    ).then((n) => {
                        setCatsToAdd(n);
                    });
                });

                let selectdsousCats = msg.data.sousCategories.map(
                    (el) => el.category_id
                );
                setSelectedValuesSousCats(selectdsousCats);
                moyenSousForm.resetFields();
            } else {
                messagealert.errorMessage();
            }

            setrecordData(msg.data);
        } else {
            msg = await categorieService.addCategorie(cat);
            if (msg.status === 201 || 200) {
                getAllCategorie().then((el) => {
                    catsToAddfunc(
                        el,
                        moyenForm.getFieldValue("categorie_niveau")
                    ).then((n) => {
                        setCatsToAdd(n);
                    });
                });

                messagealert.addSuccess("sous Catégorie a été bien ajoutée");
            } else {
                messagealert.errorMessage();
            }
        }

        setUpadtaAddSousCateg(false);
        moyenSousForm.resetFields();
    };
    const handleChange = (value) => {
        let valueParsed = value.map((el) => {
            return parseInt(el);
        });
        console.log(value)
        setSelectedValuesProfCenter(value);
        setCategorie({ ...categorie, profitCenter_id: valueParsed });
    };
    const onChange = (checked) => {
        setAddProfitCenter(checked);
    };
    const onchangeAddSousCaetg = (checked) => {
        setAddSousCategoires(checked);
    };
    const handleChangeSousCats = (value) => {
        let valueParsed = value.map((el) => {
            return parseInt(el);
        });
        setSelectedValuesSousCats(value);
        setCategorie({ ...categorie, categories: valueParsed });
    };

    const showModalUpdate = async (record) => {
        let recordNiv = record ? record.categorie_niveau : 0;
        setCatNiveau(recordNiv);
        let recoord = record;
        setrecordData(recoord);

        record && (await catsToAddfunc(categos, record.categorie_niveau, record).then(
                (el) => {
                    let y = el;
                    setCatsToAdd(y);
                }
            ));

        record ? setMode("edit") : setMode("add");
        let maximum =
            record && record.sousCategories.length !== 0
                ? Math.min(
                      ...record.sousCategories.map((el) => el.categorie_niveau)
                  ) - 1
                : 5;
        let minimumm =
            record && record.categorieParent !== null
                ? record.categorieParent.categorie_niveau + 1
                : 0;
        let newNiveau = niveaux
            .filter((el) => el <= maximum && el >= minimumm)
            .map((el) => <Option value={el}>{el}</Option>);
        setNiveau(newNiveau);
        setUpadtaAddButton(true);

        record &&
            record.profitCenters &&
            record.profitCenters.length &&
            setAddProfitCenter(true);
        record &&
            record.sousCategories &&
            record.sousCategories.length &&
            setAddSousCategoires(true);
        setVisible(false);
        setLoading(true);

        if (record !== undefined) {
            const recordFormatted = {
                ...record,
                categorie_niveau: parseInt(record.categorie_niveau),
                categorie_type: record.categorie_type.category_type_id,
            };
            moyenForm.setFieldsValue(recordFormatted);

            if (record.profitCenters !== undefined) {
                setSelectedValuesProfCenter(
                    record.profitCenters.map((el) => el.profitCenter_id)
                );
            }
            if (record.sousCategories !== undefined) {
                setSelectedValuesSousCats(
                    record.sousCategories.map((el) => el.category_id)
                );
            }
        }

        setUpadtaAdd(true);
        setLoading(false);
    };

    const showModalAddProfToCateg = () => {
        setEtatProf(true);
        setAddProfToCateg(true);

        moyenFormProf.setFieldsValue({ profitCenter_etat: etatProf });
    };
    const formatSousCategories = (sousCategories) => {
        console.log(sousCategories);
        const formattedSousCategories = [];
        for (const sousCategorie of sousCategories) {
            if( sousCategorie.etat ){
                let type = sousCategorie?.categorie_type?.categorie_type_nom;
                sousCategorie.categorie_type_nom = type;
                formattedSousCategories.push(sousCategorie)
            }
        }

        return formattedSousCategories;
    };
    const showMoreDetails = async (id, record) => {
        setLoading(true);
        setrecordData(record);
        setId(id);
        if (record.sousCategories.length !== 0) {
            record.sousCategories = formatSousCategories(record.sousCategories);
        }
        moyenForm.setFieldsValue(record);
        let res = await categorieService.getCategorie(id);
        category = res.data;
        setCategorie(record);
        const profitCenters = category.profitCenters;
        setSousCategories(category.sousCategories);
        setParentCategorie(
            category.categorieParent === undefined
                ? []
                : category.categorieParent
        );
        setProfitCenters(profitCenters);
        setVisible(true);
        setLoading(false);
    };

    const onUpdateCategorie = async (cat) => {
        setUpadtaAddButton(false);
        setLoading(true);
        setVisible(false);

        setVisibleModalDelete(true);
        if (!locationPa) {
            cat = { ...cat, profitCenter_id: [prof_id] };
        } else {
            cat = {
                ...cat,
                profitCenter_id: selectedValuesProfCenter,
            };
        }
        if (recordData) {
            cat = { ...cat, parent_id: recordData.category_id };
        }
        cat = {
            ...cat,
            categories: selectedValuesSousCats,
        };
        const msg =
            mode === "edit"
                ? await categorieService.updateCategorie(id, cat)
                : await categorieService.addCategorie(cat);
        if (msg.status === 200 || 201) {
            await getAllCategorie();
            if (!locationPa) {
                await getAllProfitCenter().then(() => {
                    showMoreDetailsProf(prof_id);
                });
            }
            if (mode === "edit") {
                messagealert.updateSuccess("catégorie a été bien mise à jour");
            } else {
                messagealert.addSuccess("catégorie a été bien ajoutée");
            }
        } else {
            messagealert.errorMessage();
        }
        setLoading(false);
        setId();
        moyenForm.resetFields();

        setUpadtaAdd(false);
        setSelectedValuesSousCats();

        if (parentId) {
            setVisible(false);
            showMoreDetails(parentId);
        }
        setCatNiveau(0);
        setCategorie();
        setAddProfitCenter(false);
        setAddSousCategoires(false);
    };
    const ondeleteCategorie = async (id) => {
        const msg = await categorieService.deleteCategorie(id);
        msg.status === 200
            ? messagealert.addSuccess("catégorie a été suppimée avec succées")
            : messagealert.errorMessage();
        getAllCategorie();
    };
    const closeModalAddProfToCateg = () => {
        setAddProfToCateg(false);
        setEtatProf(true);
        setUpadtaAddSousCateg(false);
        moyenSousForm.resetFields();

        moyenFormProf.resetFields();
    };
    const handleProvinceChangeEtat = (value) => {
        setEtatProf(value);
    };
    const handleCancel = () => {
        setMode();
        setAddProfitCenter(false);
        setAddSousCategoires(false);
        setCatNiveau(0);
        setVisible(false);
        setVisibleModalDelete(false);
        setUpadtaAdd(false);
        setSelectedValuesProfCenter();
        setSelectedValuesSousCats();
        moyenForm.resetFields();
    };
    useEffect(() => {
        getProfitCenters();
        getAllCategorie();
        getCategorieTypes();
    }, [recordData, catsToAdd]);

    const columns = [
        {
            title: () => <Tooltip title="Nom de catégorie">Nom</Tooltip>,
            dataIndex: "categorie_nom",
            sorter: (a, b) => a.categorie_nom.localeCompare(b.categorie_nom),
        },
        {
            title: () => <Tooltip title="Niveau de catégorie">Niveau</Tooltip>,
            dataIndex: "categorie_niveau",
            sorter: (a, b) => a.categorie_niveau - b.categorie_niveau,
            fixed: true,
        },
        {
            title: () => <Tooltip title="Type de catégorie">Type</Tooltip>,
            dataIndex: "categorie_type_nom",
            sorter: (a, b) => a.categorie_type.localeCompare(b.categorie_type),
            fixed: true,
        },
        {
            title: () => (
                <Tooltip title="Description de catégorie">Description</Tooltip>
            ),
            dataIndex: "categorie_description",
            sorter: (a, b) =>
                a.categorie_description.localeCompare(b.categorie_description),
            fixed: true,
        },
        locationPa && !parentId
            ? {
                  title: () => (
                      <Tooltip title="Centres de profit associés à catégorie">
                          C.P
                      </Tooltip>
                  ),
                  dataIndex: "profitCenters",
                  sorter: (a, b) =>
                      a.profitCenters.length - b.profitCenters.length,
                  fixed: true,

                  key: "profitCenters",
                  render: (profitCenters) => (
                      <>
                          <Popover
                              content={
                                  profitCenters.length !== 0
                                      ? profitCenters.map((el) => (
                                            <p>- {el.profitCenter_nom}</p>
                                        ))
                                      : "Aucun Centre de profit"
                              }
                              title={<h6>Liste des centres de profit</h6>}
                          >
                              <span className="avatar-item">
                                  <Badge count={profitCenters.length}>
                                      <Avatar
                                          style={{
                                              backgroundColor:
                                                  profitCenters.length === 0
                                                      ? "red"
                                                      : "#7265e6",
                                              verticalAlign: "middle",
                                          }}
                                          size="middle"
                                      >
                                          C.P
                                      </Avatar>{" "}
                                  </Badge>
                              </span>
                          </Popover>
                      </>
                  ),
              }
            : {},
        {
            title: () => (
                <Tooltip title="Sous catégories appartiennent à catégorie">
                    S.C
                </Tooltip>
            ),
            dataIndex: "sousCategories",
            sorter: (a, b) => a.sousCategories.length - b.sousCategories.length,
            fixed: true,
            key: "sousCategories",

            render: (sousCategories) => (
                <>
                    <Popover
                        content={
                            sousCategories.length !== 0
                                ? sousCategories.map((el) => (
                                      <p> - {el.categorie_nom}</p>
                                  ))
                                : "Aucune Catégorie"
                        }
                        title={<h6>Liste des sous catégories</h6>}
                    >
                        {" "}
                        <span className="avatar-item">
                            <Badge count={sousCategories.length}>
                                <Avatar
                                    style={{
                                        backgroundColor:
                                            sousCategories.length === 0
                                                ? "red"
                                                : "#00a2ae",
                                        verticalAlign: "middle",
                                    }}
                                    size="middle"
                                >
                                    S.C
                                </Avatar>{" "}
                            </Badge>
                        </span>
                    </Popover>
                </>
            ),
        },

        locationPa && !parentId
            ? {
                  title: "Actions",
                  key: "action",
                  width: "180px",

                  render: (text, record) => (
                      <div>
                          {" "}
                          {locationPa && !parentId && (Can("4_3")|| !!currentUser.type) && (
                              <Tooltip title="Éditer cette catégorie ">
                                  <Button
                                      className="mx-1"
                                      type="dashed"
                                      shape="circle"
                                      onClick={() => {
                                          showModalUpdate(record);
                                          setMode("edit");
                                          setVisibleModalDelete(false);
                                          setId(record.category_id);
                                      }}
                                      icon={<EditOutlined />}
                                  />
                              </Tooltip>
                          )}
                          {locationPa && !parentId &&(Can("4_4")|| !!currentUser.type) && (
                              <Tooltip title="supprimer cette catégorie">
                                  <Button
                                      className="mx-1"
                                      type="dashed"
                                      shape="circle"
                                      onClick={() => {
                                          Modal.confirm({
                                              type: "info",
                                              title: "Attention",
                                              icon: (
                                                  <ExclamationCircleOutlined />
                                              ),
                                              content:
                                                  "Etes vous sur de vouloir supprimer cette catégorie ? ",
                                              onOk: () => {
                                                  ondeleteCategorie(
                                                      record.category_id
                                                  );
                                                  setVisibleModalDelete(false);
                                              },
                                              okText: "Oui",
                                              cancelText: "Non",
                                          });
                                      }}
                                      icon={<DeleteOutlined />}
                                  />
                              </Tooltip>
                          )}
                          {locationPa && !parentId &&(Can("4_5")|| !!currentUser.type)&& (
                              <Tooltip title="voir plus détails">
                                  <Button
                                      className="mx-1"
                                      type="dashed"
                                      shape="circle"
                                      onClick={() => {
                                          showMoreDetails(
                                              record.category_id,
                                              record
                                          );
                                      }}
                                      icon={<EyeOutlined />}
                                  />
                              </Tooltip>
                          )}
                      </div>
                  ),
              }
            : {},
    ];
    const handleNiveauchange = (value) => {
        catsToAddfunc(categories, value).then((n) => setCatsToAdd(n));
    };
    const handleTypechange = (value) => {};
    const handleTypechangesous = (value) => {
        moyenSousForm.setFieldsValue({ categorie_type: value });
    };
    const onAddProfToCatg = async (prof) => {
        let response;
        let selectdProfs;
        if (mode !== "add") {
            response = await categorieService.addProfCenterToCateg(
                recordData.category_id,
                prof
            );
            selectdProfs = response.data.profitCenters.map(
                (el) => el.profitCenter_id
            );
        } else {
            response = await profitCenterService.addProfitCenter(prof);
        }
        if (response.status === 200 || 201) {
            getAllCategorie();
            messagealert.addSuccess("Centre de profit  a été bien ajouté");
            getAllProfitCentertoCateg().then((ellm) => {
                mode !== "add" &&
                    recordData &&
                    setSelectedValuesProfCenter(selectdProfs);
                const provinceDat = ellm.map((el) => (
                    <Option
                        label={el.profitCenter_nom}
                        value={el.profitCenter_id}
                    >
                        {el.profitCenter_nom}
                    </Option>
                ));
                setprovinceData(provinceDat);
            });
        } else {
            messagealert.errorMessage();
        }
        setAddProfToCateg(false);
        moyenFormProf.resetFields();

        setEtatProf(true);
    };
    const onClickAddCategorie = async () => {
        setMode("add");

        setVisibleModalDelete(true);
        showModalUpdate();
    };

    const expandedRowRender = (t, index) => {
        t.sousCategories = formatSousCategories(t.sousCategories);
        const columnsSousCats = [
            {
                title: "Nom",
                dataIndex: "categorie_nom",
                sorter: (a, b) =>
                    a.categorie_nom.localeCompare(b.categorie_nom),
                fixed: true,
            },
            {
                title: "Niveau",
                dataIndex: "categorie_niveau",
                sorter: (a, b) => a.categorie_niveau - b.categorie_niveau,
                fixed: true,
            },
            {
                title: "Type",
                dataIndex: "categorie_type_nom",
                sorter: (a, b) =>
                    a.categorie_type.localeCompare(b.categorie_type),
                fixed: true,
            },
            {
                title: "Description",
                dataIndex: "categorie_description",
                sorter: (a, b) =>
                    a.categorie_description.localeCompare(
                        b.categorie_description
                    ),
                fixed: true,
            },

            {
                title: () => (
                    <Tooltip title="Sous catégories appartiennent à catégorie">
                        S.C
                    </Tooltip>
                ),
                dataIndex: "sousCategories",
                sorter: (a, b) =>
                    a.sousCategories.length - b.sousCategories.length,
                fixed: true,
                key: "sousCategories",

                render: (sousCategories) => (
                    <>
                        <Popover
                            content={
                                sousCategories.length !== 0
                                    ? sousCategories.map((el) => (
                                          <p> - {el.categorie_nom}</p>
                                      ))
                                    : "Aucune Catégorie"
                            }
                            title={<h6>Liste des sous catégories</h6>}
                        >
                            {" "}
                            <span className="avatar-item">
                                <Badge count={sousCategories.length}>
                                    <Avatar
                                        style={{
                                            backgroundColor:
                                                sousCategories.length === 0
                                                    ? "red"
                                                    : "#00a2ae",
                                            verticalAlign: "middle",
                                        }}
                                        size="middle"
                                    >
                                        S.C
                                    </Avatar>{" "}
                                </Badge>
                            </span>
                        </Popover>
                    </>
                ),
            },

            locationPa && !parentId
                ? {
                      title: "Actions",
                      key: "action",
                      width: "180px",

                      render: (text, record) => (
                          <div>
                              {" "}
                              {locationPa && !parentId && Can("4_2") && (
                                  <Tooltip title="Éditer cette catégorie ">
                                      <Button
                                          className="mx-1"
                                          type="dashed"
                                          shape="circle"
                                          onClick={() => {
                                              showModalUpdate(record);
                                              setMode("edit");
                                              setVisibleModalDelete(false);
                                              setId(record.category_id);
                                          }}
                                          icon={<EditOutlined />}
                                      />
                                  </Tooltip>
                              )}
                              {locationPa && !parentId && Can("4_4") && (
                                  <Tooltip title="supprimer cette catégorie">
                                      <Button
                                          className="mx-1"
                                          type="dashed"
                                          shape="circle"
                                          onClick={() => {
                                              Modal.confirm({
                                                  type: "error",
                                                  title: "Attention",
                                                  icon: (
                                                      <ExclamationCircleOutlined />
                                                  ),
                                                  content:
                                                      "Cette catégorie sera supprimée définitivement,  êtes vous sur de vouloir supprimer cette catégorie ? ",
                                                  onOk: () => {
                                                      ondeleteCategorie(
                                                          record.category_id
                                                      );
                                                      setVisibleModalDelete(
                                                          false
                                                      );
                                                  },
                                                  okText: "Oui",
                                                  cancelText: "Non",
                                              });
                                          }}
                                          icon={<DeleteOutlined />}
                                      />
                                  </Tooltip>
                              )}
                              {locationPa && !parentId && Can("4_5") && (
                                  <Tooltip title="voir plus détails">
                                      <Button
                                          className="mx-1"
                                          type="dashed"
                                          shape="circle"
                                          onClick={() => {
                                              showMoreDetails(
                                                  record.category_id,
                                                  record
                                              );
                                          }}
                                          icon={<EyeOutlined />}
                                      />
                                  </Tooltip>
                              )}
                          </div>
                      ),
                  }
                : {},
        ];

        return t.sousCategories.length !== 0 ? (
            <Table
                columns={columnsSousCats}
                showSorterTooltip={false}
                rowKey={(record) => record.category_id}
                dataSource={t.sousCategories}
                pagination={{ position: ["bottomCenter"], size: "small" }}
                expandable={{ expandedRowRender }}
            />
        ) : (
            <>
                Aucune sous catégorie pour : <b> {t.categorie_nom}</b>
            </>
        );
    };
    const formProf = (
        <div>
            <Row>
                <Col span={24} className="px-2">
                    <Form.Item
                        label="Nom de centre de profit"
                        name="profitCenter_nom"
                        rules={[
                            {
                                required: !visible,
                                message:
                                    "Champ obligatoire avec maximum de caractère 255 !",
                                max: 254,
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            type="text"
                            maxLength="255"
                            disabled={visible}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24} className="px-2">
                    <Form.Item
                        label="Description de centre de profit"
                        name="profitCenter_description"
                        rules={[
                            {
                                required: !visible,
                                message:
                                    "Champ obligatoire avec maximum de caractère 255 !",
                                max: 254,
                            },
                        ]}
                    >
                        {
                            <TextArea
                                size="large"
                                type="text"
                                maxLength="255"
                                disabled={visible}
                            />
                        }
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
    const form = (
        <Form layout="vertical" form={moyenForm} onFinish={onUpdateCategorie}>
            <Row>
                <Col span={12} className="px-2">
                    {visible ? (
                        <Form.Item
                            label="Nom de catégorie"
                            name="categorie_nom"
                            rules={[
                                {
                                    required: !visible,
                                    message: "Champ obligatoire !",
                                },
                            ]}
                        >
                            <> {categorie.categorie_nom}</>
                        </Form.Item>
                    ) : (
                        <Form.Item
                            label="Nom de catégorie"
                            name="categorie_nom"
                            rules={[
                                {
                                    required: !visible,
                                    message: "Champ obligatoire !",
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                type="text"
                                required
                                disabled={visible}
                            />
                        </Form.Item>
                    )}
                </Col>
                <Col span={12} className="px-2">
                    <Form.Item
                        label="Description de catégorie"
                        name="categorie_description"
                        rules={[
                            {
                                required: !visible,
                                message:
                                    "Champ obligatoire avec maximum de caractère 255 !",
                                max: 254,
                            },
                        ]}
                    >
                        {visible ? (
                            <>{categorie.categorie_description} </>
                        ) : (
                            <Input
                                size="large"
                                type="text"
                                required
                                maxLength="255"
                                disabled={visible}
                            />
                        )}
                    </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                    <Form.Item
                        label="Type de catégorie"
                        name="categorie_type"
                        rules={[
                            {
                                required: !visible,
                                message: "Champ obligatoire !",
                            },
                        ]}
                    >
                        {visible ? (
                            <>{categorie.categorie_type.categorie_type_id} </>
                        ) : (
                            <Select size="large" onChange={handleTypechange}>
                                {categorieTypes}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                    <Form.Item
                        label="Niveau de catégorie"
                        name="categorie_niveau"
                        rules={[
                            {
                                required: true,
                                message:
                                    "Champ accepte que des chiffres entre 0 et 5",
                            },
                        ]}
                    >
                        <Select size="large" onChange={handleNiveauchange}>
                            {niveau}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                {locationPa && !visible && !parentId && (
                    <>
                        <Col span={12}>
                            <Form.Item label="Ajouter centre de profit">
                                <Switch
                                    checkedChildren="Avec"
                                    unCheckedChildren="Sans"
                                    style={{ width: "80px" }}
                                    defaultChecked={addProfitCenter}
                                    onChange={onChange}
                                    disabled={
                                        selectedValuesProfCenter &&
                                        selectedValuesProfCenter.length !== 0
                                    }
                                />
                            </Form.Item>

                            {addProfitCenter && (
                                <Form.Item
                                    label="Centres de profit"
                                    name="profitCenter_id"
                                >
                                    <Row>
                                        <Col span={20}>
                                            <Select
                                                size="large"
                                                value={selectedValuesProfCenter}
                                                mode="multiple"
                                                style={{ width: "100%" }}
                                                placeholder="Sélectionner centre de profit"
                                                onChange={handleChange}
                                                optionLabelProp="label"
                                            >
                                                {provinceData}
                                            </Select>
                                        </Col>
                                        {true && (
                                            <Tooltip title="Ajouter un nouveau centre de profit">
                                                <Button
                                                    size="large"
                                                    icon={<PlusOutlined />}
                                                    onClick={
                                                        showModalAddProfToCateg
                                                    }
                                                ></Button>
                                            </Tooltip>
                                        )}
                                    </Row>
                                </Form.Item>
                            )}
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Ajouter sous catégorie">
                                <Switch
                                    defaultChecked={addSousCategoires}
                                    checkedChildren="Avec"
                                    unCheckedChildren="Sans"
                                    style={{ width: "80px" }}
                                    onChange={onchangeAddSousCaetg}
                                    disabled={
                                        selectedValuesSousCats &&
                                        selectedValuesSousCats.length !== 0
                                    }
                                />
                            </Form.Item>
                            {addSousCategoires && (
                                <Col>
                                    <Form.Item
                                        label="Sous catégories"
                                        name="categories"
                                    >
                                        <Row>
                                            <Col span={20}>
                                                <Select
                                                    size="large"
                                                    mode="multiple"
                                                    style={{ width: "100%" }}
                                                    placeholder="Sélectionner sous catégorie"
                                                    value={
                                                        selectedValuesSousCats
                                                    }
                                                    onChange={
                                                        handleChangeSousCats
                                                    }
                                                    optionLabelProp="label"
                                                >
                                                    {catsToAdd}{" "}
                                                </Select>
                                            </Col>

                                            {true && (
                                                <Col span={2}>
                                                    <Tooltip title="Ajouter une nouvelle sous catégorie">
                                                        <Button
                                                            size="large"
                                                            icon={
                                                                <PlusOutlined />
                                                            }
                                                            onClick={
                                                                showModalAddSousCateg
                                                            }
                                                        ></Button>
                                                    </Tooltip>
                                                </Col>
                                            )}
                                        </Row>
                                    </Form.Item>
                                </Col>
                            )}
                        </Col>
                    </>
                )}
            </Row>
        </Form>
    );
    const formSous = (
        <Form layout="vertical" form={moyenSousForm} onFinish={addSouscate}>
            <Row>
                <Col span={12} className="px-2">
                    <Form.Item
                        label="Nom de catégorie"
                        name="categorie_nom"
                        rules={[
                            { required: true, message: "Champ obligatoire !" },
                        ]}
                    >
                        <Input size="large" type="text" required />
                    </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                    <Form.Item
                        label="Description de catégorie"
                        name="categorie_description"
                        rules={[
                            {
                                required: !visible,
                                message:
                                    "Champ obligatoire avec maximum de caractère 255 !",
                                max: 254,
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            type="text"
                            required
                            maxLength="255"
                            disabled={visible}
                        />
                    </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                    <Form.Item
                        label="Type de catégorie"
                        name="categorie_type"
                        rules={[
                            {
                                required: !visible,
                                message: "Champ obligatoire !",
                            },
                        ]}
                    >
                        <Select size="large" onChange={handleTypechangesous}>
                            {categorieTypes}
                        </Select>{" "}
                    </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                    <Form.Item
                        label="Niveau de catégorie"
                        name="categorie_niveau"
                        rules={[
                            {
                                required: true,
                                type: "number",
                                message:
                                    "Le niveau de sous catégorie doit être supérieur  au celle de catégorie",
                            },
                        ]}
                    >
                        <Select size="large"> {niveauSous}</Select>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (
        <Spin
            size="large"
            spinning={!categories}
            indicator={antIcon}
            avatar
            active
        >
            <>
                {(Can("4_1")|| !!currentUser.type) ? (
                    <Card
                        headStyle={{ backgroundColor: "#dee0e9" }}
                        title="Liste des catégories "
                        style={{ width: "100%" }}
                        loading={!categories}
                        extra={
                            locationPa &&
                            !prof_id &&
                            !parentId &&
                           ( Can("4_2") || !!currentUser.type )&& (
                                <Button
                                    type="primary"
                                    className="mx-2"
                                    shape="round"
                                    onClick={() => {
                                        onClickAddCategorie();
                                    }}
                                >
                                    {"Ajouter une catégorie"}
                                </Button>
                            )
                        }
                    >
                        <Spin size="large" spinning={loading}>
                            <Table
                                showSorterTooltip={false}
                                rowKey={(record) => record.category_id}
                                pagination={{
                                    position: ["bottomCenter"],
                                    size: "small",
                                }}
                                className={"components-table-demo-nested"}
                                dataSource={categories}
                                columns={columns}
                                expandable={{ expandedRowRender }}
                            />
                        </Spin>
                    </Card>
                ) : (
                    <NotAuthorized />
                )}

                <Modal
                    visible={visible}
                    onCancel={handleCancel}
                    width="80%"
                    style={{ paddingBottom: "50px" }}
                    footer={null}
                    getContainer={false}
                >
                    {recordData && recordData.categorie_nom}
                    <Tabs defaultActiveKey="1">
                        {/* {recordData.categorie_nom} */}
                        <TabPane tab="Informations générales" key="1">
                            <Card>
                                <Row>
                                    <Col span={12}>
                                        {" "}
                                        <Row>
                                            <b>Nom de catégorie: </b>
                                        </Row>
                                        <Space />
                                        <br />
                                        <Row>
                                            <b>Description de catégorie: </b>
                                        </Row>
                                        <Space />
                                        <br />
                                        <Row>
                                            <b>Niveau de catégorie: </b>
                                        </Row>
                                        <Space />
                                        <br />
                                        <Row>
                                            <b>Type de catégorie: </b>
                                        </Row>
                                        <Space />
                                        <br />
                                    </Col>
                                    <Col>
                                        <Row>
                                            {" "}
                                            {categorie &&
                                                categorie.categorie_nom}
                                        </Row>{" "}
                                        <Space />
                                        <br />
                                        <Row>
                                            {categorie &&
                                                categorie.categorie_description}
                                        </Row>{" "}
                                        <Space />
                                        <br />
                                        <Row>
                                            {categorie &&
                                                categorie.categorie_niveau}
                                        </Row>{" "}
                                        <Space />
                                        <br />
                                        <Row>
                                            {categorie &&
                                                categorie.categorie_type_nom}
                                        </Row>{" "}
                                        <Space />
                                        <br />
                                    </Col>
                                </Row>
                            </Card>
                        </TabPane>
                        <TabPane tab="Centres de profit" key="2">
                            <ProfitCenter
                                getAllCategorie={getAllCategorie}
                                showMoreDetailsCat={showMoreDetails}
                                cat_id={id}
                                proCent={profitCenters}
                            />
                        </TabPane>
                        <TabPane tab="Sous catégories" key="3">
                            {categorie && (
                                <Categorie
                                    dataSous={categorie.sousCategories}
                                    parentId={categorie.category_id}
                                    catParent={categorie}
                                />
                            )}{" "}
                        </TabPane>
                    </Tabs>
                </Modal>
                {
                    <Modal
                        title={
                            mode === "edit" && recordData
                                ? "Éditer   " + recordData.categorie_nom
                                : "Ajouter une catégorie"
                        }
                        mode={mode}
                        visible={upadtaAdd}
                        onCancel={handleCancel}
                        width="60%"
                        onOk={moyenForm.submit}
                        okText="Enregistrer"
                        cancelText="Annuler"
                        getContainer={false}
                        okButtonProps={{ disabled: !upadtaAddButton }}
                    >
                        <Spin spinning={loading}>{form}</Spin>
                    </Modal>
                }
            </>
            <Form
                layout="vertical"
                form={moyenFormProf}
                onFinish={onAddProfToCatg}
            >
                <Modal
                    className="py-2"
                    title={
                        <Row>
                            <Col span={12}>
                                {recordData
                                    ? " Ajouter un centre de profit  " +
                                      recordData.categorie_nom
                                    : "Ajouter un centre de profit"}
                            </Col>
                            <Col span={4} offset={6}>
                                <Form.Item name="profitCenter_etat">
                                    <Switch
                                        checkedChildren="Active"
                                        unCheckedChildren="Inactive"
                                        checked={etatProf}
                                        className={
                                            etatProf
                                                ? "notemptyPop"
                                                : "popoverHeaderEmpty"
                                        }
                                        onChange={handleProvinceChangeEtat}
                                        size="default"
                                        style={{ width: "100%" }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    }
                    visible={addProfToCateg}
                    onCancel={closeModalAddProfToCateg}
                    width="40%"
                    getContainer={false}
                    onOk={moyenFormProf.submit}
                    okText="Enregistrer"
                    cancelText="Annuler"
                    okButtonProps={{ disabled: !upadtaAddButton }}
                >
                    {formProf}{" "}
                </Modal>
            </Form>
            <Modal
                className="py-2"
                title={
                    recordData
                        ? " Ajouter une sous catégorie  " +
                          recordData.categorie_nom
                        : " Ajouter une sous catégorie  "
                }
                visible={upadtaAddSousCateg}
                onCancel={closeModalAddProfToCateg}
                width="50%"
                onOk={moyenSousForm.submit}
                okText="Enregistrer"
                cancelText="Annuler"
                getContainer={false}
                okButtonProps={{ disabled: !upadtaAddButton }}
            >
                <Spin spinning={loading}>{formSous}</Spin>
            </Modal>
        </Spin>
    );
};
export default Categorie;
