import authHeader from "./auth-header";

import axios from "axios";
import { environment } from "../env/config.env";
const API_URL = environment.api;
let   EntrepriseId = (localStorage.getItem('entreprise'))

const addtva = (paiement_type) => {
  return axios.post(API_URL + "taxe/"+EntrepriseId , paiement_type, { headers: authHeader() });
};

const getAllTva = () => {
  EntrepriseId = (localStorage.getItem('entreprise'))
  return axios.get(API_URL + "tvasActives/" + EntrepriseId, { headers: authHeader() });
};

const gettva = (id) => {
  return axios.get(API_URL + "tva/" + id, { headers: authHeader() });
};

const updatetva = (id, tva) => {
  return axios.put(API_URL + "tva/" + id, tva, { headers: authHeader() });
};

const deletetva = (id) => {
  return axios.delete(API_URL + "tva/" + id, { headers: authHeader() });
};

const exportMethodes = {
  addtva,
  getAllTva,
  gettva,
  updatetva,
  deletetva,
};
export default exportMethodes;
