import authHeader from "./auth-header";


import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;
let EntrepriseId ; 
const addProfitCenter = (profitCenter) => {
  const x = axios.post(API_URL + "profitcenter/"+EntrepriseId, profitCenter);
  return x;
};

const getProfitCenters = () => {
  EntrepriseId = (localStorage.getItem('entreprise'))
  return axios.get(API_URL + "profitcenters/"+EntrepriseId);
};

const getProfitCenter = (id) => {
  return axios.get(API_URL + "profitcenter/" + id, { headers: authHeader() });
};

const updateProfitcenter = (id, profitCenter) => {
  const x = axios.put(API_URL + "profitcenter/" + id, profitCenter, {
    headers: authHeader(),
  });
  return x;
};

const deleteProfitCenter = (id) => {
  return axios.delete(API_URL + "profitcenter/" + id, {
    headers: authHeader(),
  });
};
const deleteCategorieProfitCenter = (id, cat_id) => {
  const catArray = [cat_id];
  return axios.delete(API_URL + "profitcenter/CatPro/" + id + "/" + catArray);
};
const addCategToProfCenter = (id, categorie) => {
  return axios.post(API_URL + "/profitcenter/cat/" + id, categorie, {
    headers: authHeader(),
  });
};
const exportMethodes = {
  addCategToProfCenter,
  deleteCategorieProfitCenter,
  addProfitCenter,
  getProfitCenter,
  getProfitCenters,
  updateProfitcenter,
  deleteProfitCenter,
};
export default exportMethodes;
