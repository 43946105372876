import authHeader from "./auth-header";
import axios from "axios";
import { environment } from "../env/config.env";

const API_URL = environment.api;

const addExercice = ( exercice ) => {
    const entreprise = (localStorage.getItem('entreprise'))
  return axios.post(API_URL + "exercice/" + entreprise , exercice ,{ headers: authHeader() });
};

const getExercices = () => {
    const entreprise = (localStorage.getItem('entreprise'))
  return axios.get(API_URL + "active_exercice/" + entreprise ,  { headers: authHeader() });
};

const getDoneExercices = () => {
  const entreprise = (localStorage.getItem('entreprise'))
return axios.get(API_URL + "done_exercice/" + entreprise ,  { headers: authHeader() });
};

const getExercice = ( id ) => {
  return axios.get(API_URL + "exercice/" + id , { headers: authHeader() });
};

const updateExercice = (id , exercice ) => {
  return axios.put(API_URL + "exercice/" + id , exercice, { headers: authHeader() });
};

const deleteExercice = (id) => {
  return axios.delete(API_URL + "exercice/" + id , { headers: authHeader() });
};

const exportMethodes = {
  addExercice,
  getExercices,
  getExercice,
  updateExercice,
  deleteExercice,
  getDoneExercices
};
export default exportMethodes;