import authHeader from "./auth-header";
import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addDepenseType = ( depenseType ) => {
  return axios.post(API_URL + "depense_type" , depenseType ,{ headers: authHeader() });
};

const getTypesDepenses = () => {
  return axios.get(API_URL + "typeDepenseActive" ,  { headers: authHeader() });
};
const deleteDepenseType = (id) => {
  return axios.delete(API_URL + "depense_type/" + id , { headers: authHeader() });
};
const updateDepenseType = (id , depenseType ) => {
  return axios.put(API_URL + "depense_type/" + id , depenseType, { headers: authHeader() });
};


const depenseType = {
addDepenseType,
getTypesDepenses,
deleteDepenseType,
updateDepenseType
};
export default depenseType;