import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Layout,
  Row,
  Spin,
  message,
} from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import { login } from "../../actions/auth";
import backPhoto from "../../assets/images/Logo DND Serv.png";
import authService from "../../services/auth.service";
import AppFooter from "../../UIcomponent/footer/AppFooter";
import messag from "../../UIcomponent/messages/messages";
import "./SignIn.css";

const SiginIn = () => {
  const dispatch = useDispatch();
  const [clicked, setClicked] = useState(false);
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const msg = useSelector((state) => state.message);
  const history = useHistory();
  const [loginForm] = Form.useForm();

  const Login = async () => {
    setClicked(true);
    await dispatch(
      login(
        loginForm.getFieldValue("username"),
        loginForm.getFieldValue("password")
      )
    );
  };
  setTimeout(() => {
    setClicked(false);
  }, 2000);
  if (msg === "waiting") {
    message.loading("dsfdg");
  }
  // if (msg.message === "") {
  //   messag.addSuccess("fdfgf");
  // }
  const setExpirationDate = () => {
    const rem = loginForm.getFieldValue("remember");
    if (!rem) {
      var date = moment(new Date()).add(0, "seconds").add(20, "minutes");
      localStorage.setItem("expire", date);
    }
  };
  isLoggedIn && setExpirationDate();

  return (
    <>
      {msg.message === undefined ? (
        <>
          <Redirect to="/" />{" "}
        </>
      ) : (
        <Spin spinning={msg === "waiting"}>
          {" "}
          <Layout>
            <Row>
              <Col className="my-5 py-5" span={12} offset={2}>
             <Link to={"/signinexpert"}><Button size="large" type="primary"> Êtes-vous un  expert ?</Button>  </Link>
                <Image
                  preview={false}
                  src={backPhoto}
                  className="backGroundPic mx-5 my-5"
                />
              </Col>
              <div className="divv">
                <div>
                  <Col span={10}>
                    <Card className="cardSigin">
                      <Divider orientation="right" className="divider">
                        Bienvenue dans GCF
                      </Divider>

                      <Form
                        name="basic"
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        form={loginForm}
                      >
                        <Form.Item
                          name="username"
                          rules={[
                            {
                              required: true,
                              message: "Champ Obligatoire ! ",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            type="text"
                            placeholder="Nom D'utilisateur : Admin"
                          />
                        </Form.Item>

                        <Form.Item
                          className="pass"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Champ Obligatoire !",
                            },
                          ]}
                        >
                          <Input.Password
                            size="large"
                            placeholder="Mot De Passe : 123456"
                          />
                        </Form.Item>
                        <Form.Item
                          name="remember"
                          valuePropName="checked"
                          wrapperCol={{
                            offset: 8,
                            span: 16,
                          }}
                        >
                          <Checkbox>Rester connecté</Checkbox>
                        </Form.Item>

                        <div className="text-left">
                          <Form.Item>
                            <Button
                              type="primary"
                              size="large"
                              className="buttonSignIn"
                              htmlType="submit"
                              onClick={Login}
                              disabled={clicked}
                            >
                              Se Connecter
                            </Button>
                          </Form.Item>
                        </div>
                      </Form>
                    </Card>
                  </Col>
                </div>
              </div>
            </Row>

            <AppFooter />
          </Layout>
        </Spin>
      )}
    </>
  );
};

export default SiginIn;
