import {
    AuditOutlined,
    BookOutlined,
    ContainerOutlined,
    CreditCardOutlined, DollarOutlined,
    DotChartOutlined,
    PieChartOutlined,
    PlusCircleOutlined, SubnodeOutlined,
    UserOutlined,
    ReconciliationOutlined,
    BoldOutlined
} from "@ant-design/icons";
import { Image, Layout, Menu } from "antd";
import { React, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import backPhoto from "../../assets/images/Logo DND Serv.png";
import modulenamesService from "../../services/nom_modules.service";

const { Sider } = Layout;
const { SubMenu } = Menu;
function SideNav({ collapsed, onCollapse, selectedKeys }) {
    const history = useHistory();
    const location = useLocation();
    const [myKey, setMyKey] = useState([]);
    const [module, setModules] = useState();

    const [selectedKey, setselectedKey] = useState(selectedKeys);

    const navigate = (location) => {
        history.push(location);
    };
    useEffect(() => {
        getallModuleNames();
        getRoute();
    }, [location]);
    const getallModuleNames = async () => {
        const res = await (
            await modulenamesService.getallModuleNamesSide()
        ).data;
        setModules(res);
    };
    const getRoute = () => {
        const selectedRoute = [];
        switch (location.pathname) {
            case "/":
                selectedRoute.push("1");
                break;
            case "/Depensev2":
                selectedRoute.push("2");

                break;
            case "/profitcenter":
                selectedRoute.push("3");
                break;
            case "/categorie":
                selectedRoute.push("4");
                break;
            case "/entreprise":
                selectedRoute.push("5");
                break;
            case "/Users":
                selectedRoute.push("6");
                break;
            case "/Paiement":
                selectedRoute.push("7");
                break;
            default:
        }
        setMyKey(selectedRoute);
    };

    return (
        <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={onCollapse}
            width="250"
            theme="dark"
            style={{
                overflow: "auto",
                height: "100vh",
                position: "fixed",
                left: 0,
            }}
        >
            <div className="my-4 py-3 logo ">
                <div className="text-center">
                    {!collapsed && (
                        <Image
                            width={200}
                            height={60}
                            preview={false}
                            src={backPhoto}
                            className="backGroundPic mx-3 my-3"
                        />
                    )}
                </div>
            </div>

            <Menu theme="dark" mode="inline" selectedKeys={myKey}>
                <Menu.Item
                    onClick={() => {
                        navigate("/");
                        setselectedKey(1);
                    }}
                    key="1"
                    icon={<PieChartOutlined />}
                >
                    <span>
                        {module && module[0]?.module_nom
                            ? module[0].module_nom
                            : "Tableau de bord"}
                    </span>
                </Menu.Item>
                <SubMenu
                    key="8"
                    icon={<PlusCircleOutlined />}
                    title={
                        module && module[5]?.module_nom
                            ? module[5].module_nom
                            : "Charges & Dépenses"
                    }
                >
                    <Menu.Item
                        onClick={() => navigate("/Depensev2")}
                        key="2"
                        icon={<CreditCardOutlined />}
                    >
                        <span>
                            {module && module[7]?.module_nom
                                ? module[7].module_nom
                                : "Charges"}
                        </span>
                    </Menu.Item>
                    <Menu.Item
                        icon={<DollarOutlined />}
                        onClick={() => navigate("/Paiement")}
                        title="Paiements"
                        key="7"
                    >
                        <span>
                            {module && module[6]?.module_nom
                                ? module[6].module_nom
                                : "Dépenses"}
                        </span>
                    </Menu.Item>
                </SubMenu>
                <Menu.Item
                    onClick={() => navigate("/profitcenter")}
                    key="3"
                    icon={<DotChartOutlined />}
                >
                    <span>
                        {module && module[2]?.module_nom
                            ? module[2].module_nom
                            : "Centres de profit"}
                    </span>
                </Menu.Item>
                <Menu.Item
                    onClick={() => navigate("/categorie")}
                    key="4"
                    icon={<SubnodeOutlined />}
                >
                    <span>
                        {module && module[1]?.module_nom
                            ? module[1].module_nom
                            : "Catégories"}
                    </span>
                </Menu.Item>
                <Menu.Item
                    onClick={() => navigate("/entreprise")}
                    key="5"
                    icon={<BookOutlined />}
                >
                    <span>
                        {module && module[3]?.module_nom
                            ? module[3].module_nom
                            : "Entreprise"}
                    </span>
                </Menu.Item>
                <Menu.Item
                    icon={<UserOutlined />}
                    onClick={() => navigate("/Users")}
                    title="Utilisateurs"
                    key="6"
                >
                    <span>
                        {module && module[4]?.module_nom
                            ? module[4].module_nom
                            : "Gestion des utilisateurs"}
                    </span>
                </Menu.Item>
                <SubMenu
                    key="10"
                    icon={<ReconciliationOutlined />}
                    title={"États financiers"}
                >
                    <Menu.Item
                        onClick={() => navigate("/balance")}
                        key="101"
                        icon={<BoldOutlined /> }
                    >
                      Balances

                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    key="11"
                    icon={<ContainerOutlined />}
                    title={"Rapports"}
                >
                    <Menu.Item
                        onClick={() => navigate("/rapport_depense")}
                        key="111"
                        icon={<AuditOutlined /> }
                    >
                      Rapport des dépenses

                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    key="12"
                    icon={<ContainerOutlined />}
                    title={"Educo"}
                >
                    <Menu.Item
                        onClick={() => navigate("/revenu")}
                        key="122"
                        icon={<AuditOutlined /> }
                    >
                      Revenu

                    </Menu.Item>
                    <Menu.Item
                        onClick={() => navigate("/affectation_service_centre_de_profit")}
                        key="133"
                        icon={<AuditOutlined /> }
                    >
                      Affectation Serv-CenProfit

                    </Menu.Item>
                    <Menu.Item
                        onClick={() => navigate("/analyse")}
                        key="134"
                        icon={<AuditOutlined /> }
                    >
                      Analyse

                    </Menu.Item>
                </SubMenu>
            </Menu>
        </Sider>
    );
}
export default SideNav;
