import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card } from "antd";
import DashboardLeadChart from "../Dashboard/DashboardLeadChart";
import axios from "axios";

function Home() {
    const dispatch = useDispatch();

    useEffect(async ()=>{
        await getGcf()
    },[])

    const getGcf = async () => {
        // gcf login_check
        // await axios.post('https://primaire.educo-solution.com/api/login_check',
        //     {"username":"admin","password":"123456"})
        await axios.post('https://demo03.educo-solution.com/api/login_check',
        {"username":"admin","password":"admin123*"})
        .then(Gcfresponse=>{
            localStorage.setItem("gcfToken", JSON.stringify(Gcfresponse.data));
        })
    }

    return (
        <>
            <Card style={{ width: "100%" }}>
                <h4 className="text-center">
                    Bienvenue à l'application de gestion financière et comptable
                    <br />
                </h4>
            </Card>
            <Card style={{ width: "100%" }}>
                <div className=" my-5">
                    <DashboardLeadChart></DashboardLeadChart>
                </div>
            </Card>
        </>
    );
}
export default Home;
