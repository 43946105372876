import { CREATE_PROFIT_CENTER, RETRIEVE_PROFIT_CENTERS } from "../actions/type";

const initialState = [];

function profitCenterReducer(profitCenters = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_PROFIT_CENTER:
      return [...profitCenters, payload];

    case RETRIEVE_PROFIT_CENTERS:
      return payload;
    default:
      return profitCenters;
  }
}

export default profitCenterReducer;
