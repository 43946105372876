import React, { useEffect, useState } from 'react'
import GcfService from "../../services/gcf.service";
import { Button, Card, Col, Input, Spin, Table, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from "moment";

const Revenu = () => {

  const [revenus, setRevenus] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastMaj, setLastMaj] = useState(null);

  useEffect(()=>{
    getAllRevenus()
  },[])

  const getAllRevenus = async () => {
    const response = await GcfService.getAllRevenus();
    console.log(response.data)
    initDbData(response.data)
  }

  const initDbData = (data) => {
    setIsLoading(true)
    for(let revenu of data){
      revenu.dateDeb = formatteDate(revenu.dateDeb)
      revenu.dateFin = formatteDate(revenu.dateFin)
    }
    const record = data[0] 
    record ? setLastMaj(formatteDate(record.date_maj)) : setLastMaj(null)

    console.log("db data",data)
    setRevenus(data)
    setIsLoading(false)
  }

  const getServices = async () => {
    const response = await GcfService.getServices();
    initRemoteData(response.data)
  }

    const initRemoteData = async (data) => {
    console.log(data)
    setIsLoading(true)
    let rows = []
    for (let service of data){
      if(service.serviceEcheances){
        for(let serviceEcheances of service?.serviceEcheances){
          for (let echeance of serviceEcheances?.eleveEcheances){
            // let eleve = await GcfService.getEleve(echeance.eleve)
            
            let tempRows = {}
            // tempRows.name = eleve.data.nom
            // tempRows.classe = eleve.data.classe?.nomFr

            tempRows.name = echeance.eleve.nom
            tempRows.classe = echeance.eleve.classe?.nomFr

            tempRows.service = service.name
            tempRows.echeances = serviceEcheances.name
            tempRows.dateDeb = formatteDate(serviceEcheances.dateDeb)
            tempRows.dateFin = formatteDate(serviceEcheances.dateFin)
            tempRows.montant = echeance.montantAvecPourcentage
            tempRows.montantPayer = echeance.montant
            tempRows.reste = tempRows.montant - tempRows.montantPayer
            tempRows.date_maj = moment()

            rows.push(tempRows)
          }
        }
      }
    }

    await storeRevenus(rows)
    setRevenus(rows)
    setIsLoading(false)
  }

  const storeRevenus = async (revenus) => {
    const response = await GcfService.storeRevenus(revenus)
    return response.data
  }

  const formatteDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const columns = [
    {
        title: () => <Tooltip title="Nom">Nom</Tooltip>,
        dataIndex: "name",
        sorter: (a, b) => a.service.localeCompare(b.service),
    },
    {
      title: () => <Tooltip title="classe">classe</Tooltip>,
      dataIndex: "classe",
      sorter: (a, b) => a.classe.localeCompare(b.classe),
    },
    {
      title: () => <Tooltip title="Service">Service</Tooltip>,
      dataIndex: "service",
      sorter: (a, b) => a.service.localeCompare(b.service),
    },
    {
      title: () => <Tooltip title="Echancier">Echancier</Tooltip>,
      dataIndex: "echeances",
      sorter: (a, b) => a.echeances.localeCompare(b.echeances),
    },
    {
      title: () => <Tooltip title="date Limite">date Limite</Tooltip>,
      dataIndex: "dateFin",
      sorter: (a, b) => a.dateFin.localeCompare(b.dateFin),
    },
    {
      title: () => <Tooltip title="Montant">Montant</Tooltip>,
      dataIndex: "montant",
      sorter: (a, b) => a.montant - b.montant,
    },
    {
      title: () => <Tooltip title="Montant Payer">Montant Payer</Tooltip>,
      dataIndex: "montantPayer",
      sorter: (a, b) => a.montantPayer - b.montantPayer,
    },
    {
      title: () => <Tooltip title="Montant Reste Payer">Montant Reste Payer</Tooltip>,
      dataIndex: "reste",
      sorter: (a, b) => a.reste - b.reste,
    },
    {
      title: () => <Tooltip title="Date de Payement">Date de Payement</Tooltip>,
      dataIndex: "dateDeb",
      sorter: (a, b) => a.dateDeb.localeCompare(b.dateDeb),
    },
  ]

  return (
    <Spin size="large" spinning={isLoading}> 
      <Card
        headStyle={{ backgroundColor: "#dee0e9" }}
        title="Liste des Revenus"
        loading={isLoading}
        extra={
          <Col style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            <Tooltip title="Dernier Date de MAJ">D.D.M.A.J</Tooltip>
            <Input
              disabled
              size="small"
              type="text"
              maxLength="255"
              value={lastMaj}
            />
            <Button
                className="mx-2"
                shape="round"
                type="primary"
                onClick={() => {
                  getServices()
                }}
            >
                Mise a jour
            </Button>
        </Col>
      }
    >                        
      <Table
        showSorterTooltip={false}
        rowKey={(record,index) => index}
        pagination={{
            position: ["bottomCenter"],
            size: "small",
        }}
        className={"components-table-demo-nested"}
        dataSource={revenus}
        columns={columns}
      />
    </Card>

  </Spin>
  )
}

export default Revenu