import {
    CREATE_FREQUENCE,
    RETRIEVE_FREQUENCE,
  } from "./type";
  
  import frequenceService from "../services/frequence.service";
 
  export const getAllFrequence = () => async (dispatch) => {
    try {
      const res = await frequenceService.getAllFrequence();
      dispatch({
        type: RETRIEVE_FREQUENCE,
        payload:res.data
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const addDepenseType = () => async (dispatch) => {
    try {
      const res = await frequenceService.addFrequence();
      dispatch({
        type: CREATE_FREQUENCE,
        payload: res.data,
      });

    } catch (err) {
      console.log(err);
    }
  };
  