import { Cascader } from "antd";
import React, { useEffect, useState } from "react";
import profitCenterservice from "../../services/profitCenter.service";

const ProfitCenterAndCategorieSelect = ({
  profitCenterSelection,
  defaultValues,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [optionTree, setOptionTree] = useState([]);

  const onChange = (values, selectedOptions) => {
    const profitCenter = values[0];
    const categorie = values[values.length - 1];
    profitCenterSelection({ profitCenter, categorie });
  };

  const filter = (inputValue, path) => {
    return path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  };

  useEffect(() => {
    getData();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {};
  const getData = async () => {
    const response = await profitCenterservice.getProfitCenters();
    let list = [];
    for (let profitCenter of response.data) {
      if(profitCenter.profitCenter_etat)
        list.push({
          value: profitCenter.profitCenter_id,
          label: profitCenter.profitCenter_nom,
          children: formatCategories(profitCenter.categories),
        });
    }
    setLoading(false);
    setOptionTree(list);
  };
  const formatCategories = (categories) => {
    let list = [];
    for (let categorie of categories) {
      if(categorie.etat)
        list.push({
          value: categorie.category_id,
          label: categorie.categorie_nom,
          children: formatCategories(categorie.sousCategories),
        });
    }
    return list;
  };

  return (
    <Cascader
      defaultValue={defaultValues}
      size="large"
      options={optionTree}
      onChange={onChange}
      placeholder=" "
      showSearch={{ filter }}
    />
  );
};
export default ProfitCenterAndCategorieSelect;
