import {
  Tabs,
  Card,
  Button,
  Col,
  Input,
  Row,
  Modal,
  Form,
  Switch,
  Spin,
  Select,
} from "antd";
import Can from "../../../Can/Can";
import Taxe from "./Taxe/Taxe";
import TvaType from "./Tva/Tva";
import { useState, useEffect } from "react";
import TaxeService from "../../../services/Taxe.service";
import TvaService from "../../../services/Tva.service";
import currency from "../../../helpers/currency";
import messages from "../../../UIcomponent/messages/messages";
import Timbre from "./Timbre/Timbre";
import { useSelector } from "react-redux";

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

function TaxesAndTva() {
  const { user: currentUser } = useSelector((state) => state.auth);

  const [moyenTaxeModal, setTaxeModal] = useState(false);
  const [typeTaxe, setTypeTaxe] = useState("Taxe");
  const [valeur, setValeur] = useState();

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [moyenForm] = Form.useForm();
  const [dataTableTaxe, setDataTableTaxe] = useState([]);
  const [dataTableTimbre, setDataTableTidataTableTimbre] = useState([]);

  const [dataTableTva, setDataTableTva] = useState([]);
  const [taxeTypes, setTaxeTypes] = useState(
    ["Taxe", "Tva", "Timbre"].map((el) => (
      <Option label={el} value={el}>
        {el}
      </Option>
    ))
  );
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  useEffect(() => {
    // typeTaxe === "Taxe" && getTaxes();
    // typeTaxe === "Tva" && getTvas();
    // typeTaxe === "Timbre" && getTimbres();
  }, []);

  const closingModal = () => {
    setTaxeModal(false);
    getTaxes();
    setConfirmLoading(false);
    clearData();
  };
  const initTableDataTaxe = (data) => {
    setDataTableTaxe(data);
  };
  const initTableDataTimbre = (data) => {
    setDataTableTidataTableTimbre(data);
  };
  const initTableDataTva = (data) => {
    setDataTableTva(data);
  };

  const getTvas = async () => {
    setLoading(true);
    const response = await TvaService.getAllTva();
    if (response.status === 200 || 204) {
      initTableDataTva(response.data);
    } else messages.errorMessage();
    setLoading(false);
  };

  const getTaxes = async () => {
    setLoading(true);
    const response = await TaxeService.getTaxes();
    if (response.status === 200 || 204) {
      initTableDataTaxe(response.data);
    } else messages.errorMessage();
    setLoading(false);
  };
  const getTimbres = async () => {
    setLoading(true);
    const response = await TaxeService.getTimbres();
    if (response.status === 200 || 204) {
      initTableDataTimbre(response.data);
    } else messages.errorMessage();
    setLoading(false);
  };

  const clearData = () => {
    moyenForm.resetFields();
    setEditMode(false);
    setUpdateId(null);
    setTypeTaxe();
  };
  const handleChangeType = (value) => {
    setTypeTaxe(value);
  };
  const handlechangeValeur = (value) => {
    setValeur(value);
    moyenForm.setFieldsValue({ valeur: value.target.value });
  };
  const addSuccess = () => {
    messages.addSuccess(
      typeTaxe === "Taxe"
        ? "Taxe ajoutée avec succès "
        : typeTaxe === "Tva"
        ? "Tva ajoutée avec succès "
        : "Timbre ajoutée avec succès "
    );
    closingModal();
  };

  const errorMessage = () => {
    setConfirmLoading(false);
    messages.errorMessage();
  };

  const addTaxe = async (values) => {
    setLoading(true);
    setConfirmLoading(true);
    setTaxeModal(true);
    setConfirmLoading(true);
    values.active = true;
    values.type = typeTaxe;
    const response = await TaxeService.addTaxe(values);
    if (response.status === 200 || 204) {
      typeTaxe === "Taxe" && getTaxes();
      typeTaxe === "Tva" && getTvas();
      typeTaxe === "Timbre" && getTimbres();
      addSuccess();
    } else errorMessage();
    closingModal();
    setLoading(false);
  };

  const closeTaxeModal = () => {
    setTaxeModal(false);
    clearData();
  };
  return (
    <>
      <Card
        headStyle={{ backgroundColor: "#dee0e9" }}
        title="Taxes et TVA "
        style={{ width: "100%" }}
        extra={
         ( Can("9_2") ||  !!currentUser.type )&& (
            <Button
              className="mx-2"
              shape="round"
              type="primary"
              onClick={() => setTaxeModal(true)}
            >
              Ajouter Taxe
            </Button>
          )
        }
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Taxes" key="1">
            <Taxe dataTableTaxe={dataTableTaxe}></Taxe>
          </TabPane>
          <TabPane tab="TVA " key="2">
            <TvaType dataTableTva={dataTableTva}></TvaType>
          </TabPane>
          <TabPane tab="Timbres" key="3">
            <Timbre dataTableTaxe={dataTableTimbre}></Timbre>
          </TabPane>
        </Tabs>
      </Card>
      <Modal
        title={"Ajouter une nouvelle Taxe"}
        centered
        getContainer={false}
        okText="Enregistrer"
        cancelText="Annuler"
        confirmLoading={confirmLoading}
        visible={moyenTaxeModal}
        onOk={moyenForm.submit}
        onCancel={closeTaxeModal}
        width={600}
      >
        <Spin spinning={isLoading}>
          <Form layout="vertical" form={moyenForm} onFinish={addTaxe}>
            <Row>
              <Col span={12} className="px-2">
                <Form.Item
                  label="Nom de la Taxe "
                  name="nom"
                  rules={[
                    {
                      required: true,
                      message:
                        "Champ obligatoire avec maximum de caractère 100 !",
                      max: 99,
                    },
                  ]}
                >
                  <Input
                    placeholder="Exemple : . . ."
                    size="large"
                    type="text"
                    maxLength={100}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Type de la Taxe "
                  name="type"
                  rules={[
                    {
                      required: true,
                      message:
                        "Champ obligatoire avec maximum de caractère 100 !",
                      max: 99,
                    },
                  ]}
                >
                  <Select size="large" onChange={handleChangeType}>
                    {taxeTypes}
                  </Select>
                </Form.Item>
              </Col>
              <>
                {["Tva", "Taxe"].includes(typeTaxe) ? (
                  <Col span={24} className="px-2">
                    <Form.Item
                      label="Valeur de la Taxe"
                      name="valeur"
                      rules={[
                        {
                          required: true,
                          message: "Champ obligatoire !",
                        },
                        {
                          max: 2,
                          message: "Valeur entre 0 et 99",
                        },
                      ]}
                    >
                      {" "}
                      <Input
                        placeholder="Exemple : 30 %"
                        size="large"
                        type="number"
                        onChange={handlechangeValeur}
                        maxLength={100}
                        addonAfter="%"
                      />
                    </Form.Item>
                  </Col>
                ) : (
                  <>
                    <Col span={18} className="px-2">
                      <Form.Item
                        label="Valeur de la Taxe"
                        name="valeur"
                        rules={[
                          {
                            required: true,
                            message: "Champ obligatoire !",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Exemple : 23"
                          size="large"
                          type="number"
                          maxLength={100}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6} className="px-2">
                      <Form.Item
                        label="Devise"
                        name="devise"
                        rules={[
                          { required: true, message: "Champ obligatoire !" },
                        ]}
                      >
                        <Select size="large">
                          {currency.map((c) => (
                            <Option key={c.id} value={c.id}>
                              {c.code}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </>

              <Col span={24} className="px-2">
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      message:
                        "Champ obligatoire avec maximum de caractère 255 !",
                      max: 254,
                    },
                  ]}
                >
                  <TextArea rows={4} type="text" maxLength={255} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}
export default TaxesAndTva;
