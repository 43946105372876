import axios from "axios";
import { environment } from "../env/config.env";
const API_URL = environment.api;

let EntrepriseId = (localStorage.getItem('entreprise'))
const register = (user) => {
  EntrepriseId = (localStorage.getItem('entreprise'))
  return axios.post(API_URL + "auth/signup/"+EntrepriseId, user);
};
const login = async (username, password) => {
  let res = await axios
    .post(API_URL + "auth/signin", {
      username,
      password,
    })
    .then((response) => {
      if (response) {
        if (response.data.arrayPermission) {
          
          localStorage.setItem("user", JSON.stringify(response.data));
          localStorage.setItem('entreprise' ,response.data.entrepriseClient)
                    localStorage.setItem(
            "permissions",
            JSON.stringify(response.data.arrayPermission)
          );
        }

        return response;
      }
    });
  return res;
};

const logout = async () => {
  localStorage.removeItem("user");
  localStorage.removeItem("entreprise");
  localStorage.removeItem("expire");
  localStorage.removeItem("permissions");

  return true;
};

const exportedObject = {
  register,
  login,
  logout,
};
export default exportedObject;
