import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Input, Row, Tooltip } from "antd";
import nomModuleService from "../../services/nom_modules.service";
import AppTable from "../../UIcomponent/Table/AppTable";
import { EditOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
const NomModule = ({ reload }) => {
    const [moduleNames, setModuleNames] = useState();
    const [visible, setvisible] = useState(false);
    const [recordData, setRecorddata] = useState("d");
    const [form] = Form.useForm();
    useEffect(() => {
        getAllModuleNames();
    }, []);
    const getAllModuleNames = async () => {
        const res = await (await nomModuleService.getallModuleNames()).data;
        setModuleNames(res);
    };
    const updateNomModule = async (record) => {
        record.type = true;
        await nomModuleService.updateModule(recordData.nom_module_id, record);
        getAllModuleNames();
        handleCancel();
    };
    const handleCancel = () => {
        setvisible(false);
    };

    const handleUpdateName = (record) => {
        setRecorddata(record);
        form.setFieldsValue(record);
        setvisible(true);
    };

    const columns = [
        {
            title: () => (
                <Tooltip title="Nom de Module par defaut">
                    Nom de Module Par Défault{" "}
                </Tooltip>
            ),
            dataIndex: "defaul_name",
            sorter: (a, b) => a.defaul_name.localeCompare(b.defaul_name),
        },
        {
            title: () => (
                <Tooltip title="Nom de module">Nom de Module </Tooltip>
            ),
            dataIndex: "module_nom",
            sorter: (a, b) => a.module_nom.localeCompare(b.module_nom),
        },
        {
            title: "Actions",
            key: "action",
            width: "180px",

            render: (text, record) => (
                <Tooltip title="Éditer le nom de ce module ">
                    <Button
                        className="mx-1"
                        type="dashed"
                        shape="circle"
                        onClick={() => {
                            handleUpdateName(record);
                        }}
                        icon={<EditOutlined />}
                    />
                </Tooltip>
            ),
        },
    ];
    return (
        <Card
            headStyle={{ backgroundColor: "#dee0e9" }}
            title="Liste  des modules "
        >
            <AppTable data={moduleNames} columns={columns} />

            <Modal visible={visible} onCancel={handleCancel} onOk={form.submit}>
                <Form form={form} onFinish={updateNomModule} layout="vertical">
                    <Col span={24} className="px-2">
                        <Form.Item
                            label="Nom par défaut"
                            name="defaul_name"
                            rules={[
                                {
                                    message:
                                        "Champ obligatoire avec maximum de caractère 255 !",
                                    max: 254,
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                type="text"
                                maxLength="255"
                                disabled
                            />
                        </Form.Item>
                        <Form.Item
                            label="Nom de Module "
                            name="module_nom"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Champ obligatoire avec maximum de caractère 255 !",
                                    max: 254,
                                },
                            ]}
                        >
                            <Input size="large" type="text" maxLength="255" />
                        </Form.Item>
                    </Col>
                </Form>
            </Modal>
            <Button
                onClick={() => reload()}
                type="primary"
                className="mx-2"
                shape="round"
            >
                Enregistrer{" "}
            </Button>
        </Card>
    );
};
export default NomModule;
