import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Alert, Button, Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import currencys from "../../helpers/currency";
import TaxeService from "../../services/Taxe.service";
import exportMessages from "../../UIcomponent/messages/messages";
const { Option, OptGroup } = Select;

function DetaisDepense({ isVisible, closeModal, montantTotal }) {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [alltaxes, setTaxes] = useState([]);

  const [dbTaxe, setBdTaxes] = useState([]);
  const [dbTva, setBdTva] = useState([]);
  const [dbTimbre, setBdTimbre] = useState([]);

  const [nbrFields, setNbrFields] = useState(0);
  const [montantForm] = Form.useForm();

  const onFinish = (values) => {
    montantForm.resetFields();
  };

  useEffect(() => {
    getTaxes();
    setMontantTTC();
    return () => {};
  }, [montantTotal]);

  const getTaxes = async () => {
    const response = await TaxeService.getallTaxes();
    const taxeAndTva = response.data;
    const taxe = taxeAndTva.filter((e) => e.type === "Taxe");
    const tva = taxeAndTva.filter((e) => e.type === "Tva");
    const timbre = taxeAndTva.filter((e) => e.type === "Timbre");
    setTaxes(taxeAndTva);
    setBdTaxes(taxe);
    setBdTva(tva);
    setBdTimbre(timbre);
  };
  const handleOk = () => {
    
    calculeTotalTaxes();
    const taxes = montantForm.getFieldValue("taxes");
    const total_ttc = montantForm.getFieldValue("total_ttc");
    const total_ht = montantForm.getFieldValue("total_ht");
    const Ligne_depense = montantForm.getFieldValue("ligne_depense");
    let somme = 0;
    if (Ligne_depense===undefined || Ligne_depense.some((el)=>el===undefined)){
      exportMessages.errorMessage("veuillez vérifier vos informations ")
      return
      
    }
    
    if (montantForm.getFieldError().length !== 0){
      exportMessages.errorMessage("veuillez vérifier vos informations ")
      return
    }
    Ligne_depense.map((el) => {
      if (el.total_ht) somme += Number(el.total_ht);
    });
    const Montant = { taxes, total_ttc, total_ht };
    if (somme > total_ht) {
      Modal.error({
        title: "Attenation",
        icon: <ExclamationCircleOutlined />,
        content: "la somme des détails est supérieur au montant totale ",
        cancelText: "OK",
      });
    } else if (somme < total_ht) {
      Modal.error({
        title: "Attention",
        icon: <ExclamationCircleOutlined />,
        content: "la somme des détails est inférieur au montant totale ",
        cancelText: "OK",
      });
    } else {
      closeModal(Montant, Ligne_depense);
    }
  };
  const handleCancel = () => {
    closeModal();
    montantForm.resetFields();
  };

  const calculeTotalTaxes = () => {
    const taxeIndex = montantForm.getFieldValue("taxes");
  };

  const setMontantTTC = () => {
    montantForm.setFieldsValue({
      total_ttc: montantTotal.total_ttc,
      total_ht: montantTotal.total_ht,
    });
  };

  const onChangeMontantTtc = (valuees, key) => {
    let mantant_ht = +valuees.target.value;
    let montant_ttc = mantant_ht;
    const fields = montantForm.getFieldsValue();
    const { ligne_depense } = fields;
    let total_taxes = 0;
    let total_timbres = 0;
    let alltaxesIds = ligne_depense[key].taxes;
    if (alltaxesIds) {
      const taxes = alltaxes.filter(
        (taxe) => alltaxesIds.includes(taxe.taxe_id) && taxe.type !== "Timbre"
      );
      const timbres = alltaxes.filter(
        (taxe) => alltaxesIds.includes(taxe.taxe_id) && taxe.type === "Timbre"
      );
      for (const element of taxes) {
        total_taxes += Number(element.valeur);
      }
      for (const element of timbres) {
        total_timbres += Number(element.valeur);
      }
    }
    let montatnTaxr = (mantant_ht / 100) * total_taxes ; 
    montant_ttc =
      mantant_ht + montatnTaxr+ total_timbres;
    Object.assign(ligne_depense[key], {
      total_ttc: Math.round(montant_ttc * 100) / 100,
    });
    fields.ligne_depense = ligne_depense;
    montantForm.setFieldsValue({ fields });
  };

  const onChangeMontantTaxe = (valuees, key) => {
    let mantant_ttc = 0;
    const fields = montantForm.getFieldsValue();
    const { ligne_depense } = fields;
    let total_taxes = 0;
    let total_timbres = 0;
    // let alltaxesIds = ligne_depense[key].taxes;
    if (true) {
      const taxes = alltaxes.filter(
        (taxe) => valuees.includes(taxe.taxe_id) && taxe.type !== "Timbre"
      );
      const timbres = alltaxes.filter(
        (taxe) => valuees.includes(taxe.taxe_id) && taxe.type === "Timbre"
      );
      for (const element of taxes) {
        total_taxes += Number(element.valeur);
      }
      for (const element of timbres) {
        total_timbres += Number(element.valeur);
      }
    }
    let mantant_ht = +ligne_depense[key].total_ht;
    if (mantant_ht !== undefined) {
      mantant_ttc =
        mantant_ht + (mantant_ht * total_taxes) / 100 - total_timbres;
    }
    Object.assign(ligne_depense[key], {
      total_ttc: Math.round(mantant_ttc * 100) / 100,
    });
    fields.ligne_depense = ligne_depense;
    montantForm.setFieldsValue({ fields });

  };
  const getCurrency = (devise) => {
    return currencys.filter((currency) => currency.id === devise).code;
  };
  return (
    <Modal
      title="Détails"
      visible={isVisible}
      okText={"Valider"}
      cancelText={"Annuler"}
      onOk={montantForm.submit}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      width={950}
    >
      <Form
        form={montantForm}
        name="dynamic_form_nest_item"
        onFinish={handleOk}
        layout="vertical"
        autoComplete="off"
      >
        <Row>
         
          <Col span={12} className="px-2">
            <Form.Item
              name="total_ht"
              label="Montant hors taxe"
              rules={[{ required: true, message: "Champ Obligatoire" }]}
            >
              <Input size="large" disabled />
            </Form.Item>
          </Col>
          <Col span={12} className="px-2">
            <Form.Item
              name="total_ttc"
              label="Montant TTC "
              rules={[{ required: true, message: "Champ Obligatoire" }]}
            >
              <Input size="large" disabled />
            </Form.Item>
          </Col>

          <Form.List name="ligne_depense">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field) => {
                    return (
                      <>
                        {}
                        <Col span={5}>
                          <Form.Item className="px-2">
                            <Form.Item
                              {...field}
                              label="Nom"
                              name={[field.name, "titre"]}
                              fieldKey={[field.fieldKey, "titre"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Champ obligatoire !",
                                },
                              ]}
                            >
                              <Input size="large" />
                            </Form.Item>
                          </Form.Item>
                        </Col>
                        <Col span={5} className="px-2">
                          <Form.Item
                            {...field}
                            label="Montant hors taxe"
                            name={[field.name, "total_ht"]}
                            fieldKey={[field.fieldKey, "total_ht"]}
                            rules={[
                              {
                                required: true,
                                message: "Champ obligatoire !",
                              },
                            ]}
                          >
                            <Input size="large"  required type = "number" onChange={(e) => onChangeMontantTtc(e, field.key)}  />
                          </Form.Item>
                        </Col>


                        <Col span={8} className="px-2">
                          <Form.Item
                            {...field}
                            label="Taxes appliqué"
                            name={[field.name, "taxes"]}
                            fieldKey={[field.fieldKey, "taxes"]}
                           
                          >
                            <Select
                              size="large"
                              onChange={(e) =>
                                onChangeMontantTaxe(e, field.key)
                              }
                              mode="multiple"
                            >
                              <OptGroup label="TVA">
                                {dbTva.map((tva, index) => (
                                  <Select.Option
                                    key={tva.taxe_id}
                                    value={tva.taxe_id}
                                  >
                                    {tva.nom} - {tva.valeur} %
                                  </Select.Option>
                                ))}
                              </OptGroup>
                              <OptGroup label="Taxes">
                                {dbTaxe.map((taxe, index) => (
                                  <Select.Option
                                    key={taxe.taxe_id}
                                    value={taxe.taxe_id}
                                  >
                                    {taxe.nom}
                                  </Select.Option>
                                ))}
                              </OptGroup>
                              <OptGroup label="Taxes">
                                {dbTimbre.map((taxe, index) => (
                                  <Select.Option
                                    key={taxe.taxe_id}
                                    value={taxe.taxe_id}
                                  >
                                    {taxe.nom}
                                  </Select.Option>
                                ))}
                              </OptGroup>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={5} className="px-2">
                          <Form.Item
                            {...field}
                            label="Total TTC"
                            name={[field.name, "total_ttc"]}
                            fieldKey={[field.fieldKey, "total_ttc"]}
                            rules={[
                              {
                                required: true,
                                message: "Champ obligatoire !",
                              },
                            ]}
                            
                          >
                            <Input type="number" size="large" disabled />
                          </Form.Item>
                        </Col>
                       
                        <Col span={1} className="px-2">
                          <Form.Item label=" " name=" ">
                            <MinusCircleOutlined
                              onClick={() => remove(field.name)}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    );
                  })}
                  <Col span={24} className="px-2">
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        size="large"
                        icon={<PlusOutlined />}
                      >
                        Ajouter
                      </Button>
                    </Form.Item>
                  </Col>
                </>
              );
            }}
          </Form.List>
        </Row>
      </Form>
    </Modal>
  );
}
export default DetaisDepense;
