import {
  DeleteOutlined,
  DollarCircleFilled,
  DollarCircleOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Card,
  Form,
  Modal,
  notification,
  Spin,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Can from "../../Can/Can";
import currency from "../../helpers/currency";
import DepenseService from "../../services/depense.service";
import messageAlert from "../../UIcomponent/messages/messages";
import AppTable from "../../UIcomponent/Table/AppTable";
import NotAuthorized from "../NotAuthorizedPage/NotAuthorizedPage";

function DepenseV2() {
  const { user: currentUser } = useSelector((state) => state.auth);

  const [isLoading, setLoading] = useState(false);
  const [depenseForm] = Form.useForm();
  const [depensesWithEcheance, setDepensesWithEcheance] = useState([]);
  const [depensesRecurrente, setDepensesRecurrente] = useState([]);
  const [depensesWithoutEcheance, setDepensesWithoutEcheance] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const history = useHistory();

  useEffect(() => {
    getDepenses();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    // setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };

  const getDepenses = async () => {
    setLoading(true);
    const response = await DepenseService.getDepenses();
    initTableData(response.data.filter((el) => el.statut !== -1));
  };
  const getDevise = (devise) => {
    return currency.find((currency) => currency.id === devise).code;
  };
  const getStatus = (status) => {
    switch (status) {
      case 0:
        return "à payer".toUpperCase();
      case 1:
        return "En cours".toUpperCase();
      default:
        return "payé".toUpperCase();
    }
  };

  const openNotificationWithIcon = () => {
    notification.error({
      message: <span style={{ color: "red" }}>Erreur serveur</span>,
      description: (
        <>
          Erreur lors de l'insertion de la dernier depense. <br /> La Tableau ne
          peut pas etre afficher
        </>
      ),
      style: { width: 500 },
    });
  };
  const initTableData = (depenses) => {
    try {
      for (const depense of depenses) {
        depense.showFournisseur = depense.interne
          ? "Interne"
          : depense.fournisseur.nom;
        depense.showMontant =
          depense.montant.total_ttc + " " + getDevise(depense.devise);
        depense.showCreationDate = moment(depense.date_creation).format(
          "YYYY-MM-DD"
        );
        depense.nbrEcheance = depense.echeances.length;
        depense.statut = getStatus(depense.statut);
        depense.firstPaiementDate = moment(
          depense.echeances[0].date_limite
        ).format("YYYY-MM-DD");
        if (depense.depense_occurence?.frequence)
          depense.showFrequence =
            "Chaque " +
            depense.depense_occurence.frequence.periode +
            " " +
            depense.depense_occurence.frequence.type;
      }
      setDepensesRecurrente(
        depenses.filter((depense) => depense.depense_occurence?.frequence)
      );
      setDepensesWithEcheance(
        depenses.filter((depense) => depense.nbrEcheance > 1)
      );
      setDepensesWithoutEcheance(
        depenses.filter((depense) => depense.nbrEcheance === 1)
      );
    } catch (err) {
      openNotificationWithIcon();
    }
    setLoading(false);
  };
  const afficher = (record) => {
    let categorie = record.categorie;
    let element = categorie;
    const arr = [];
    while (element?.categorieParent != null) {
      arr.push(element.category_id);
      element = { ...element.categorieParent };
    }
    arr.push(element?.category_id);
    arr.push(record.profitCenter.profitCenter_id);
    arr.reverse();
    return arr;
  };

  const formatteRecordToUpdate = (record) => {
    record.categorieAndPc = afficher(record);
    return record;
  };
  const updateDepense = (record) => {
    record = formatteRecordToUpdate(record);
    history.push("/AddDepense", { record });
  };

  const DepenseSansEcheance = [
    {
      title: "Titre",
      dataIndex: "titre",
      key: "titre",
      width: "20%",
    },
    {
      title: "Fournisseur",
      dataIndex: "showFournisseur",
      key: "showFournisseur",
    },
    {
      title: "Montant TTC",
      dataIndex: "showMontant",
      key: "showMontant",
    },
    {
      title: "Date de création",
      dataIndex: "showCreationDate",
      key: "showCreationDate",
      sorter: (a, b) => a.date_creation.length - b.date_creation.length,
    },
    {
      title: "Date de Paiement",
      dataIndex: "firstPaiementDate",
      key: "firstPaiementDate",
      sorter: (a, b) => a.statut.length - b.statut.length,
    },
    {
      title: "Statut",
      dataIndex: "statut",
      key: "statut",
      render: (text, record) => (
        <Tag color={getColorByStatus(record.statut)}>{record.statut}</Tag>
      ),
      sorter: (a, b) => a.statut.length - b.statut.length,
      width: "12%",
    },
    {
      title: "Action",
      key: "action",
      width: "20%",

      render: (text, record) => (
        <div>
          {record.statut !== "SUPPRIMER" && (
            <Tooltip title="Supprimer">
              {(Can("2_4")|| !!currentUser.type) && (
                <Button
                  className="mx-1"
                  type="dashed"
                  shape="circle"
                  onClick={() => confirmDelete(record)}
                  icon={<DeleteOutlined />}
                />
              )}
            </Tooltip>
          )}
         
            <>
            {(Can("2_5")||!!currentUser) && (
              <Tooltip title="Détails Charge">
                <Button
                  onClick={() => {
                    goToFicheDepense(record);
                  }}
                  className="mx-1"
                  type="dashed"
                  shape="circle"
                  icon={<EyeOutlined />}
                />
              </Tooltip>)}

              {record.statut !== "PAYÉ" &&Can("16_2") && (
                <Tooltip title="Ajouter dépense">
                  <Button
                    onClick={() => {
                      openPaiementPage(record);
                    }}
                    className="mx-1"
                    type="dashed"
                    shape="circle"
                    icon={<DollarCircleOutlined />}
                  />
                </Tooltip>
              )}
                          {(Can("2_3")|| !!currentUser.type) && (

              <Tooltip title="Mise à jour ">
                <Button
                  className="mx-1"
                  type="dashed"
                  shape="circle"
                  onClick={() => updateDepense(record)}
                  icon={<EditOutlined />}
                />
              </Tooltip>)}
            </>
          
        </div>
      ),
    },
  ];
  const DepenseAvecEcheance = [
    {
      title: "Titre",
      dataIndex: "titre",
      key: "titre",
    },
    {
      title: "Fournisseur",
      dataIndex: "showFournisseur",
      key: "showFournisseur",
    },
    {
      title: "Montant TTC",
      dataIndex: "showMontant",
      key: "showMontant",
    },
    {
      title: "Date de création",
      dataIndex: "showCreationDate",
      key: "showCreationDate",
      sorter: (a, b) => a.date_creation.length - b.date_creation.length,
    },
    {
      title: "Statut",
      dataIndex: "statut",
      key: "statut",
      render: (text, record) => (
        <Tag color={getColorByStatus(record.statut)}>{record.statut}</Tag>
      ),
      sorter: (a, b) => a.statut.length - b.statut.length,
      width: "12%",
    },
    {
      title: "Echéance",
      dataIndex: "nbrEcheance",
      key: "nbrEcheance",
      width: "10%",

      render: (text, record) => (
        <div>
          <span
            className="avatar-item goback"
            onClick={() =>
             ( Can("2_6") || !!currentUser)&&
              goToEcheance(
                record.depense_id,
                record.echeances,
                record.showMontant
              )
            }
          >
            <Badge count={record.echeances.length}>
              <Avatar>
                <DollarCircleFilled></DollarCircleFilled>
              </Avatar>
            </Badge>
          </span>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "20%",
      render: (text, record) => (
        <div>
          {record.statut !== "SUPPRIMER" && (
            <Tooltip title="Supprimer">
              {(Can("2_4")|| !!currentUser.type) && (
                <Button
                  className="mx-1"
                  type="dashed"
                  shape="circle"
                  onClick={() => confirmDelete(record)}
                  icon={<DeleteOutlined />}
                />
              )}
            </Tooltip>
          )}

         
            <>
             {(Can("2_5")||!!currentUser) && (
              <Tooltip title="Détails Charge">
                <Button
                  onClick={() => {
                    goToFicheDepense(record);
                  }}
                  className="mx-1"
                  type="dashed"
                  shape="circle"
                  icon={<EyeOutlined />}
                />
              </Tooltip>  )}
              {record.statut !== "PAYÉ" &&Can("16_2") && (
                <Tooltip title="Ajouter Dépense">
                  <Button
                    onClick={() => {
                      openPaiementPage(record);
                    }}
                    className="mx-1"
                    type="dashed"
                    shape="circle"
                    icon={<DollarCircleOutlined />}
                  />
                </Tooltip>
              )}
                                        {(Can("2_3")|| !!currentUser.type) && (

              <Tooltip title="Mise à jour ">
                <Button
                  className="mx-1"
                  type="dashed"
                  shape="circle"
                  onClick={() => updateDepense(record)}
                  icon={<EditOutlined />}
                />
              </Tooltip>)}
            </>
        
        </div>
      ),
    },
  ];
  const DepenseRecurrente = [
    {
      title: "Titre",
      dataIndex: "titre",
      key: "titre",
    },
    {
      title: "Fournisseur",
      dataIndex: "showFournisseur",
      key: "showFournisseur",
    },
    {
      title: "Montant TTC",
      dataIndex: "showMontant",
      key: "showMontant",
    },
    {
      title: "Date de création",
      dataIndex: "showCreationDate",
      key: "showCreationDate",
      sorter: (a, b) => a.date_creation.length - b.date_creation.length,
    },
    {
      title: "Frequence",
      dataIndex: "showFrequence",
      key: "showFrequence",
      sorter: (a, b) => a.showFrequence.length - b.showFrequence.length,
    },
    {
      title: "Statut",
      key: "statut",
      width: "12%",
      render: (text, record) => (
        <Tag color={getColorByStatus(record.statut)}>{record.statut}</Tag>
      ),
      sorter: (a, b) => a.statut.length - b.statut.length,
    },
    {
      title: "Action",
      key: "action",
      width: "20%",
      render: (text, record) => (
        <div>
          {record.statut !== "SUPPRIMER" && (
            <Tooltip title="Supprimer">
              {(Can("2_4")|| !!currentUser.type) && (
                <Button
                  className="mx-1"
                  type="dashed"
                  shape="circle"
                  onClick={() => confirmDelete(record)}
                  icon={<DeleteOutlined />}
                />
              )}
            </Tooltip>
          )}
      
            <>
            {(Can("2_5")||!!currentUser) && (
              <Tooltip title="Détails Charge">
                <Button
                  onClick={() => {
                    goToFicheDepense(record);
                  }}
                  className="mx-1"
                  type="dashed"
                  shape="circle"
                  icon={<EyeOutlined />}
                />
              </Tooltip> )}
              {record.statut !== "PAYÉ" &&Can("16_2") && (
                <Tooltip title="Ajouter Dépense">
                  <Button
                    onClick={() => {
                      openPaiementPage(record);
                    }}
                    className="mx-1"
                    type="dashed"
                    shape="circle"
                    icon={<DollarCircleOutlined />}
                  />
                </Tooltip>
              )}
              {(Can("2_3")|| !!currentUser.type)&&(
              <Tooltip title="Mise à jour ">
                <Button
                  className="mx-1"
                  type="dashed"
                  shape="circle"
                  onClick={() => updateDepense(record)}
                  icon={<EditOutlined />}
                />
              </Tooltip>)}
            </>
         
        </div>
      ),
    },
  ];

  const getColorByStatus = (status) => {
    switch (status) {
      case "PAYÉ":
        return "#52c41a";
      case "EN COURS":
        return "#ff7129";
      default:
        return "#ff4d4f";
    }
  };

  const confirmDelete = (record) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer cette depense ? ",
      onOk: () => deleteDepense(record.depense_id),
      okText: "Oui",
      cancelText: "Non",
    });
  };

  const deleteDepense = async (depense_id) => {
    const response = await DepenseService.deleteDepense(depense_id);
    if (response.status === 204 || 200) {
      messageAlert.addSuccess("Suppression avec succès ! ");
      getDepenses();
    } else messageAlert.errorMessage();
  };

  const navigate = (location) => {
    history.push(location);
  };
  const openPaiementPage = (record) => {
    history.push("/AddPaiement", { record });
  };
  const goToEcheance = (depense_id, echeances, total) => {
    history.push("/echeance", { depense_id, echeances, total });
  };

  const goToFicheDepense = (record) => {
    history.push("/ficheDepense", { record, from: "depense" });
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

  return (
    <Spin spinning={isLoading} indicator={antIcon} size="large">
      {(Can("2_1")|| !!currentUser.type)? (
        <Card
          headStyle={{ backgroundColor: "#dee0e9" }}
          title="Liste des Charges "
          style={{ width: "100%" }}
          extra={
            ((Can("2_2")|| !!currentUser.type)&&
            <Button
              className="mx-2"
              shape="round"
              type="primary"
              onClick={() => navigate("/AddDepense")}
            >
              Ajouter une Charge
            </Button>)
          }
        >
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Charge sans échèance" key="1">
              <AppTable
                data={depensesWithoutEcheance}
                columns={DepenseSansEcheance}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Charge avec échèance" key="2">
              <AppTable
                data={depensesWithEcheance}
                columns={DepenseAvecEcheance}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Charge recurrente" key="3">
              <AppTable data={depensesRecurrente} columns={DepenseRecurrente} />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      ) : (
        <NotAuthorized />
      )}
    </Spin>
  );
}
export default DepenseV2;
