import axios from "axios";
import authHeader from "./auth-header";


import { environment } from "../env/config.env";
const API_URL = environment.api;

const getAll = () => {
 return axios.get(API_URL + "module", { headers: authHeader() });
};

const exportObj = {

  getAll
  
  
};
export default exportObj;