import {
  CloseOutlined,
  CloudDownloadOutlined,
  ExclamationCircleOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
  Upload,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import paiementService from "../../../services/paiement.service";
import paiementMethodes from "../../../services/paiementMethodes.service";
import EcheanceTable from "../Tables/EcheanceTable";
import "../Fichedepnese.css";
import PaiementTable from "../Tables/PaiementTable";
import messageAlert from "../../../UIcomponent/messages/messages";
import Uploader from "../../../helpers/Uploader";
import fileService from "../../../services/file.service";

const UpdatePaiement = () => {
  const [form] = Form.useForm();
  const [detailsForm] = Form.useForm();
  const [methodesPaiement, setmethodesPaiement] = useState([]);
  const [methodes, setMethodes] = useState([]);
  const [encaissement, setEncaissement] = useState(false);
  const location = useLocation();

  const [selectedFiles, setselectedFiles] = useState([]);
  const [paiement, setPaiement] = useState({});
  const [refresh, setrefresh] = useState(false);

  const { Option } = Select;
  const { Panel } = Collapse;
  const { Dragger } = Upload;
  const { TextArea } = Input;

  useEffect(() => {
    initFormValues();
    getMethodesPaiement();
  }, [refresh]);

  const initFormValues = () => {
    const index = location.state.paiement;
    const paiement = location.state.record.paiements[index];
    paiement.methodePaiement_id = paiement.methodePaiement.methode_paiement_id;
    paiement.dateEncaissement = moment(paiement.date_ecaissement);
    paiement.datePaiement = moment(paiement.date_paiement);
    form.setFieldsValue(paiement);
    setPaiement(paiement);
  };

  const props = {
    multiple: true,
    onRemove(file) {},
    beforeUpload(file) {
      return false;
    },
  };
  const history = useHistory();

  const goback = () => {
    history.push("/Depensev2");
  };

  const initMethodes = (data) => {
    setMethodes(data);
    let methodesSelect = data.map((el) => (
      <Option value={el.methode_paiement_id}>{el.nom}</Option>
    ));
    setmethodesPaiement(methodesSelect);
  };

  const calculeMontantEcheances = () => {
    let montant = 0;
    for (const element of location.state.record.echeances) {
      montant = montant + element.reste;
    }
    return montant;
  };
  const calculeMontantPaiements = () => {
    let montant = 0;
    for (const element of location.state.record.paiements) {
      montant = montant + element.montant;
    }
    return montant;
  };

  const getMethodesPaiement = async () => {
    let methodes = await paiementMethodes.getPaiementMethodes();
    if (methodes.status === 200) initMethodes(methodes.data);
  };

  const onFinish = (paiementData) => {
    if (
      Number(paiementData.montant) >
      Number(location.state.record.montant.total_ttc)
    ) {
      showConfirmModal(paiementData);
    } else formatteAndSendData(paiementData);
  };

  const showConfirmModal = (paiementData) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Le montant saisi est supérieur au montant restant à payer !",
      okText: "Continuer",
      cancelText: "Annuler",
      onOk: () => formatteAndSendData(paiementData),
    });
  };
  const fileChange = (value) => {
    let arr = value.fileList.map((el) => el.originFileObj);
    setselectedFiles(arr);
    form.setFieldsValue({ files: arr });
  };

  const downloadFile = async (file) => {
    let y = await fileService.getFile(file.file_id, "2").then((response) => {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.name);
      document.body.appendChild(link);
      link.click();
    });
  };
  const formatteAndSendData = (paiementData) => {
    const dateEncaissement = paiementData.dateEncaissement
      ? paiementData.dateEncaissement
      : paiementData.datePaiement;
    const paiement = {
      libelle: paiementData.libelle,
      dateEncaissement: moment(dateEncaissement).toDate(),
      datePaiement: moment(paiementData.datePaiement).toDate(),
      description: paiementData.description,
      methodePaiement: paiementData.methodePaiement_id,
      montant: paiementData.montant,
    };
    sendData(paiement);
  };
  const sendData = async (paiementData) => {
    const index = location.state.paiement;
    const paiementId = location.state.record.paiements[index].paiement_id;
    const depenseId = location.state.record.depense_id;
    let resopnse = await paiementService.updatePaiement(
      depenseId,
      paiementId,
      paiementData
    );
    if (resopnse.status === 200) {
      messageAlert.addSuccess("Paiement mise à jours avec succées");
      selectedFiles && fileService.upload(selectedFiles, paiementId, "2");
      goback();
    } else {
      messageAlert.errorMessage("une erreur est survenue");
    }
  };
  const deleteFile = async (file) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer ce fichier ? ",
      onOk: async () => {
        let res = await fileService.deleteFile(file, "2");
        let newFiles = [
          ...location.state.record.paiements[
            location.state.paiement
          ].file_id.filter((el) => el !== file),
        ];
        location.state.record.paiements[location.state.paiement].file_id = newFiles;
        setrefresh(!refresh);

      },
      okText: "Oui",
      cancelText: "Non",
    });
  };
  const methodeChanged = () => {
    const methodeId = form.getFieldValue("methodePaiement");
    const methode = methodes.find(
      (methode) => methode.methode_paiement_id === methodeId
    );
    setEncaissement(methode.encaissement);
  };
  const resetForm = () => {
    form.resetFields();
  };

  return (
    <Card
      headStyle={{ backgroundColor: "#dee0e9" }}
      title={
        <>
          <Row>
            <Col span={9}>
              <span onClick={() => goback()} className="goback">
                Retour
              </span>
            </Col>
            <Col span={14}>
              Mise à jour du Paiement de {location.state.record.titre}
            </Col>
          </Row>
        </>
      }
    >
      <Form layout="vertical">
        <Row>
          <Col span={8} className="px-2" form={detailsForm}>
            <Form.Item
              label="Montant depense global "
              initialValue={location.state.record.montant.total_ttc}
              name="montantGlobal"
            >
              <Input type="number" size="large" disabled />
            </Form.Item>
          </Col>
          <Col span={8} className="px-2">
            <Form.Item
              label="Montant à payer : "
              initialValue={calculeMontantEcheances()}
              name="toPay"
            >
              <Input type="number" size="large" disabled />
            </Form.Item>
          </Col>
          <Col span={8} className="px-2">
            <Form.Item
              label="Montant payé : "
              initialValue={calculeMontantPaiements()}
              name="paied"
            >
              <Input type="number" size="large" disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row className="pb-4 px-2">
        <Collapse className="collapsetable">
          <Panel
            header={
              location.state.record.echeances > 1
                ? "Liste des écheances"
                : "Paiement Prévu"
            }
            key="1"
          >
            <EcheanceTable echeances={location.state.record.echeances} />
          </Panel>
          <Panel header="Liste des paiements" key="2">
            <PaiementTable
              paiements={location.state.record.paiements}
              depense={location.state.record}
              showUpdate={false}
            />
          </Panel>
        </Collapse>
      </Row>
      <>
        <Form onFinish={onFinish} layout="vertical" form={form}>
          <Row>
            <Col span={6} className="px-2">
              <Form.Item
                label="Montant à payer :  "
                name="montant"
                rules={[
                  {
                    required: true,
                    message: "Champ obligatoire  !",
                  },
                ]}
              >
                <Input type="number" size="large" />
              </Form.Item>
            </Col>
            <Col span={6} className="px-2">
              <Form.Item
                label="Méthode de paiement :"
                name="methodePaiement_id"
                rules={[
                  {
                    required: true,
                    message: "Champ obligatoire ! ",
                  },
                ]}
              >
                <Select size="large" onChange={() => methodeChanged()}>
                  {methodesPaiement}
                </Select>
              </Form.Item>
            </Col>
            <Col span={encaissement ? 6 : 12} className="px-2">
              <Form.Item
                label="Date du paiement:"
                name="datePaiement"
                rules={[
                  {
                    required: true,
                    message: "Champ obligatoire ! ",
                  },
                ]}
              >
                <DatePicker
                  placeholder=""
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            {encaissement && (
              <Col span={6} className="px-2">
                <Form.Item
                  label="Date de décaissement:"
                  name="dateEncaissement"
                  rules={[
                    {
                      required: true,
                      message: "Champ obligatoire ! ",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder=""
                    size="large"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            )}

            <Col span={8} className="px-2">
              <Form.Item
                label="Libelle : "
                name="libelle"
                rules={[
                  {
                    required: true,
                    message: "Champ obligatoire !",
                  },
                ]}
              >
                <Input size="large" rows={1} />
              </Form.Item>
            </Col>
            <Col span={16} className="px-2">
              <Form.Item label="Commentaires : " name="description">
                <TextArea size="large" rows={1} />
              </Form.Item>
            </Col>
            <Col span={24} className="px-2">
              Piéces jointes :
              {location.state.record.paiements[ location.state.paiement ].file_id.map((file) => (
                <Alert
                  key={file.file_id}
                  message={file.name}
                  type="success"
                  className="my-1"
                  action={
                    <Space direction="horizonal">
                      <Tooltip title="Supprimer">
                        <Button
                          type="danger"
                          shape="circle"
                          icon={<CloseOutlined />}
                          onClick={() => {
                            deleteFile(file);
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Telecharger">
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<CloudDownloadOutlined />}
                          onClick={() => downloadFile(file)}
                        />
                      </Tooltip>
                    </Space>
                  }
                />
              ))}
            </Col>
            <Col span={24} className="px-2">
              <Form.Item name="files">
                <Uploader  text="Dépense" fileChange={fileChange} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="d-flex flex-row-reverse">
            <Button type="primary" htmlType="submit" className="mx-2 px-4">
              Ajouter
            </Button>
            <Button type="" className="mx-2" onClick={() => resetForm()}>
              Annuler
            </Button>
          </Row>
        </Form>
      </>
    </Card>
  );
};
export default UpdatePaiement;
