import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    LoadingOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import {
    Avatar,
    Badge,
    Button,
    Card,
    Col,
    Form,
    Input,
    Modal,
    Popover,
    Row,
    Select,
    Spin,
    Switch,
    Tabs,
    Tag,
    Tooltip,
    Table
} from "antd";
import React, { useEffect, useState } from 'react'
import profitCenterservice from "../../services/profitCenter.service";
import GcfService from "../../services/gcf.service";
import moment from "moment";
import messagealert from "../../UIcomponent/messages/messages";


const AfftectationSCDP = () => {

    const [myForm] = Form.useForm();
    const [openAddModal,setOpenAddModal] = useState(false)
    const [showModalAddCentreProfit,setShowModalAddCentreProfit] = useState(false)
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedValuesProfCenter, setSelectedValuesProfCenter] = useState();
    const [profCes, setProfCes] = useState([]);
    const [servicess, setServicess] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState([]);
    const [isShowMode, setIsShowMode] = useState(false);
    const [updateMode, setUpdateMode] = useState(false);
    const [selectedToUpdate, setSelectedToUpdate] = useState({});
    
    const { Option } = Select;

    useEffect(() => {
        getAllProfitCenter();
        getServices()
        getCentreServicesAnnes()
    }, []);


  const getServices = async () => {
    setIsLoading(true)
    const response = await GcfService.getServices();
    initRemoteData(response.data)
    setIsLoading(false)
  }

  const initRemoteData = async (services) => {
    setIsLoading(true)
    let serviceList = []
    let anneScolaireList = []
    for(let service of services){
        if(service.id && service.price && service.anneeScolaire){
            let temp = {}
            temp.id = service.id
            temp.nameAr = service.nameAr
            temp.name = service.name
            temp.price = service.price
            const anneScolaire = await GcfService.getAnneScolaire(service.anneeScolaire)
            const savedAnnesScolaires = await GcfService.storeAnneScolaires(anneScolaire.data)
            await GcfService.storeServices(temp, savedAnnesScolaires.data.id);

            serviceList.push(temp)
        }
    }
    console.log(serviceList)
    setServicess(serviceList)
    setIsLoading(false)
  }

    const getAllProfitCenter = async () => {
        const profCens = await profitCenterservice.getProfitCenters();
        const profe = profCens.data.reverse();
        setProfCes(profe);
        return profe;
    };

    const getCentreServicesAnnes = async () => {
        const centreServiceAnneList = await GcfService.getCentreServiceAnne()
        formatData(centreServiceAnneList.data)
    }

    const formatData = (data) => {
        console.log("data before",data)
        for(let element of data){
            element.profitCenter_nom = element.profitCenter.profitCenter_nom
            element.profitCenter_description = element.profitCenter.profitCenter_description
            element.serviceName = element.service.name
            element.serviceNameAr = element.service.nameAr
            element.servicePrice = element.service.price
        }
        console.log("formatData ::",data)
        setDataSource(data)
    }

    const submitForm = async (values) => {
        console.log(values)
        if (updateMode) await GcfService.updateCentreServiceAnne(values, selectedToUpdate.centre_service_anne_id)
        else await GcfService.storeCentreServiceAnne(values)
        console.log(values)
        setOpenAddModal(false)
        myForm.resetFields()
        getCentreServicesAnnes()
    }

    const handleCancel = () => {
        setOpenAddModal(false)
        setIsShowMode(false)
        setUpdateMode(false)
        myForm.resetFields();
    }

    const ondeleteAffectation = async (id) => {
        const msg = await GcfService.deleteCentreServiceAnne(id);
        msg.status === 200
            ? messagealert.addSuccess("affectation a été suppimée avec succées")
            : messagealert.errorMessage();
        getCentreServicesAnnes();
    };

    const showDetails = (record) => {
        setLoading(true);
        setIsShowMode(true)
        myForm.setFieldsValue({profitCenter_id : record.profitCenter.profitCenter_id, services_id : record.service.id });
        setOpenAddModal(true)
        setLoading(false);
    }

    const openUpdateModal = (record) => {
        myForm.setFieldsValue({profitCenter_id : record.profitCenter.profitCenter_id, services_id : record.service.id });
        setSelectedToUpdate(record)
        setOpenAddModal(true)
        setUpdateMode(true)
    }

    const profitCenterSelectList = () => {
        return profCes.map((el,index)=>(
            <Option
                key={index}
                label={el.profitCenter_nom}
                value={parseInt(el.profitCenter_id)}
            >
                {el.profitCenter_nom}
            </Option>
        ))
    }

    const serviceSelectList = () => {
        return servicess.map((it,index)=>(
            <Option
                key={index}
                label={it.nameAr}
                value={it.id}
            >
                {it.nameAr}
            </Option>
        ))
    }

    const columns = [
        {
          title: () => <Tooltip title="classe">Nom ProfitCenter</Tooltip>,
          dataIndex: "profitCenter_nom",
          sorter: (a, b) => a.profitCenter_nom.localeCompare(b.profitCenter_nom),
        },
        {
            title: () => <Tooltip title="classe">ProfitCenter Description</Tooltip>,
            dataIndex: "profitCenter_description",
            sorter: (a, b) => a.profitCenter_description.localeCompare(b.profitCenter_description),
        },
        {
            title: () => <Tooltip title="classe">Service Name</Tooltip>,
            dataIndex: "serviceName",
            sorter: (a, b) => a.serviceName.localeCompare(b.serviceName),
        },
        {
            title: () => <Tooltip title="classe">Service NameAr</Tooltip>,
            dataIndex: "serviceNameAr",
            sorter: (a, b) => a.serviceNameAr.localeCompare(b.serviceNameAr),
        },
        {
            title: () => <Tooltip title="classe">Service Price</Tooltip>,
            dataIndex: "servicePrice",
            sorter: (a, b) => a.servicePrice.localeCompare(b.servicePrice),
        },
        {
            title: "Actions",
            key: "action",
            width: "180px",

            render: (text, record) => (
                <div>
                    {" "}
                    {/* <Tooltip title="Éditer cette Affectation ">
                        <Button
                            className="mx-1"
                            type="dashed"
                            shape="circle"
                            onClick={() => {
                                openUpdateModal(record)
                            }}
                            icon={<EditOutlined />}
                        />
                    </Tooltip> */}
                    <Tooltip title="supprimer cette Affectation">
                        <Button
                            className="mx-1"
                            type="dashed"
                            shape="circle"
                            onClick={() => {
                                Modal.confirm({
                                    type: "info",
                                    title: "Attention",
                                    icon: (
                                        <ExclamationCircleOutlined />
                                    ),
                                    content:
                                        "Etes vous sur de vouloir supprimer cette Affectation ? ",
                                    onOk: () => {
                                        ondeleteAffectation(record.centre_service_anne_id)
                                    },
                                    okText: "Oui",
                                    cancelText: "Non",
                                });
                            }}
                            icon={<DeleteOutlined />}
                        />
                    </Tooltip>
                    <Tooltip title="voir plus détails">
                        <Button
                            className="mx-1"
                            type="dashed"
                            shape="circle"
                            onClick={() => {
                                showDetails(
                                    record
                                );
                            }}
                            icon={<EyeOutlined />}
                        />
                    </Tooltip>
                </div>
            ),
        }
    ]

  return (
    <Spin size="large" spinning={isLoading}> 
        <Card
        headStyle={{ backgroundColor: "#dee0e9" }}
        title="Affectation Service-Centre de Profit"
        loading={isLoading}
        extra={
            <Button
                className="mx-2"
                shape="round"
                type="primary"
                onClick={() => {
                    setOpenAddModal(true)
                }}
            >
                Ajouter une Affectation
            </Button>
        }
        >     
        </Card>

        <Table
            showSorterTooltip={false}
            rowKey={(record,index) => index}
            pagination={{
                position: ["bottomCenter"],
                size: "small",
            }}
            className={"components-table-demo-nested"}
            dataSource={dataSource}
            columns={columns}
        />

        <Modal
            title={
                <>
                    <div className="d-flex justify-content-between">
                        <div>
                      {isShowMode ? "Afficher une Affectation" : (updateMode ? "Update une Affectation" :"Ajouter une Affectation")} 
                        </div>
                    </div>
                </>
            }
            visible={openAddModal}
            onCancel={handleCancel}
            width="40%"
            getContainer={false}
            onOk={myForm.submit}
            okText="Enregistrer"
            cancelText="Annuler"
        >
            <Form
                aria-disabled="true"
                layout="vertical"
                form={myForm}
                onFinish={submitForm}
            >
            <div>
                <Form.Item
                    label="Nom de centre de profit"
                    name="profitCenter_id"
                >
                    <Select
                        disabled={isShowMode}
                        size="large"
                        value={selectedValuesProfCenter}
                        style={{ width: "100%" }}
                        placeholder="Sélectionner centre de profit"
                        optionLabelProp="label"
                    >
                        {profitCenterSelectList()}
                    </Select>
                </Form.Item>
            </div>

            <br />
                <Form.Item
                    className="px-2"
                    label="Services"
                    name="services_id"
                >
                    <Select
                        disabled={isShowMode}
                        size="large"
                        value={selectedValues}
                        mode="multiple"
                        allowClear
                        placeholder="Selectioner une catégorie"
                        optionLabelProp="label"
                    >
                        { serviceSelectList()}
                    </Select>
                </Form.Item>
            </Form>
            
        </Modal>
        
    </Spin>                   
  )
}

export default AfftectationSCDP