import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Tooltip,
  Card,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Can from "../../../../Can/Can";
import TaxeService from "../../../../services/Taxe.service";
import messages from "../../../../UIcomponent/messages/messages";
import AppTable from "../../../../UIcomponent/Table/AppTable";
import NotAuthorized from "../../../NotAuthorizedPage/NotAuthorizedPage";
const { TextArea } = Input;

function Taxe({ dataTableTaxe }) {
  const { user: currentUser } = useSelector((state) => state.auth);

  const [datatableParenbt, setdatatableParenbt] = useState(dataTableTaxe);

  const [moyenTaxeModal, setTaxeModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [moyenForm] = Form.useForm();
  const [dataTable, setDataTable] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();

  useEffect(() => {
    getTaxes();
    return () => {
      clearStates();
    };
  }, [dataTableTaxe]);
  const clearStates = () => {
    setDataTable([]);
    setUpdateId(null);
  };

  const getTaxes = async () => {
    const response = await TaxeService.getTaxes();

    if (response.status === 200 || 204) initTableData(response.data);
    else messages.errorMessage();
  };
  const TaxeColumn = [
    {
      title: "Nom",
      dataIndex: "nom",
      sorter: (a, b) => a.nom.localeCompare(b.nom),
      width: "17%",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "55%",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Valeur",
      dataIndex: "valeur",
      sorter: (a, b) => a.valeur - b.valeur,
      width: "10%",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            {(!!currentUser.type || Can("9_3")) && (
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => updateMode(record)}
                icon={<EditOutlined />}
              />
            )}
          </Tooltip>
          <Tooltip title="Supprimer">
            {(!!currentUser.type || Can("9_4")) && (
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                onClick={() => confirmDelete(record)}
                icon={<DeleteOutlined />}
              />
            )}
          </Tooltip>
        </div>
      ),
    },
  ];

  const confirmDelete = (record) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer cette taxe ? ",
      okText: "Oui",
      onOk: () => deleteItem(record),
      cancelText: "Non",
    });
  };
  const updateMode = (record) => {
    setTaxeModal(true);
    moyenForm.setFieldsValue(record);
    setUpdateId(record.taxe_id);
    setEditMode(true);
  };

  const initTableData = (data) => {
    setDataTable(data);
  };

  const deleteItem = async (item) => {
    const response = await TaxeService.deleteTaxe(item.taxe_id);
    if (response.status === 200 || 204) {
      messages.addSuccess("Suppression avec succès ! ");
      getTaxes(response.data);
    } else messages.errorMessage();
  };

  const closingModal = () => {
    setTaxeModal(false);
    getTaxes();
    setConfirmLoading(false);
    clearData();
  };

  const addTaxe = async (values) => {
    setConfirmLoading(true);
    setTaxeModal(true);
    setConfirmLoading(true);
    values.active = true;
    values.type = "Taxe";
    const response = editMode
      ? await TaxeService.updateTaxe(updateId, values)
      : await TaxeService.addTaxe(values);
    if (response.status === 200 || 204) {
      if (editMode) updateSuccess();
      else addSuccess();
    } else errorMessage();
    closingModal();
  };

  const closeTaxeModal = () => {
    setTaxeModal(false);
    clearData();
  };

  const clearData = () => {
    moyenForm.resetFields();
    setEditMode(false);
    setUpdateId(null);
  };

  const addSuccess = () => {
    messages.addSuccess("Taxe ajoutée avec succès ");
    closingModal();
  };
  const updateSuccess = () => {
    messages.updateSuccess("Taxe mise à jour avec succéss");
    closingModal();
  };
  const errorMessage = () => {
    setConfirmLoading(false);
    messages.errorMessage();
  };

  return (
    <Spin spinning={isLoading} size="large">
      {(!!currentUser.type || Can("9_1")) ? (
        <AppTable data={dataTable} columns={TaxeColumn} />
      ) : (
        <NotAuthorized />
      )}
      <Modal
        title={editMode ? "Modifier un taxe" : "Ajouter un taxe"}
        centered
        getContainer={false}
        okText="Enregistrer"
        cancelText="Annuler"
        confirmLoading={confirmLoading}
        visible={moyenTaxeModal}
        onOk={moyenForm.submit}
        onCancel={closeTaxeModal}
        width={600}
      >
        <Form layout="vertical" form={moyenForm} onFinish={addTaxe}>
          <Row>
            <Col span={24} className="px-2">
              <Form.Item
                label="Nom de la Taxe "
                name="nom"
                rules={[
                  {
                    required: true,
                    message:
                      "Champ obligatoire avec maximum de caractère 100 !",
                    max: 99,
                  },
                ]}
              >
                <Input
                  placeholder="Exemple : . . ."
                  size="large"
                  type="text"
                  maxLength={100}
                />
              </Form.Item>
            </Col>
            <Col span={24} className="px-2">
              <Form.Item
                label="Valeur de la Taxe"
                name="valeur"
                rules={[
                  {
                    required: true,
                    message: "Champ obligatoire !",
                  },
                  {
                    max: 2,
                    message: "Valeur entre 0 et 99",
                  },
                ]}
              >
                <Input
                  placeholder="Exemple : 30"
                  size="large"
                  type="number"
                  maxLength={100}
                  addonAfter="%"
                />
              </Form.Item>
            </Col>
            <Col span={24} className="px-2">
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    message:
                      "Champ obligatoire avec maximum de caractère 255 !",
                    max: 254,
                  },
                ]}
              >
                <TextArea rows={4} type="text" maxLength={255} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Spin>
  );
}
export default Taxe;
