import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;
let EntrepriseId 


const addDepense = async (depense) => {
  return await axios.post(API_URL + "depense/"+EntrepriseId, depense, {
    headers: authHeader(),
  });
};

const getDepenses = () => {
  EntrepriseId = (localStorage.getItem('entreprise'))
    return axios.get(API_URL + "depenses/"+EntrepriseId, { headers: authHeader() });
};

const getDepense = (id) => {
  return axios.get(API_URL + "depense/" + id, { headers: authHeader() });
};
const updateDepense = (id, depense) => {
  return axios.put(API_URL + "depense/" + id, depense, {
    headers: authHeader(),
  });
};
const updateStatus = (id, status) => {
  return axios.get(API_URL + "depenseStatus/" + id + "?status=" + status, {
    headers: authHeader(),
  });
};
const deleteDepense = (id) => {
  return axios.delete(API_URL + "depense/" + id, { headers: authHeader() });
};
const updateDepensePaiement = (id, depensePaiementData) => {
  return axios.put(API_URL + "depensepaiement/" + id, depensePaiementData, {
    headers: authHeader(),
  });
};

const exportMethodes = {
  addDepense,
  getDepense,
  getDepenses,
  deleteDepense,
  updateDepense,
  updateStatus,
  updateDepensePaiement,
};
export default exportMethodes;
