import authHeader from "./auth-header";


import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;
let   EntrepriseId = (localStorage.getItem('entreprise'))

const addTypeCategorie = (typeCategorie) => {
  return axios.post(API_URL + "categorie_type/"+ EntrepriseId, typeCategorie, {
    headers: authHeader(),
  });
};

const getTypeCategories = () => {
     EntrepriseId = (localStorage.getItem('entreprise'))

    const res =   axios.get(API_URL + "categorie_types/"+EntrepriseId, { headers: authHeader() })
  return res
};

const getTypeCategorie = (id) => {
  return axios.get(API_URL + "categorie_type/" + id, { headers: authHeader() });
};

const updateTypeCategorie = (id, typeCategorie) => {
  return axios.put(API_URL + "categorie_type/" + id, typeCategorie, {
    headers: authHeader(),
  });
};

const deleteTypeCategorie = (id) => {
  return axios.delete(API_URL + "categorie_type/" + id, { headers: authHeader() });
};


const exportMethodes = {
  addTypeCategorie,

  getTypeCategorie,
  getTypeCategories,
  updateTypeCategorie,
  deleteTypeCategorie,

};
export default exportMethodes;
