import { ConfigProvider, Layout } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import "./App.css";
import { history } from "./helpers/history";
import AddBalance from "./pages/Bilan/AddBalance";
import Balances from "./pages/Bilan/Balances";
import Categorie from "./pages/Categories/Categorie";
import AddDepensePage from "./pages/Depenses/AddDepensePage";
import DepenseV2 from "./pages/Depenses/DepenseV2";
import FicheDepense from "./pages/Depenses/FicheDepense";
import AddPaiement from "./pages/Depenses/paiement/AddPaiement";
import Paiement from "./pages/Depenses/paiement/Paiement";
import UpdatePaiement from "./pages/Depenses/paiement/UpdatePaiement";
import EcheancePage from "./pages/Echeance/EcheancePage";
import Home from "./pages/Home/Home";
import NotFound from "./pages/Home/NotFound/NotFound";
import NomModule from "./pages/nomModule/NomModule";
import NotAuthorized from "./pages/NotAuthorizedPage/NotAuthorizedPage";
import Contrat from "./pages/Parametres/Contrat/Contrat";
import TypeEcheance from "./pages/Parametres/Echeance/EcheanceType";
import Entreprise from "./pages/Parametres/Entreprise/Entreprise";
import Exercice from "./pages/Parametres/Exercice/Exercice";
import ExpertDashboared from "./pages/Parametres/ExpetComprtable/EcpertDashboared";
import ExpertLogin from "./pages/Parametres/ExpetComprtable/ExpertLogin";
import Fournisseur from "./pages/Parametres/Fournisseur/Fournisseur";
import Frequence from "./pages/Parametres/Frequence/frequence";
import PaiementParms from "./pages/Parametres/Paiement/Paiement";
import BalanceConfig from "./pages/Parametres/Rapport/BalanceConfig";
import RapportHelper from "./pages/Parametres/Rapport/RapportHelper";
import TaxesAndTva from "./pages/Parametres/TaxesAndTva/TaxesAndTva";
import TypeCategorie from "./pages/Parametres/TypeCategorie/TypeCategorie";
import TypeDepense from "./pages/Parametres/TypeDepense/TypeDepense";
import Vehicule from "./pages/Parametres/Vehicule/Vehicule";
import ProfitCenter from "./pages/Profit_Center/ProfitCenter";
import RapportDepense from "./pages/Rapport/RapportDepense";
import SiginIn from "./pages/SiginIn/SignIn";
import ListeUser from "./pages/User&Permission/User/listUser";
import exportObj from "./services/user.service";
import AppFooter from "./UIcomponent/footer/AppFooter";
import exportMessages from "./UIcomponent/messages/messages";
import SideNav from "./UIcomponent/SideNav/SideNav";
import NavbarTop from "./UIcomponent/TopNav/TopNav";
import Revenu from "./pages/Educo/Revenu";
import AfftectationSCDP from "./pages/Educo/AfftectationSCDP";
import Analyse from "./pages/Educo/Analyse";
const { Content } = Layout;

const App = () => {
    
    const msg = useSelector((state) => state.message);

    const [collapsed, setCollapsed] = useState(false);

    const { user: currentUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [userSta, setuser] = useState(currentUser);
    const onCollapse = () => {
        setCollapsed(!collapsed);
    };
    useEffect(() => {
        setuser(currentUser);
        history.listen((location) => {
            dispatch(clearMessage());
        });
    }, [dispatch]);

    const checkExpirationDate = () => {
        if (localStorage.getItem("expire")) {
            const date = moment(localStorage.getItem("expire")).valueOf();
            const nowDate = moment(new Date()).valueOf();
            var isafter = date < nowDate;
            if (isafter) {
                dispatch(logout());
                history.push("/signin");
            }
        }
    };
    const reloadPageAfterChangeModule = () => {
        history.push("/");
    };

    if (msg.message === "notuser") {
        exportMessages.errorMessage("Utilisateur non trouvé ");
    } else if (msg.message === "password") {
        exportMessages.errorMessage("Mot de passe incorrecte");
    } else if (msg.message === undefined && !currentUser.type) {
        history.push("/");
        exportMessages.addSuccess("Bienvenue au GCF");

        setTimeout(() => {
            window.location.reload();
        }, 200);
    } else if (msg.message === undefined && !!currentUser.type) {
        history.push("/expert");
    }

    let user = currentUser?.token
        ? currentUser.username
        : currentUser?.data?.token
        ? currentUser.data.username
        : "";
    if (JSON.parse(localStorage.getItem("user"))) {
        exportObj
            .getUser(JSON.parse(localStorage.getItem("user")).id)
            .then((el) => {
                let newPermisssions = JSON.stringify(el.data.arrayPermissions);
                let oldPermissions = JSON.stringify(
                    JSON.parse(localStorage.getItem("permissions"))
                );
                localStorage.setItem(
                    "permissions",
                    JSON.stringify(el.data.arrayPermissions)
                );
                if (
                    newPermisssions !== oldPermissions &&
                    !JSON.parse(localStorage.getItem("user")).type
                ) {
                    exportMessages.errorMessage(
                        "Vos permissions ont été changés"
                    );
                    setTimeout(() => {
                        window.location.reload();
                    }, 200);
                }
            });
    }
    return (
        <Router history={history}>
            <ConfigProvider>
                <Switch>
                    {(!!currentUser?.type || !!currentUser?.data?.type) && (
                        <Route
                            exact
                            path="/expert"
                            component={ExpertDashboared}
                        />
                    )}

                    {currentUser?.token || currentUser?.data?.token ? (
                        <>
                            <Layout style={{ minHeight: "100vh" }}>
                                <SideNav
                                    onCollapse={onCollapse}
                                    collapsed={collapsed}
                                />
                                <Layout
                                    style={{
                                        marginLeft: !collapsed
                                            ? "250px"
                                            : "80px",
                                        transition: "0.45s",
                                    }}
                                    className="site-layout-background"
                                    theme="light"
                                >
                                    <NavbarTop user={user} />

                                    <Content >
                                        {/* <BreadCrumb /> */}
                                        <div className="site-layout-background m-4 p-4">
                                            <Switch>
                                                <Route
                                                    exact
                                                    path="/"
                                                    component={Home}
                                                />
                                                <Route
                                                    path="/methode_paiement"
                                                    component={PaiementParms}
                                                />
                                                <Route
                                                    path="/Paiement/"
                                                    component={Paiement}
                                                />
                                                <Route
                                                    path="/Users"
                                                    component={ListeUser}
                                                />
                                                <Route
                                                    path="/Taxes"
                                                    component={TaxesAndTva}
                                                />
                                                <Route
                                                    path="/Contrat"
                                                    component={Contrat}
                                                />
                                                <Route
                                                    path="/users"
                                                    component={ListeUser}
                                                />
                                                <Route
                                                    path="/Vehicules"
                                                    component={Vehicule}
                                                />
                                                <Route
                                                    path="/entreprise"
                                                    component={Entreprise}
                                                />
                                                <Route
                                                    path="/categorie"
                                                    component={Categorie}
                                                />
                                                <Route
                                                    path="/profitcenter"
                                                    component={ProfitCenter}
                                                />
                                                <Route
                                                    path="/AddDepense"
                                                    component={AddDepensePage}
                                                />
                                                <Route
                                                    path="/AddPaiement"
                                                    component={AddPaiement}
                                                />
                                                <Route
                                                    path="/TypeCategorie"
                                                    component={TypeCategorie}
                                                />
                                                <Route
                                                    path="/NomModule"
                                                    component={() => (
                                                        <NomModule
                                                            reload={
                                                                reloadPageAfterChangeModule
                                                            }
                                                        />
                                                    )}
                                                />

                                                <Route
                                                    path="/updatePaiement"
                                                    component={UpdatePaiement}
                                                />
                                                <Route
                                                    path="/Depensev2"
                                                    component={DepenseV2}
                                                />
                                                <Route
                                                    path="/ficheDepense"
                                                    component={FicheDepense}
                                                />
                                                <Route
                                                    path="/echeance"
                                                    component={EcheancePage}
                                                />
                                                <Route
                                                    path="/Fournisseurs"
                                                    component={Fournisseur}
                                                />
                                                <Route
                                                    path="/Frequence"
                                                    component={Frequence}
                                                />
                                                <Route
                                                    path="/TypeDepense"
                                                    component={TypeDepense}
                                                />
                                                <Route
                                                    path="/TypeEcheance"
                                                    component={TypeEcheance}
                                                />
                                                <Route
                                                    path="/rapport_depense"
                                                    component={RapportDepense}
                                                />
                                                <Route
                                                    path="/add_balance"
                                                    component={AddBalance}
                                                />
                                                <Route
                                                    path="/balance"
                                                    component={Balances}
                                                />
                                                <Route
                                                    path="/exercice"
                                                    component={Exercice}
                                                />
                                                <Route
                                                    path="/rapport_helper"
                                                    component={RapportHelper}
                                                />
                                                <Route
                                                    path="/balance_config"
                                                    component={BalanceConfig}
                                                />
                                                <Route
                                                    path="/revenu"
                                                    component={Revenu}
                                                />
                                                <Route
                                                    path="/affectation_service_centre_de_profit"
                                                    component={AfftectationSCDP}
                                                />
                                                <Route
                                                    path="/analyse"
                                                    component={Analyse}
                                                />
                                                <Route
                                                    path="/401"
                                                    component={NotAuthorized}
                                                />
                                                <Route component={NotFound} />
                                            </Switch>
                                        </div>
                                    </Content>
                                    <AppFooter />
                                </Layout>
                            </Layout>
                            )
                        </>
                    ) : (
                        <>
                            <Redirect to="/signin" />
                            <Route path="/signin" component={SiginIn} />
                            {
                                <Route
                                    path="/signinexpert"
                                    component={ExpertLogin}
                                />
                            }
                            {}
                        </>
                    )}

                    <Route path="/404" component={NotFound} />
                    <Redirect from="*" to="/404" />
                </Switch>
            </ConfigProvider>
        </Router>
    );
};
export default App;
