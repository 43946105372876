import gcfHeader from "./gcf-header";
import axios from "axios";
import { environment } from "../env/config.env";
import authHeader from "./auth-header";

const API_URL = environment.api;
// const GCF_URL = environment.gcf;
const GCF_URL = environment.demo03;

const getServices = async () => {
    // return await axios.get( GCF_URL + "/api/getservice",{ headers: gcfHeader() });
    return await axios.get( GCF_URL + "/api/services",{ headers: gcfHeader() });
};

const storeServices = async (service, anneScolaireId) => {
    return await axios.post( API_URL + "service/" + anneScolaireId, service, { headers: authHeader() });
};

const getServiceEleve = async ( eleveApi ) => {
    return await axios.get( GCF_URL + eleveApi ,{ headers: gcfHeader() });
};

const getEleveEchancies = async ( eleveApi ) => {
    return await axios.get( GCF_URL + eleveApi ,{ headers: gcfHeader() });
};

const getEleve = async ( eleveApi ) => {
    return await axios.get( GCF_URL + eleveApi ,{ headers: gcfHeader() });
};

const getEleveEchancierObject = async ( eleveApi ) => {
    return await axios.get( GCF_URL + eleveApi ,{ headers: gcfHeader() });
};

const getAnneScolaire = async ( eleveApi ) => {
    return await axios.get( GCF_URL + eleveApi ,{ headers: gcfHeader() });
};

const getAllRevenus = async ( ) => {
    return await axios.get( API_URL + "revenu" ,{ headers: authHeader() });
};

const storeRevenus = async ( revenus ) => {
    return await axios.post( API_URL + "revenu", revenus ,{ headers: authHeader() });
};

const storeAnneScolaires = async ( annesScolaire ) => {
    return await axios.post( API_URL + "anneScolaire", annesScolaire ,{ headers: authHeader() });
};

const getCentreServiceAnne = async ( ) => {
    return await axios.get( API_URL + "centreServiceAnne" ,{ headers: authHeader() });
};

const storeCentreServiceAnne = async ( centreServiceAnne ) => {
    return await axios.post( API_URL + "centreServiceAnne", centreServiceAnne ,{ headers: authHeader() });
};

const updateCentreServiceAnne = async ( centreServiceAnne, id ) => {
    return await axios.put( API_URL + "centreServiceAnne/" + id, centreServiceAnne ,{ headers: authHeader() });
};

const deleteCentreServiceAnne = async ( id ) => {
    return await axios.delete( API_URL + "centreServiceAnne/"+ id ,{ headers: authHeader() });
};

const exportMethodes = {
    getServices,
    getServiceEleve,
    getEleveEchancies,
    getEleve,
    getEleveEchancierObject,
    getAllRevenus,
    storeRevenus,
    storeServices,
    getAnneScolaire,
    storeAnneScolaires,
    storeCentreServiceAnne,
    getCentreServiceAnne,
    deleteCentreServiceAnne,
    updateCentreServiceAnne
};
export default exportMethodes;