const bilanTitles = [
    { title : "PRODUITS D'EXPLOITATION" , emplacement : 0 , hide: true },
    { title : "Total des produits d'exploitation" , emplacement : 1 , hide: false  },
    { title : "CHARGES D'EXPLOITATION" , emplacement : 0 , hide: true },
    { title : "Total des charges d'exploitation" , emplacement : 1 , hide: false  },
    { title : "Résultat d'exploitation" , emplacement : 0 , hide: true },
    { title : "Résultat des activités ordinaires avant impôt" , emplacement : 0 , hide: false },
    { title : "Résultat des activités ordinaires après impôt" , emplacement : 0 , hide: false },
    { title : "Résultat net de l'exercice" , emplacement : 0 , hide: false  },
    { title : "Résultat après modifications comptables" , emplacement : 0 , hide: false },
    { title : "CAPITAUX PROPRES" , emplacement : 0 , hide: true },
    { title : "Capitaux propres" , emplacement : 1 , hide: true },
    { title : "TOTAL CAPITAUX PROPRES" , emplacement : 0, hide: false  },
    { title : "PASSIFS" , emplacement : 0 , hide: true },
    { title : "Passifs non courants" , emplacement : 1 , hide: true },
    { title : "Total passifs non courants" , emplacement : 1, hide: false },
    { title : "Passifs courants" , emplacement : 1 , hide: true },
    { title : "Total passifs courants" , emplacement : 1 , hide: false },
    { title : "TOTAL DES PASSIFS" , emplacement : 0 , hide: false },
    { title : "TOTAL DES CAPITAUX PROPRES ET PASSIFS" , emplacement : 0 , hide: false  },
    { title : "ACTIFS NON COURANTS" , emplacement : 0 , hide: true },
    { title : "Actifs immobilisés" , emplacement : 1 , hide: true },
    { title : "Total des actifs immobilisés" , emplacement : 1, hide: false },
    { title : "TOTAL DES ACTIFS NON COURANTS" , emplacement : 0 , hide: false},
    { title : "ACTIFS COURANTS" , emplacement : 1 , hide: true },
    { title : "TOTAL DES ACTIFS COURANTS" , emplacement : 0 , hide: false },
    { title : "TOTAL DES ACTIFS" , emplacement : 0 , hide: false },
    { title : "Flux de trésorerie liés à l'exploitation" , emplacement : 0 , hide: true  },
    { title : "Flux de trésorerie liés aux activités d'investissement" , emplacement : 0 , hide: true },
    { title : "Flux de trésorerie liés aux activités de financement" , emplacement : 0 , hide: true },
    { title : "TOTAL Flux de trésorerie liés à l'exploitation" , emplacement : 0 , hide: false },
    { title : "TOTAL Flux de trésorerie liés aux activités d'investissement" , emplacement : 0, hide: false },
    { title : "TOTAL Flux de trésorerie liés aux activités de financement" , emplacement : 0, hide: false },
    { title : "Résultat net" , emplacement : 1, hide: false },
    { title : "Variation de trésorerie" , emplacement : 0, hide: true },
    { title : "TOTAL Variation de trésorerie" , emplacement : 0, hide: false },
    { title : "Trésorerie au début de l'exercice" , emplacement : 1 , hide: false},
    { title : "Trésorerie à la clôture de l'exercice" , emplacement : 1, hide: false },

];
export default bilanTitles;
