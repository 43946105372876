import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Button, Card, Modal, Tooltip, Form, Row, Col, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import paiementService from "../../../services/paiement.service";
import depenseService from "../../../services/depense.service";
import AppTable from "../../../UIcomponent/Table/AppTable";
import moment from "moment";
import currency from "../../../helpers/currency";
import Can from "../../../Can/Can";
import exportMessages from "../../../UIcomponent/messages/messages";
import { useSelector } from "react-redux";

const Option = { Select };
const Paiement = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [paiements, setPaiements] = useState([]);
  const [depenses, setDepenses] = useState([]);
  const [depensesAll, setDepensesAll] = useState([]);
  const [depensesSelected, setDepensesSelected] = useState([]);
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  const [form] = Form.useForm();
  const formatteDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };
  const handleOk = () => {
    setDepensesSelected(depensesAll[form.getFieldValue().depense]);
    !!depensesAll[form.getFieldValue().depense]
      ? history.push("/AddPaiement", {
          record: depensesAll[form.getFieldValue().depense],
          from: "paiement",
        })
      : exportMessages.errorMessage("Veuiller sélectionner une dépense ");
    setVisible(false);
  };
  const formattDepense = (depensess) => {

    let depenseSele = depensess.map((depense, index) => (
      <Option label={depense.titre} value={index}>
        {depense.titre +
          "   --  " +
          depense.montant.total_ttc +
          "  " +
          currency[depense.devise].code}{" "}
      </Option>
    ));
    setDepenses(depenseSele);
  };
  const getDepenses = async () => {
    let response = await depenseService.getDepenses();
    if (response.status === 200) {
      formattDepense(response.data.filter((el) => el.statut !== -1));
      setDepensesAll(response.data);
    }
  };
  const formattePaiements = (data) => {
    let newData = data.map((el) => {
      let newEl = {
        ...el,
        methodedePaiement: el.methodePaiement.nom,
        typeCompte: el.methodePaiement.type_account,
        depenseName: el.depense.titre,
        datePaiement: formatteDate(el.date_paiement),
        dateEncaissesment: formatteDate(el.date_ecaissement),
        categorie: el.depense.categorie?.categorie_nom,
        pc: el.depense.profitCenter?.profitCenter_nom,
      };
      return newEl;
    });
    setPaiements(newData);
  };
  const getStatus = (status) => {
    switch (status) {
      case 0:
        return "à payer".toUpperCase();
      case 1:
        return "En cours".toUpperCase();
      default:
        return "payé".toUpperCase();
    }
  };
  const getPaiements = async () => {
    let res = await paiementService.getPaiements();
    if(res.status === 200){
      formattePaiements(res.data);
    } else exportMessages.errorMessage("une erreur est surevenue");
  };
  useEffect(() => {
   getPaiements();
  }, []);
  const showDetails = async (record) => {
    let depense = await depenseService.getDepense(record.depense.depense_id);

    depense.status === 200
      ? history.push("/ficheDepense", {
          record: { ...depense.data, statut: getStatus(depense.data.statut) },
          from: "paiement",
        })
      : exportMessages.errorMessage("une erreur est survenue");
  };
  const openPaiementPage = () => {
    getDepenses();
    setVisible(true);
  };
  const columns = [
    {
      title: "Dépense",
      key: "paiment",
      dataIndex: "libelle",
      sorter: (a, b) => a.libelle.localeCompare(b.libelle),
    },
    {
      title: "Charge",
      key: "depenseName",
      dataIndex: "depenseName",
      sorter: (a, b) => a.depenseName.localeCompare(b.depenseName),
    },
    {
      title: "Montant",
      key: "montant",
      dataIndex: "montant",
      sorter: (a, b) => a.montant - b.montant,
    },

    {
      title: "Date de paiement",
      key: "datePaiement",
      dataIndex: "datePaiement",
      sorter: (a, b) => a.datePaiement.localeCompare(b.datePaiement),
    },

    {
      title: "Date de décaissement",
      key: "dateEncaissesment",
      dataIndex: "dateEncaissesment",
      sorter: (a, b) => a.dateEncaissesment.localeCompare(b.dateEncaissesment),
    },

    {
      title: "Méthode de paiement",
      key: "methodedePaiement",
      dataIndex: "methodedePaiement",
      sorter: (a, b) => a.methodedePaiement.localeCompare(b.methodedePaiement),
    },
    {
      title: "Catégorie",
      key: "categorie",
      dataIndex: "categorie",
      sorter: (a, b) => a.categorie.localeCompare(b.categorie),
    },
    {
      title: "Centre de profit",
      key: "pc",
      dataIndex: "pc",
      sorter: (a, b) => a.pc.localeCompare(b.pc),
    },
    {
      title: "Actions",
      key: "typeCompte",
      dataIndex: "typeCompte",
      render: (text, record) => (
        <>
          <Tooltip title="voir détails dépense">
            {(Can("16_5")|| !! currentUser) && (
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => showDetails(record)}
              />
            )}
          </Tooltip>
          <Tooltip title="voir détails dépense">
            {(Can("16_4")|| !! currentUser) && (
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => {
                  Modal.confirm({
                    type: "info",
                    title: "Attention",
                    icon: <ExclamationCircleOutlined />,
                    content:
                      "Etes vous sur de vouloir supprimer ce paiement ? ",
                    onOk: () => {
                      //deleteFunction
                    },
                    okText: "Oui",
                    cancelText: "Non",
                  });
                }}
              />
            )}
          </Tooltip>
        </>
      ),
    },
  ];
  return (
    <>
      {(Can("16_1") || !! currentUser) && (
        <Card
          headStyle={{ backgroundColor: "#dee0e9" }}
          title="Liste des Dépenses "
          style={{ width: "100%" }}
          extra={
          (  Can("16_2")|| !! currentUser) && (
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                onClick={openPaiementPage}
              >
                Ajouter  Dépense
              </Button>
            )
          }
        >
          <AppTable data={paiements} columns={columns} />
        </Card>
      )}
      <Modal
        title="Ajouter Dépense" 
        visible={visible}
        okText="Aller vers page de paiement "
        onOk={() => handleOk() }
        cancelText="Annuler"
        onCancel={() => setVisible(false)}
      >
        <Form layout="vertical" form={form}>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Charge à payer :"
                name="depense"
                rules={[{ required: true, message: "Champ obligatoire !" }]}
              >
                <Select size="large">{depenses}</Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default Paiement;
