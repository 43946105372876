import React from "react";
import titles from "../../helpers/bilanTitles.js";

const ExportTables = ({actifs , passifs, resultat ,flux, exercice1, exercice2 }) => {

    const getStyle = (element) => {
        
        const title = titles.find(e => (e.title === element.attribut));
        if(title?.emplacement === 1 || title?.emplacement === 0 ){
            if( title?.emplacement === 1) return "text-left padding-left-small italic"
            if( title?.emplacement === 0) return "text-left bold"

        }else{
            return  "text-left margin-left-large"
        }
    }

    return (
        <div className="text-left hidden" >
            <table className="table table-striped text-left" id="actif-table" >
                <thead>
                    <tr>
                        <th scope="col" colSpan={3} rowSpan={2}>
                            ACTIFS
                        </th>
                    </tr>
                    <tr></tr>
                    <tr className="text-left">
                        <th scope="col">
                            Actif
                        </th>
                        <th scope="col" >
                            {exercice1}
                        </th>
                        <th scope="col" >
                            {exercice2}
                        </th>
                    </tr>
                </thead>
                <tbody className="text-left">
                    {actifs &&
                        actifs.map((actif) => (
                            <tr>
                                <th className={getStyle(actif)}>{actif.attribut}</th>
                                <td>{actif.val}</td>
                                <td>{actif.valMinusOne}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <table className="table table-striped" id="passif-table">
                <thead>
                    <tr>
                        <th scope="col" colSpan={3} rowSpan={2}>
                            PASSIFS
                        </th>
                    </tr>
                    <tr></tr>
                    <tr>
                        <th scope="col">
                            CAPITAUX PROPRES ET PASSIFS
                        </th>
                        <th scope="col" >
                            {exercice1}
                        </th>
                        <th scope="col" >
                            {exercice2}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {passifs &&
                        passifs.map((actif) => (
                            <tr>
                                <th className="text-left" >{actif.attribut}</th>
                                <td>{actif.val}</td>
                                <td>{actif.valMinusOne}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <table className="table table-striped" id="flux-table">
                <thead>
                    <tr>
                        <th scope="col" colSpan={3} rowSpan={2}>
                            ETAT DES FLUX DE TRESORERIE
                        </th>
                    </tr>
                    <tr></tr>
                    <tr>
                        <th scope="col">
                            Libelle
                        </th>
                        <th scope="col" >
                            {exercice1}
                        </th>
                        <th scope="col" >
                            {exercice2}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {flux && flux.map((f) => (
                        <tr>
                            <th className="text-left" >{f.attribut}</th>
                            <td>{f.val}</td>
                            <td>{f.valMinusOne}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <table className="table table-striped" id="result-table">
                <thead>
                    <tr>
                        <th scope="col" colSpan={3} rowSpan={2}>
                            RESULTAT
                        </th>
                    </tr>
                    <tr></tr>
                    <tr>
                        <th scope="col">
                            Resultat
                        </th>
                        <th scope="col" >
                            {exercice1}
                        </th>
                        <th scope="col" >
                            {exercice2}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {resultat &&
                        resultat.map((result) => (
                            <tr>
                                <th >{result.attribut}</th>
                                <td>{result.val}</td>
                                <td>{result.valMinusOne}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
};
export default ExportTables;
