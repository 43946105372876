import {
    CREATE_FOURNISSEUR,
    RETRIEVE_FOURNISSEUR,
  } from "./type";
  
  import fournisseurService from "../services/fournisseur.service";
 
  export const getAllFournisseur = () => async (dispatch) => {
    try {
      const res = await fournisseurService.getAllFournisseurs();
      dispatch({
        type: RETRIEVE_FOURNISSEUR,
        payload:res.data
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const addFournisseur = () => async (dispatch) => {
    try {
      const res = await fournisseurService.addFournisseur();
      dispatch({
        type: CREATE_FOURNISSEUR,
        payload: res.data,
      });

    } catch (err) {
      console.log(err);
    }
  };
  
 
 