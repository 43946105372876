import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, Tooltip, Modal, Form, Card, Row, Col, Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import categorieTypeService from "../../../services/categorie-type.service";
import exportMessages from "../../../UIcomponent/messages/messages";
import AppTable from ".././../../UIcomponent/Table/AppTable";

const TypeCategorie = () => {
    const [typesCategories, setTypescategories] = useState();
    const [visibleUpdate, setVisibleUpdate] = useState(false);
    const [recordData, setrecordData] = useState('d');
    const [loading, setLoading] = useState(true);

    const [typeForm] = Form.useForm();

const handleSetrecordData =(data)=>{
    setrecordData(data)
}

    useEffect(() => {
        getTypes();
    }, [loading]);

    const columns = [
        {
            title: () => (
                <Tooltip title="Nom de type de  catégorie">Nom</Tooltip>
            ),
            dataIndex: "categorie_type_nom",
            key: "categorie_type_id",
            sorter: (a, b) =>
                a.categorie_type_nom.localeCompare(b.categorie_type_nom),
        },
        {
            title: "Actions",
            key: "action",
            width: "180px",
            render: (text, record) => (
                <div>
                    {" "}
                    <Tooltip title="Éditer ce type de catégorie ">
                        <Button
                            className="mx-1"
                            type="dashed"
                            shape="circle"
                            onClick={() => {
                                handleUpdate(record);
                            }}
                            icon={<EditOutlined />}
                        />
                    </Tooltip>
                    <Tooltip title="Supprimer ce type de catégorie ">
                        <Button
                            className="mx-1"
                            type="dashed"
                            shape="circle"
                            onClick={() => {
                                handleDeleteType(record);
                            }}
                            icon={<DeleteOutlined />}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    const getTypes = async () => {
        const typesCategorie = await (
            await categorieTypeService.getTypeCategories()
        ).data;
        setTypescategories(typesCategorie);
        setLoading(false)
    };
    const addTypeCategorie = async (type) => {
       setLoading(true)
        const res = recordData!=='d' ? await categorieTypeService.updateTypeCategorie(recordData.category_type_id ,type ) : await categorieTypeService.addTypeCategorie(type);
        [200, 201].includes(res.status)
            ? exportMessages.addSuccess("Type ajouté avec succées ")
            : exportMessages.errorMessage("un erreur est survenue ");
        setVisibleUpdate(false);
        typeForm.resetFields();
        getTypes()
        setLoading(false)
        setrecordData('d')

    };
    const openModalAdd = () => {
        setVisibleUpdate(true);
    };
    const handleUpdate = async(record) => {
        setrecordData(record)
        setLoading(true)
        typeForm.setFieldsValue(record);
        setLoading(false)
        openModalAdd(record);

    };
    const deleteTypeCategorie = async (id) => {
        await categorieTypeService.deleteTypeCategorie(id).then((el) => {
            el.status === 200
                ? exportMessages.addSuccess("Type supprimé avec succées")
                : exportMessages.errorMessage("un erreur est survenue ");
        });
        getTypes();
    };
    const handleDeleteType = (record) => {
        Modal.confirm({
            type: "info",
            title: "Attention",
            icon: <ExclamationCircleOutlined />,
            content:
                "Etes vous sur de vouloir supprimer ce type de  catégorie ? ",
            onOk: () => {
                deleteTypeCategorie(record.category_type_id);
            },
            okText: "Oui",
            cancelText: "Non",
        });
    };

    const handleCancel = () => {
        setVisibleUpdate(false);
        typeForm.resetFields();
    };
    return (
        <Spin spinning={loading}>
        <Card
            headStyle={{ backgroundColor: "#dee0e9" }} 
            title=" Types des Catégories"
            extra={
                <Button
                    onClick={() => openModalAdd()}
                    type="primary"
                    className="mx-2"
                    shape="round"
                >
                    Ajouter un nouveau type
                </Button>
            }
        >
           
            <AppTable data={typesCategories} columns={columns} />
            <Modal
                visible={visibleUpdate}
                onCancel={() => {
                    handleCancel();
                }}
                onOk={typeForm.submit}
                okText="Valider"
                cancelText="Annuler"
            >
                <Form
                    layout="vertical"
                    form={typeForm}
                    onFinish={addTypeCategorie}
                >
                    {" "}
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Nom de type de catégorie"
                                name="categorie_type_nom"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Champ obligatoire avec maximum de caractère 255 !",
                                        max: 254,
                                    },
                                ]}
                            >
                                <Input size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Card>
        </Spin>
    );
};
export default TypeCategorie;
