import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
  } from "@ant-design/icons";
  import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Modal,
    Row,
    Spin,
    Tooltip,
  } from "antd";
  import React, { useEffect, useRef, useState } from "react";
  import { useSelector } from "react-redux";
  import Can from "../../../Can/Can";
  import RapportHelperService from "../../../services/bilan.service";
  import messages from "../../../UIcomponent/messages/messages";
  import AppTable from "../../../UIcomponent/Table/AppTable";
  import NotAuthorized from "../../NotAuthorizedPage/NotAuthorizedPage";
  import JoditEditor from "jodit-react";
import exportMessages from "../../../UIcomponent/messages/messages";

  const { TextArea } = Input;
  
  function RapportHelper() {
    const { user: currentUser } = useSelector((state) => state.auth);
    const editor = useRef(null)

    const [rapportHelperModal, setRapportHelperModal] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [helperform] = Form.useForm();
    const [dataTable, setDataTable] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [updateId, setUpdateId] = useState();
    const [content, setContent] = useState('');

    const config = {
		  readonly: false 
	  }

    useEffect(() => {
      getRapportHelpers();
      return () => {
        clearStates();
      };
    }, []);
  
    const clearStates = () => {
      setDataTable([]);
      setEditMode(false);
      setUpdateId(null);
    };

    const getRapportHelpers = async () => {
      setLoading(true);
      const response = await RapportHelperService.getRapportHelpers();
      if (response.status === 200 || 204) initTableData(response.data);
      else messages.errorMessage();
      setLoading(false);
    };
    
    const RapportHelperColumn = [
      {
        title: "Titre",
        dataIndex: "attribut",
        sorter: (a, b) => a.attribut.localeCompare(b.attribut),
        width: "85%",
      },{
        title: "Action",
        key: "action",
        render: (text, record) => (
          <div>
            <Tooltip title="Mettre à jour">
              {(!!currentUser|| Can("10_3")) && (
                <Button
                  className="mx-1"
                  type="dashed"
                  shape="circle"
                  onClick={() => updateMode(record)}
                  icon={<EditOutlined />}
                />
              )}
            </Tooltip>
            <Tooltip title="Supprimer">
              {(!!currentUser|| Can("10_4")) && (
                <Button
                  className="mx-1"
                  type="dashed"
                  shape="circle"
                  onClick={() => confirmDelete(record)}
                  icon={<DeleteOutlined />}
                />
              )}
            </Tooltip>
          </div>
        ),
      },
    ];
  
    const confirmDelete = (record) => {
      Modal.confirm({
        title: "Attention",
        icon: <ExclamationCircleOutlined />,
        content: "Êtes-vous sûr de vouloir supprimer ce section ? ",
        onOk: () => deleteItem(record),
        okText: "Oui",
        cancelText: "Non",
      });
    };
    const updateMode = (record) => {
      setRapportHelperModal(true);
      helperform.setFieldsValue(record);
      setUpdateId(record.bilan_id);
      setEditMode(true);
    };
  
    const initTableData = (data) => {
      setDataTable(data);
    };
  
    const deleteItem = async (item) => {
      console.log(item);
      const response = await RapportHelperService.deleteHelper(item.bilan_id);
      if (response.status === 200 || 204) {
        messages.addSuccess("Suppression avec succès ! ");
        getRapportHelpers(response.data);
      } else messages.errorMessage();
    };
  
    const closingModal = () => {
      getRapportHelpers();
      setRapportHelperModal(false);
      setConfirmLoading(false);
      clearData();
    };
  
    const addHelper = async (values) => {
      setLoading(true);
      const data = helperform.getFieldsValue()
      const newNote = {attribut: data.attribut, val : 0  , description : data.description , tableName: "helper"}
      console.log(newNote);
      const response = editMode
        ? await RapportHelperService.updateHelperNote(updateId, newNote) 
        : await RapportHelperService.addNewNote( newNote );
      if (response.status === 200 || 204) {
        if (editMode) updateSuccess();
        else addSuccess();
        
        getRapportHelpers()
      }else exportMessages.errorMessage();
      setLoading(false);
    };
  
    const closeRapportHelperModal = () => {
      setRapportHelperModal(false);
      setConfirmLoading(false);
      clearData();
    };
  
    const clearData = () => {
      helperform.resetFields();
      setEditMode(false);
      setUpdateId(null);
    };
  
    const addSuccess = () => {
      messages.addSuccess("Section ajoutée avec succès ");
      closingModal();
    };
    const updateSuccess = () => {
      messages.updateSuccess("Mise à jour de la section avec success");
      closingModal();
    };
    const errorMessage = () => {
      setConfirmLoading(false);
      messages.errorMessage();
    };
  
    return (
      <Spin spinning={isLoading} size="large">
        {(!!currentUser|| Can("10_1")) ? (
          <Card
            headStyle={{ backgroundColor: "#dee0e9" }}
            title="Liste des sections du rapport "
            style={{ width: "100%" }}
            extra={
              (!!currentUser|| Can("10_2")) && (
                <Button
                  className="mx-2"
                  shape="round"
                  type="primary"
                  onClick={() => setRapportHelperModal(true)}
                >
                  Ajouter une section
                </Button>
              )
            }
          >
            <AppTable data={dataTable} columns={RapportHelperColumn} />
          </Card>
        ) : (
          <NotAuthorized />
        )}
        <Modal
          title="Ajouter une section"
          centered
          okText="Enregistrer"
          cancelText="Annuler"
          getContainer={false}
          confirmLoading={confirmLoading}
          visible={rapportHelperModal}
          onOk={helperform.submit}
          onCancel={closeRapportHelperModal}
          width={1200}
        >
          <Form layout="vertical" form={helperform} onFinish={addHelper}>
            <Row>
              <Col span={24} className="px-2">
                <Form.Item
                  label="Nom du section "
                  name="attribut"
                  rules={[
                    {
                      required: true,
                      message:
                        "Champ obligatoire avec maximum de caractère 100 !",
                      max: 99,
                    },
                  ]}
                >
                  <Input
                    size="large"
                    type="text"
                    maxLength={100}
                  />
                </Form.Item>
              </Col>
              <Col span={24} className="px-2">
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Champ obligatoire avec maximum de caractère 100 !",
                    },
                  ]}
                >
                <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    placeholder={''}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Spin>
    );
  }
  export default RapportHelper;
  