import authHeader from "./auth-header";

import axios from "axios";
import { environment } from "../env/config.env";
const API_URL = environment.api;
let   EntrepriseId = (localStorage.getItem('entreprise'))

const addPaiementMethode = (paiement) => {
  return axios.post(API_URL + "methodepaiement/" + EntrepriseId, paiement, {
    headers: authHeader(),
  });
};

const getPaiementMethodes = () => {
  EntrepriseId = (localStorage.getItem('entreprise'))
  return axios.get(API_URL + "methodepaiements/"  + EntrepriseId, { headers: authHeader() });
};

const getPaiementMethode = (id) => {
  return axios.get(API_URL + "methodepaiement/" + id, {
    headers: authHeader(),
  });
};

const updatePaiementMethode = (id, paiement) => {
  return axios.put(API_URL + "methodepaiement/" + id, paiement, {
    headers: authHeader(),
  });
};

const deletePaiementMethode = (id) => {
  return axios.delete(API_URL + "methodepaiement/" + id, {
    headers: authHeader(),
  });
};

const exportMethodes = {
  addPaiementMethode,
  getPaiementMethodes,
  getPaiementMethode,
  updatePaiementMethode,
  deletePaiementMethode,
};
export default exportMethodes;
