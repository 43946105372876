import {
    Button,
    Card,
    Col,
    Collapse,
    DatePicker,
    Form, InputNumber,
    Radio,
    Row,
    Select,
    Slider,
    Spin
} from "antd";
import React, { useEffect, useState } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from "recharts";
import { PieChart, Pie , Cell } from "recharts";
import { LineChart, Line, ResponsiveContainer } from 'recharts';
import { useSelector } from "react-redux";
import Can from "../../Can/Can";
import NotAuthorized from "../NotAuthorizedPage/NotAuthorizedPage";
import profitCenterservice from "../../services/profitCenter.service";
import DepenseService from "../../services/depense.service";
import moment from "moment";
import currency from "../../helpers/currency";
import GcfService from "../../services/gcf.service";
import paiementService from "../../services/paiement.service";
import exportMessages from "../../UIcomponent/messages/messages";


const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
    x:100
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
    x:1000
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
    x:2000
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
    x:5000
  },
//   {
//     name: 'Page E',
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//     x:100  
// },
//   {
//     name: 'Page F',
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//     x:200
//   },
//   {
//     name: 'Page G',
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//     x:0
//   },
];

// expRevenus:element.montant,expCharges:0,actDepenses:0,actRevenus
const data2 = [
    {
       date: "2022-01-31",
       expCharges: -1000,
       actDepenses:0,
       expRevenus: 1000,
       actRevenus:0,
    },
    {
        date: "2022-02-20",
        expCharges: -500,
        actDepenses:0,
        expRevenus: 200,
        actRevenus:0,
    },
    {
        date: "2022-03-10",
        expCharges: 100,
        actDepenses:0,
        expRevenus: -100,
        actRevenus:0,
    }
]

const colors = [
    "#8884d8","#84D8B2","#D884AA",'#D4D884', '#84AAD8', '#B284D8','#aaaaaa'
]

const Analyse = () => {
    
    const { user: currentUser } = useSelector((state) => state.auth);
    
    const [searchForm] = Form.useForm();
    const [rangeForm] = Form.useForm();

    const [isLoading, setLoading] = useState(false);

    const [profitCenters, setProfitCenters] = useState([]);
    const [charges , setCharges] = useState([]);
    const [depenses , setDepenses] = useState([]);
    const [revenus, setRevenus] = useState([]);
    const [centreServiceAnnes, setCentreServiceAnnes] = useState([]);
    const [filtreData , setFiltreData] = useState([]);
    const [filtreDiffData , setFiltreDiffData] = useState([]);
    const [totalData , setTotalData] = useState([]);

    useEffect(() => {
        initData()
    }, []);

    const initData = async ()  => {
        setLoading(true)
        await loadProfitCenters();
        await getCharges();
        // await getServices()
        await getCentreServicesAnnes()
        await getDepenses()
        await getAllRevenus()
        initForm()
        setLoading(false)
    }

    const initForm = () => {
        searchForm.setFieldsValue({ isByMonth: true });
    };

    const loadProfitCenters = async () => {
        setLoading(true);
        const response = await profitCenterservice.getProfitCenters();
        formatteProfitCenters(response.data);
        setLoading(false);
    };

    const formatteProfitCenters = (data) => {
        const list = [];
        for (const element of data) {
            list.push({
                id: element.profitCenter_id,
                nom: element.profitCenter_nom,
            });
        }
        setProfitCenters(list);
    };

    const getCharges = async () => {
        setLoading(true);
        const response = await DepenseService.getDepenses();
        formatCharges(response.data.filter((el) => el.statut !== -1));
    };

    const formatCharges = (charges) => {
        try {
            for (const charge of charges) {
              charge.showFournisseur = charge.interne
                ? "Interne"
                : charge.fournisseur.nom;
              charge.showMontant =
                charge.montant.total_ttc + " " + getDevise(charge.devise);
              charge.showCreationDate = moment(charge.date_creation).format(
                "YYYY-MM-DD"
              );
              charge.nbrEcheance = charge.echeances.length;
              charge.statut = getStatus(charge.statut);
              charge.firstPaiementDate = moment(
                charge.echeances[0].date_limite
              ).format("YYYY-MM-DD");
              if (charge.depense_occurence?.frequence)
                charge.showFrequence =
                  "Chaque " +
                  charge.depense_occurence.frequence.periode +
                  " " +
                  charge.depense_occurence.frequence.type;
            }
        
        setCharges(charges)
        } catch (err) {
            console.log(err)
        }
        setLoading(false);
    }

    const getAllRevenus = async () => {
        const response = await GcfService.getAllRevenus();
        formatRevenus(response.data)
    }

    const formatRevenus = (data) => {
        setLoading(true)
        for(let revenu of data){
          revenu.dateDeb = formatteDate(revenu.dateDeb)
          revenu.dateFin = formatteDate(revenu.dateFin)
        }
    
        setRevenus(data)
        setLoading(false)
    }

    const getCentreServicesAnnes = async () => {
        const centreServiceAnneList = await GcfService.getCentreServiceAnne()
        setCentreServiceAnnes(centreServiceAnneList.data)
    }

    const getDepenses = async () => {
        let res = await paiementService.getPaiements();
        if(res.status === 200){
          formateDepenses(res.data);
        } else exportMessages.errorMessage("une erreur est surevenue");
    }

    const formateDepenses = (data) => {
        let newData = data.map((el) => {
          let newEl = {
            ...el,
            datePaiement: formatteDate(el.date_paiement),
            dateEncaissesment: formatteDate(el.date_ecaissement),
            pc: el.depense.profitCenter?.profitCenter_nom,
          };
          return newEl;
        });
        setDepenses(newData);
      };

    const searchData = async () => {
        const search = searchForm.getFieldsValue();
        let result1 = []
        let result2 = []
        
        if(search.dateFrom != null && search.param1 != null && search.param2 != null && search.profitCenter != null){
            switch(search.param1){
                case 0:
                    result1 = await getExpectedCharges(search)
                    break;
                case 1:
                    result1 = await getActualDepense(search)
                    break;
                case 2:
                    result1 = await getExpectedRevenus(search)
                    break;
                case 3:
                    result1 = await getActualRevenus(search)
                    break;
            }
            switch(search.param2){
                case 0:
                    result2 = await getExpectedCharges(search)
                    break;
                case 1:
                    result2 = await getActualDepense(search)
                    break;
                case 2:
                    result2 = await getExpectedRevenus(search)
                    break;
                case 3:
                    result2 = await getActualRevenus(search)
                    break;
            }

            if(result1 && result2){
                getTotalData(result1,result2, search)
                let filtredList = [...result1,...result2]
                filtredList = filtredList.sort((a,b)=> compareDates(a.date, b.date))
                // getDiffData(filtredList,search)
                if(search.isByMonth){
                    const result = groupByMonths(filtredList)
                    getDiffData(result,search)
                    setFiltreData(result)
                }else{
                    getDiffData(filtredList,search)
                    setFiltreData(filtredList)
                }
            }

        }
    }

    const getExpectedCharges = (search) => {
        let isAllProfilesCenters = false
        if(search.profitCenter == -1) isAllProfilesCenters = true
        let chargess = [...charges]
        if(!isAllProfilesCenters) chargess = chargess.filter((element)=> element.profitCenter.profitCenter_id == search.profitCenter )
        chargess = chargess.filter((element)=> isBetweenTwoDates(element.firstPaiementDate, search.dateFrom) )
        chargess = chargess.sort((a,b)=> compareDates(a.firstPaiementDate, b.firstPaiementDate))
        const filtredCharges = chargess.map((element) => ({date:formatteDate(element.firstPaiementDate), expCharges:element.montant.total_ttc,actDepenses:0,expRevenus:0,actRevenus:0}))
        return filtredCharges
    }                           

    const getActualDepense = (search) => {
        let isAllProfilesCenters = false
        if(search.profitCenter == -1) isAllProfilesCenters = true
        let depensess = [...depenses]
        if(!isAllProfilesCenters) depensess = depensess.filter((element)=> element.depense.profitCenter.profitCenter_id == search.profitCenter )
        depensess = depensess.filter((element)=> isBetweenTwoDates(element.datePaiement, search.dateFrom) )
        depensess = depensess.sort((a,b)=> compareDates(a.datePaiement, b.datePaiement))
        depensess = depensess.map((element) => ({date:formatteDate(element.datePaiement), actDepenses:element.montant,expCharges:0,expRevenus:0,actRevenus:0}))
        return depensess
    }

    const getExpectedRevenus = (search) => {
        let isAllProfilesCenters = false
        if(search.profitCenter == -1) isAllProfilesCenters = true
        // 1 - list of centreServiceAnne by profilecenterID
        let centreServiceAnness = [...centreServiceAnnes]
        let revenuss = [...revenus]
        if(!isAllProfilesCenters) centreServiceAnness = centreServiceAnness.filter((element)=> element.profitCenter.profitCenter_id == search.profitCenter)
        // 2 - get the list of service of that list (list x)
        let servicess = centreServiceAnness.map((element)=>element.service)
        // 3- get list a revenu that the service attribute in the list x
        const serviceNames = servicess.map(item => item.name);
        revenuss = revenuss.filter(item => serviceNames.includes(item.service));
        // 4- filter by date
        revenuss = revenuss.sort((a,b)=> compareDates(a.dateDeb, b.dateDeb))
        revenuss = revenuss.map((element) => ({date:formatteDate(element.dateDeb), expRevenus:element.montant,expCharges:0,actDepenses:0,actRevenus:0}))
        return revenuss
    }

    const getActualRevenus = (search) => {
        let isAllProfilesCenters = false
        if(search.profitCenter == -1) isAllProfilesCenters = true
        // 1 - list of centreServiceAnne by profilecenterID
        let centreServiceAnness = [...centreServiceAnnes]
        let revenuss = [...revenus]
        if(!isAllProfilesCenters) centreServiceAnness = centreServiceAnness.filter((element)=> element.profitCenter.profitCenter_id == search.profitCenter)
        // 2 - get the list of service of that list (list x)
        let servicess = centreServiceAnness.map((element)=>element.service)
        // 3 - get list a revenu that the service attribute in the list x
        const serviceNames = servicess.map(item => item.name);
        revenuss = revenuss.filter(item => serviceNames.includes(item.service));
        // 4 - filter by date && get only the payed montant montantPayer
        revenuss = revenuss.sort((a,b)=> compareDates(a.dateDeb, b.dateDeb))
        revenuss = revenuss.map((element) => ({date:formatteDate(element.dateDeb), actRevenus:element.montantPayer,expCharges:0,actDepenses:0,expRevenus:0}))
        return revenuss
    }

    const getTotalData = (resultParam1, resultParam2, search) => {
        let list = [];
        let total1 = 0
        let total2 = 0
        let fieldParam1 = getFields(search.param1)
        let fieldParam2 = getFields(search.param2)
        for (let element of resultParam1) total1 += element[fieldParam1]
        for (let element of resultParam2) total2 += element[fieldParam2] 

        list.push({name:fieldParam1, value: total1},{name:fieldParam2, value: total2})
        setTotalData(list)
    }

    // param1 - param2
    // 1- loop
    // 2- take care for the 1st eleemnt value = montant - montant de element index - 1 or index - 1 n'existe pas donc value = montant - 0
    // 3- if : element has montant in the param1 -> somme with the last elemnt montant
    //                                    param2 -> minus last elemnt montant
    const getDiffData = (data, search) => {
        let fieldParam1 = getFields(search.param1)
        let fieldParam2 = getFields(search.param2)
        let result = []
        let obj = {}
        for (const [index, element] of data.entries()) {
            if(index == 0){
                obj = {date:element.date, value: element[fieldParam1] != 0 ? element[fieldParam1] : element[fieldParam2]}
                result.push(obj)
            }else{
                obj = {date : element.date, value : element[fieldParam1] != 0 ? element[fieldParam1] + result[index - 1].value : result[index - 1].value - element[fieldParam2] }
                result.push(obj)
            }
        }
        setFiltreDiffData(result)
    }

    const getFields = (searchParam) => {
        switch (searchParam) {
            case 0:
                return "expCharges"
            case 1:
                return "actDepenses"
            case 2:
                return "expRevenus"
            case 3:
                return "actRevenus"
        }
    }

    const profitCenterChanged = () => {}

    const getDevise = (devise) => {
        return currency.find((currency) => currency.id === devise).code;
      };
      const getStatus = (status) => {
        switch (status) {
          case 0:
            return "à payer".toUpperCase();
          case 1:
            return "En cours".toUpperCase();
          default:
            return "payé".toUpperCase();
        }
    };

    const formatteDate = (date) => {
        return moment(date).format("YYYY-MM-DD");
    };

    const compareDates = (a, b) => {
        const dateA = moment(a, "YYYY-MM-DD");
        const dateB = moment(b, "YYYY-MM-DD");
        return dateA - dateB;
    }

    const isBetweenTwoDates = (date, intervale) => {
        return moment(date).isBetween(moment(intervale[0]), moment(intervale[1]), null, '[]');
    }

    const groupByMonths = (data) => {
        const summedData = {};

        for (const obj of data) {
          const dateParts = obj.date.split('-');
          const yearMonth = `${dateParts[0]}-${dateParts[1]}`;
          
          if (!summedData[yearMonth]) {
            summedData[yearMonth] = {
              date: yearMonth,
              expCharges: 0,
              actDepenses: 0,
              expRevenus: 0,
              actRevenus: 0
            };
          }
      
          summedData[yearMonth].expCharges += obj.expCharges;
          summedData[yearMonth].actDepenses += obj.actDepenses;
          summedData[yearMonth].expRevenus += obj.expRevenus;
          summedData[yearMonth].actRevenus += obj.actRevenus;
        }
    
        return Object.values(summedData);
    }

  return (
    <Spin spinning={isLoading} size="large">
            {Can("10_1") || !!currentUser.type ? (
                <Card
                    headStyle={{ backgroundColor: "#dee0e9" }}
                    title="Analyse"
                >
                    <Collapse
                        defaultActiveKey={["1"]}
                        expandIconPosition={"left"}
                    >
                        <Collapse.Panel
                            header="Paramètres de recherche "
                            key="1"
                        >
                            <Form
                                layout="vertical"
                                form={searchForm}
                                onFinish={searchData}
                            >
                                <Row>
                                    <Col span={12} className="px-2">
                                        <Form.Item
                                            label="Période"
                                            name="dateFrom"
                                        >
                                            <DatePicker.RangePicker
                                                style={{ width: "100%" }}
                                                size="large"
                                                placeholder={["De", "À"]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} className="px-2">
                                        <Form.Item
                                            label="Centre de profit"
                                            name="profitCenter"
                                        >
                                            <Select
                                                size="large"
                                                onChange={() =>
                                                    profitCenterChanged()
                                                }
                                            >
                                                <Select.Option value={-1}>
                                                    Tous les centres de profit
                                                </Select.Option>
                                                {profitCenters &&
                                                    profitCenters.map((pc) => (
                                                        <Select.Option
                                                            key={pc.id}
                                                            value={pc.id}
                                                        >
                                                            {pc.nom}
                                                        </Select.Option>
                                                    ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={10} className="px-2">
                                        <Form.Item
                                                label="param 1"
                                                name="param1"
                                            >
                                                <Select
                                                    size="large"
                                                >
                                                    <Select.Option value={0}>
                                                        Charges (Expected)
                                                    </Select.Option>
                                                    <Select.Option value={1}>
                                                        Depense (Actuel)
                                                    </Select.Option>
                                                    <Select.Option value={2}>
                                                        Revenu (Expected)
                                                    </Select.Option>
                                                    <Select.Option value={3}>
                                                        Revenu (Actuel)
                                                    </Select.Option>

                                                </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={2} className="px-2">
                                        VS
                                    </Col>
                                    <Col span={10} className="px-2">
                                        <Form.Item
                                                label="param 2"
                                                name="param2"
                                            >
                                                <Select
                                                    size="large"
                                                >
                                                    <Select.Option value={0}>
                                                        Charges (Expected)
                                                    </Select.Option>
                                                    <Select.Option value={1}>
                                                        Depense (Actuel)
                                                    </Select.Option>
                                                    <Select.Option value={2}>
                                                        Revenu (Expected)
                                                    </Select.Option>
                                                    <Select.Option value={3}>
                                                        Revenu (Actuel)
                                                    </Select.Option>

                                                </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                <Col span={12} className="px-2">
                                        <Form.Item
                                            // label="Voulez-vous appliquer un recherche par mois ?"
                                            name="isByMonth"
                                        >
                                            {/* <Radio.Group className="px-2">
                                                <Radio
                                                    value={false}
                                                >
                                                    Non
                                                </Radio>
                                                <Radio
                                                    value={true}
                                                >
                                                    Oui
                                                </Radio>
                                            </Radio.Group> */}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="d-flex flex-row-reverse my-2">
                                    <Button
                                        type="primary"
                                        className="mx-2"
                                        onClick={searchForm.submit}
                                    >
                                        Recherche
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            searchForm.resetFields();
                                        }}
                                    >
                                        Annuler
                                    </Button>
                                </Row>
                            </Form>
                        </Collapse.Panel>
                    </Collapse>

                    <div className="py-4">
                    <h5 className="text-center">param 1 vs param 2 : </h5>
                        <LineChart
                            width={1000}
                            height={400}
                            data={filtreData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="expCharges" stroke="#8884d8" activeDot={{ r: 8 }} />
                            <Line type="monotone" dataKey="actDepenses" stroke="#4e90c2" activeDot={{ r: 8 }} />
                            <Line type="monotone" dataKey="expRevenus" stroke="#75b323" activeDot={{ r: 8 }} />
                            <Line type="monotone" dataKey="actRevenus" stroke="#23b3a9" activeDot={{ r: 8 }} />
                        </LineChart>
                    </div>

                    <div className="py-4">
                    <h5 className="text-center">Courbe de Différences ( param1 - param 2 ) : </h5>
                        <LineChart
                            width={1000}
                            height={400}
                            data={filtreDiffData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
                        </LineChart>
                    </div>

                    <Row>
                        <Col span={12}>
                            <h5 className="text-center">Total : </h5>
                            <PieChart width={400} height={400} className="mt-0 pt-0">
                                <Pie
                                    className="pt-0 mt-0"
                                    dataKey="value"
                                    startAngle={0}
                                    endAngle={380}
                                    data={totalData}
                                    outerRadius={100}
                                    fill="#8884d8"
                                    label
                                >
                                {
                                    data.map((entry, index) => <Cell fill={colors[index]}/>)
                                }
                                </Pie>
                            <Legend />
                            <Tooltip />
                            </PieChart>
                        </Col>
                    </Row>
                    
                </Card>
            ) : (
                <NotAuthorized />
            )}
            
    </Spin>
  )
}

export default Analyse