import {
  CREATE_VEHICULE,
  UPDATE_VEHICULE,
  DELETE_VEHICULE,
  RETRIEVE_VEHICULES,
  DELETE_ALL_VEHICULES,
} from "../actions/type";
const initialState = [];

function vehiculesReducer(vehicules = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_VEHICULE:
      return [...vehicules, payload];

    case RETRIEVE_VEHICULES:
      return payload;

    case UPDATE_VEHICULE:
      return vehicules.map((vehicule) => {
        if (vehicule.id === payload.id) {
          return {
            ...vehicule,
            ...payload,
          };
        } else {
          return vehicules;
        }
      });

    case DELETE_VEHICULE:
      return vehicules.filter(({ id }) => id !== payload.id);

    case DELETE_ALL_VEHICULES:
      return [];

    default:
      return vehicules;
  }
}

export default vehiculesReducer;
