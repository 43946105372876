// LOGIN
export const LOGIN_FETCHING = "LOGIN_FETCHING";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

// MESSAGES
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

// USER
export const CREATE_USERS = "CREATE_USERS";
export const RETRIEVE_USERS = "RETRIEVE_USERS";
export const UPDATE_USERS = "UPDATE_USERS";
export const DELETE_USERS = "DELETE_USERS";
export const DELETE_ALL_USERS = "DELETE_ALL_USERS";
//Vehicule
export const CREATE_VEHICULE = "ADD_VEHICULE";
export const UPDATE_VEHICULE = "UPDATE_VEHICULE";
export const DELETE_VEHICULE = "DELETE_VEHICULE";
export const RETRIEVE_VEHICULES = "RETRIEVE_VEHICULES";
export const DELETE_ALL_VEHICULES = "DELETE_ALL_VEHICULES";
export const RETRIEVE_ENTREPEISES = "RETRIEVE_ENTREPEISES";

//Roles
export const CREATE_ROLES = "CREATE_ROLES";
export const RETRIEVE_ROLES = "RETRIEVE_ROLES";
export const UPDATE_ROLES = "UPDATE_ROLES";
export const DELETE_ROLES = "DELETE_ROLES";

//Module
export const RETRIEVE_MODULES = "RETRIEVE_MODULES";
//Permission
export const RETRIEVE_PERMISSION = "RETRIEVE_PERMISSION";
//Depense
export const CREATE_DEPENSE = "CREATE_DEPENSE";
export const RETRIEVE_DEPENSE = "RETRIEVE_DEPENSE";
export const RETRIEVE_DEPENSERECCURENT = "RETRIEVE_DEPENSERECCURENT";
export const DELETE_DEPENSE = "DELETE_DEPENSE";
//Depense type
export const CREATE_DEPENSETYPE = "CREATE_DEPENSETYPE";
export const RETRIEVE_DEPENSETYPE = "RETRIEVE_DEPENSETYPE";

// Frequence
export const CREATE_FREQUENCE = "CREATE_FREQUENCE";
export const RETRIEVE_FREQUENCE = "RETRIEVE_FREQUENCE";

// Fournisseur
export const CREATE_FOURNISSEUR = "CREATE_FOURNISSEUR";
export const RETRIEVE_FOURNISSEUR = "RETRIEVE_FOURNISSEUR";

//Echeance
export const RETRIEVE_ECHEANCE = "RETRIEVE_ECHEANCE";

// Categories

export const CREATE_CATEGORIE = "CREATE_CATEGORIE";
export const RETRIEVE_CATEGORIES = "RETRIEVE_CATEGORIES";
export const UPDATE_CATEGORIE = "UPDATE_CATEGORIE";

//Profit center

export const CREATE_PROFIT_CENTER = "CREATE_PROFIT_CENTER";
export const RETRIEVE_PROFIT_CENTERS = "RETRIEVE_PROFIT_CENTERS";
export const UPDATE_PROFIT_CENTERS = "UPDATE_PROFIT_CENTERS";
