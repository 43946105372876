import React from "react";
import exportMessages from "../../UIcomponent/messages/messages";
import { Result, Button } from "antd";
import { useHistory } from "react-router";
const NotAuthorized = () => {
  const history = useHistory();
  const navigate = () => {
    history.push("/");
  };
  // exportMessages.errorMessage("rr");

  return (
    <>
      <Result
        status="error"
        subTitle="Désolé ,vous n'avez l'autorisation d'accées pour cette page !."
        extra={
          <Button
            onClick={() => {
              navigate();
            }}
            type="primary"
          >
            Aller au page d'accueil
          </Button>
        }
      />
    </>
  );
};
export default NotAuthorized;
