import { Card, Col, Row } from "antd";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import EcheanceForm from "./EcheanceForm";

const EcheancePage = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    return () => {};
  }, []);

  const goback = () => {
    history.push("/Depensev2");
  };

  return (
    <Card
      headStyle={{ backgroundColor: "#dee0e9" }}
      title={
        <>
          <Row>
            <Col span={10}>
              <span onClick={() => goback()} className="goback">
                Retour
              </span>
            </Col>
            <Col span={14}>Validation des écheances</Col>
          </Row>
        </>
      }
    >
      <EcheanceForm depenseId={location.state.depense_id} echeances={location.state.echeances} total={location.state.total}></EcheanceForm>
    </Card>
  );
};
export default EcheancePage;
