import { Card, Col, Row, Space } from "antd";

const CardUi = ({ title, data }) => {
  return (
    <Card>
      <Row>
        <Col span={12}>
          {" "}
          <Row>
            <b>Nom de centre de profit : </b>
          </Row>
          <Space />
          <br />
          <Row>
            <b>Description de centre de profit : </b>
          </Row>
          <Space />
          <br />
          <Row>
            <b>État de centre de profit : </b>
          </Row>
          <Space />
          <br />
        </Col>
        <Col>
          <Row> {data.profitCenter_nom}</Row> <Space />
          <br />
          <Row>{data.profitCenter_description}</Row> <Space />
          <br />
          <Row>
            {data.profitCenter_etat === true ? "Active" : "Non active"}
          </Row>{" "}
          <Space />
          <br />
        </Col>
      </Row>
    </Card>
  );
};
export default CardUi;
