const currency = [
  { id: 0, code: "DZD" },
  { id: 1, code: "EGP" },
  { id: 2, code: "EUR" },
  { id: 3, code: "LBP" },
  { id: 4, code: "LYD" },
  { id: 5, code: "OMR" },
  { id: 6, code: "QAR" },
  { id: 7, code: "SAR" },
  { id: 8, code: "CHE" },
  { id: 9, code: "TND" },
  { id: 10, code: "TRY" },
  { id: 11, code: "AED" },
  { id: 12, code: "GBP" },
  { id: 13, code: "USD" },
];

export default currency;
