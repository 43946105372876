import authHeader from "./auth-header";
import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;
let   EntrepriseId = (localStorage.getItem('entreprise'))

const addFournisseur = (fournisseur) => {
  return axios.post(API_URL + "fournisseur/"+EntrepriseId, fournisseur, {
    headers: authHeader(),
  });
};

const getAllFournisseurs = () => {
  EntrepriseId = (localStorage.getItem('entreprise'))

  return axios.get(API_URL + "fournisseursActive/" + EntrepriseId, { headers: authHeader() });
};
const deleteFournisseur = (id) => {
  return axios.delete(API_URL + "fournisseur/" + id, { headers: authHeader() });
};
const updateFournisseur = (id, fournisseur) => {
  return axios.put(API_URL + "fournisseur/" + id, fournisseur, {
    headers: authHeader(),
  });
};

const exportMethodes = {
  addFournisseur,
  getAllFournisseurs,
  deleteFournisseur,
  updateFournisseur,
};
export default exportMethodes;
