import { InboxOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Row, Select, Spin, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { environment } from "../../env/config.env";
import BilanService from "../../services/bilan.service";
import ExerciceService from "../../services/exercice.service";
import exportMessages from "../../UIcomponent/messages/messages";

const { Dragger } = Upload;

const API_URL = environment.api;

const AddBalance = () => {
    const history = useHistory();

    const [balanceForm] = Form.useForm();
    const [isLoading, setLoading] = useState(false);
    const [selectedFiles, setselectedFiles] = useState([]);
    const [filesList, setFilesList] = useState([]);
    const [exercices, setExercices] = useState([]);

    const list = [];

    const props = {
        multiple: false,
        onRemove(file) {
            const index = list.indexOf(file);
            list.splice(index, 1);
            setFilesList(list);
        },
        beforeUpload(file) {
            if( file.name.includes(".xlsx")){
                list.push(file);
                setFilesList(list);
            }else{
                balanceForm.resetFields(["file"]);
                exportMessages.errorMessage("Seulement les fichiers format xlsx sont accepté ")

            }
            return false;
        },
    };

    useEffect(() => {
        getExercices();
        return () => {
            clearStates();
        };
    }, []);

    const clearStates = () => {
        balanceForm.resetFields();
    };

    const fileChange = async (values) => {};

    const addFile = async (values) => {
        setLoading(true);
        const formData = new FormData();
        if (filesList.length) {
            for (const element of filesList) formData.append("file", element);
            const resp = await BilanService.addBalance( formData, values.exercice );
            if (resp.status === 200){
                exportMessages.addSuccess("Balance Ajouter avec success");
                clearStates();
                navigate("/balance")
            } else exportMessages.errorMessage();
        }
        setFilesList((prevState) => [...filesList]);
        setLoading(false);
    };

    const navigate = (location) => {
        history.push(location);
    };


    const getExercices = async () => {
        setLoading(true);
        const response = await ExerciceService.getExercices();
        if (response.status === 200 || 204) formatteExercice(response.data);
        else exportMessages.errorMessage();
        setLoading(false);
    };
    const formatteExercice = (data) => {
        if( data.length ){
            const exercices = data.filter( exercice => exercice.etat === 0 );
            setExercices(exercices)
        }
    }

    const goback = () => {
        history.push("/balance");
    }

    return (
        <Spin spinning={isLoading} size="large">
            <Card headStyle={{ backgroundColor: "#dee0e9" }} 
                title={      <>
                    <Row> 
                      <Col span={10}><span onClick={() => goback()} className="goback"> Retour </span></Col>
                      <Col span={14}> Ajouter une Balance </Col>
                    </Row> 
                  </>
                }>
                <Form layout="vertical" form={balanceForm} onFinish={addFile}>
                    <Form.Item
                        label="Année de l'exercice "
                        name="exercice"
                        rules={[
                            {
                                required: true,
                                message: "Champ obligatoire !",
                            },
                        ]}
                    >
                        <Select size="large">
                            {exercices &&
                                exercices.map((exercice) => (
                                    <Select.Option
                                        value={exercice.exercice_id}
                                    >
                                        {exercice.annee}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="file"
                        label="Balance de l'exercice"
                        rules={[
                            {
                                required: true,
                                message: "Champ obligatoire !",
                            },
                        ]}
                    >
                        <Dragger
                            {...props}
                            onChange={fileChange}
                            multiple={true}
                            defaultFileList={[]}
                            style={{ maxHeight: "150px" }}
                        >
                            <p className="ant-Dragger-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-Dragger-text">
                                Déposer Le fichier Excel contenant la balance ici
                            </p>
                            <p className="ant-Dragger-hint">
                                Seulement les fichiers excel sont permis
                            </p>
                        </Dragger>
                    </Form.Item>
                    <Row className="d-flex flex-row-reverse my-3">
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="mx-2 px-4"
                            disabled={filesList.length === 0}
                        >
                            Ajouter
                        </Button>
                        <Button
                            type=""
                            className="mx-2"
                            onClick={() => clearStates()}
                        >
                            Annuler
                        </Button>
                    </Row>
                </Form>
            </Card>
        </Spin>
    );
};
export default AddBalance;
