import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    LoadingOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import {
    Avatar,
    Badge,
    Button,
    Card,
    Col,
    Form,
    Input,
    Modal,
    Popover,
    Row,
    Select,
    Spin,
    Switch,
    Tabs,
    Tag,
    Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getAllCategories } from "../../actions/categorie";
import categorieService from "../../services/categorie.service";
import profitCenterservice from "../../services/profitCenter.service";
import CardUi from "../../UIcomponent/Card/Card";
import messagealert from "../../UIcomponent/messages/messages";
import AppTable from "../../UIcomponent/Table/AppTable";
import Categorie from "../Categories/Categorie";
import "./profitCenter.css";
import Can from "../../Can/Can";
import NotAuthorized from "../NotAuthorizedPage/NotAuthorizedPage";
import categorieTypeService from "../../services/categorie-type.service";

const ProfitCenter = ({
    proCent,
    cat_id,
    showMoreDetailsCat,
    getAllCategorie,
}) => {
    const { TabPane } = Tabs;
    const { TextArea } = Input;
    const { user: currentUser } = useSelector((state) => state.auth);

    const { Option } = Select;
    const location = useLocation();
    const locationPa = location.pathname !== "/categorie";
    const [niveau, setNiveau] = useState();
    const [categorieTypes, setCategorieTypes] = useState();

    const [profitCenter, setProfitCenter] = useState();
    const [categories, setCategories] = useState();
    const [addCategtoProf, setaddCategtoProf] = useState(false);
    const [mode, setMode] = useState("");
    const [loading, setLoading] = useState();
    const [id, setId] = useState();
    const [moyenForm] = Form.useForm();
    const [selectedValues, setSelectedValues] = useState();
    const [addCategoires, setAddCategoires] = useState(false);
    const [etatProf, setEtatProf] = useState(true);
    const [visible, setVisible] = useState(false);
    const [visibleModalDelete, setVisibleModalDelete] = useState(false);
    const [upadtaAdd, setUpadtaAdd] = useState(false);
    const [upadtaAddButton, setUpadtaAddButton] = useState(false);

    const [recordData, setrecordData] = useState(false);
    const dispatch = useDispatch();
    const [profitCe, setProfCes] = useState();
    const [provinceData, setprovinceData] = useState();

    const profitCenters = proCent ? proCent : profitCe;
    const getAllCategoris = async () => {
        const cats = await categorieService.getCategories();

        return cats.data;
    };
    const getCategorieTypes = async () => {
        const types = await (
            await categorieTypeService.getTypeCategories()
        ).data.map((el) => (
            <Option value={el.category_type_id} label={el.categorie_type_nom}>
                {el.categorie_type_nom}
            </Option>
        ));
        setCategorieTypes(types);
    };
    const getAllProfitCenter = async () => {
        const profCens = await profitCenterservice.getProfitCenters();
        const profe = profCens.data.reverse();
        setProfCes(profe);
        return profe;
    };
    const getOneProfitCenter = async (id) => {
        let oneProfitCenter = await profitCenterservice.getProfitCenter(id);
        setProfitCenter(oneProfitCenter.data);
        return oneProfitCenter.data;
    };

    const handleProvinceChangeEtat = (value) => {
        setEtatProf(value);
        setProfitCenter({ ...profitCenter, profitCenter_etat: value });
    };

    const showModalUpdate = (record) => {
        setUpadtaAddButton(true);
        record && record.categories.length !== 0 && setAddCategoires(true);
        getAllCategoris().then((ellm) => {
            const provinceDat = ellm.map((el) => (
                <Option
                    label={el.categorie_nom}
                    value={parseInt(el.category_id)}
                >
                    {el.categorie_nom}
                </Option>
            ));
            setprovinceData(provinceDat.reverse());
        });
        if (record !== undefined) {
            setEtatProf(record.profitCenter_etat);

            setrecordData(record);
            record && setId(record.profitCenter_id);
            moyenForm.setFieldsValue(record);
            setSelectedValues(record.categories.map((el) => el.category_id));
        } else {
            setEtatProf(true);
        }
        setUpadtaAdd(true);
    };
    function handleChange(value) {
        let valueParsed = value.map((el) => {
            return parseInt(el);
        });
        setSelectedValues(value);
        setProfitCenter({ ...profitCenter, category_id: valueParsed });
    }

    const showMoreDetails = async (id, record) => {
        moyenForm.setFieldsValue(record);
        setId(id);
        let res = await getOneProfitCenter(id);
        const categories = res.categories.map((el) => {
            let type = el.categorie_type.categorie_type_nom;
            el.categorie_type_nom = type;
            return el;
        });
        setCategories(categories);
        setVisible(true);
    };

    const onUpdateProfitCenter = async (profCter) => {
        setUpadtaAddButton(false);
        if (!locationPa) {
            profCter = { ...profCter, category_id: [cat_id] };
        }
        profCter = { ...profCter, category_id: selectedValues };
        profCter = { ...profCter, profitCenter_etat: etatProf };
        const msg =
            mode === "edit"
                ? await profitCenterservice.updateProfitcenter(id, profCter)
                : await profitCenterservice.addProfitCenter(profCter);

        if (msg.status === 200 || 201) {
            await getAllProfitCenter();

            if (!locationPa) {
                await getAllCategorie();

                showMoreDetailsCat(cat_id);
            }
            if (mode === "edit") {
                messagealert.updateSuccess(
                    "centre de profit a été bien mis a jour "
                );
            } else {
                messagealert.addSuccess("centre de profit a été ajouté");
            }
        } else {
            messagealert.errorMessage();
        }
        setProfitCenter();
        setSelectedValues();
        moyenForm.resetFields();
        setUpadtaAdd(false);
        setLoading(false);
        setAddCategoires(false);
        setEtatProf(false);
        return true;
    };
    const capitilze = (str) => {
        let newStr = str
            .split(" ")
            .map((el) => {
                el.toLocaleLowerCase();
                let le = el.charAt(0).toLocaleUpperCase();
                let x = le + el.substr(1, el.length - 1);

                return x;
            })
            .join(" ");
        return newStr;
    };
    const ondeleteProfitCenter = async (id) => {
        const msg = await profitCenterservice.deleteProfitCenter(id);
        if (msg.status === 200) {
            await getAllProfitCenter();
            messagealert.updateSuccess(
                "le centre de profit a été supprimé avec succés"
            );
            // dispatch(getAllProfitCenters);
        } else {
            messagealert.errorMessage();
        }
    };
    const onChange = (checked) => {
        setAddCategoires(checked);
    };
    useEffect(() => {
        getAllProfitCenter();
        getCategorieTypes();
    }, []);
    const handleCancel = () => {
        setAddCategoires(false);
        setEtatProf(false);
        setMode();
        setVisible(false);
        setVisibleModalDelete(false);
        setUpadtaAdd(false);
        setSelectedValues();
        setrecordData();
        moyenForm.resetFields();
    };
    const niveaux = [0, 1, 2, 3, 4, 5];

    const getCategorieNames = (categories) => {
        const cats = [];
        for (const categorie of categories) {
            if(categorie.etat)
                cats.push(categorie)
        }
        if(cats.length > 0 ){
            for (const categorie of cats) {
                return categorie.categorie_nom;
            }
        }else return "Aucune Catégorie"
    }
    const getCategorieNumber = (categories) => {
        const cats = [];
        for (const categorie of categories) {
            if(categorie.etat)
                cats.push(categorie)
        }
        return cats.length;
    }
    const getColor = (categories) => {
        const cats = []
        for (const categorie of categories) {
            if(categorie.etat)
                cats.push(categorie)
        }
        if(cats.length > 0 ) return "red";
        else return "#ffbf00"
    }

    const columns = [
        {
            title: () => (
                <Tooltip title="Nom de centtre de profit ">Nom</Tooltip>
            ),
            dataIndex: "profitCenter_nom",
            sorter: (a, b) =>
                a.profitCenter_nom.localeCompare(b.profitCenter_nom),
            fixed: true,
        },
        {
            title: () => (
                <Tooltip title="Description  de centre de profit">
                    Description{" "}
                </Tooltip>
            ),
            dataIndex: "profitCenter_description",
            sorter: (a, b) =>
                a.profitCenter_description.localeCompare(
                    b.profitCenter_description
                ),
        },
        {
            title: () => (
                <Tooltip title="État  de centre de profit">État </Tooltip>
            ),
            dataIndex: "profitCenter_etat",
            sorter: (a, b) => a.profitCenter_etat - b.profitCenter_etat,
            width: "50px",

            render: (profitCenter_etat) => (
                <span>
                    {[profitCenter_etat].map((tag) => {
                        let color = tag === true ? "green" : "red";
                        let title = tag === true ? "Active " : "Inactive";
                        return (
                            <Tag color={color} key={title}>
                                {title}
                            </Tag>
                        );
                    })}
                </span>
            ),
        },

        locationPa
            ? {
                  title: () => (
                      <Tooltip title="Catégories  de centre de profit">
                          Catégories{" "}
                      </Tooltip>
                  ),
                  dataIndex: "categories",
                  sorter: (a, b) => a.categories.length - b.categories.length,
                  fixed: true,
                  width: "50px",

                  key: "categoriesNumber",
                  render: (categories) => (
                      <>
                          <Popover
                              content={ getCategorieNames(categories)}
                              title={<h6>Liste des catégories</h6>}
                          >
                              <span className="avatar-item">
                                  <Badge count={getCategorieNumber(categories)}>
                                      <Avatar
                                            style={{
                                                backgroundColor: getColor(categories),
                                                verticalAlign: "middle",
                                            }}
                                            size="middle"
                                        >
                                        C
                                      </Avatar>
                                  </Badge>
                              </span>
                          </Popover>
                      </>
                  ),
              }
            : {},

        locationPa
            ? {
                  title: "Actions",
                  key: "action",
                  width: "170px",

                  render: (text, record) => (
                      <div>
                          {locationPa && (Can("3_3") || !!currentUser.type) && (
                              <Tooltip title="éditer centre de profit">
                                  <Button
                                      className="mx-1"
                                      type="dashed"
                                      shape="circle"
                                      onClick={() => {
                                          setMode("edit");

                                          showModalUpdate(record, id);
                                          setVisibleModalDelete(false);
                                      }}
                                      icon={<EditOutlined />}
                                  />
                              </Tooltip>
                          )}
                          {locationPa && (Can("3_5") || !!currentUser.type) && (
                              <Tooltip title="voir plus détails">
                                  <Button
                                      className="mx-1"
                                      type="dashed"
                                      shape="circle"
                                      onClick={() =>
                                          showMoreDetails(
                                              record.profitCenter_id,
                                              record
                                          )
                                      }
                                      icon={<EyeOutlined />}
                                  />
                              </Tooltip>
                              
                          )}
                           <Tooltip title="supprimer cette catégorie">
                                  <Button
                                      className="mx-1"
                                      type="dashed"
                                      shape="circle"
                                      onClick={() => {
                                          Modal.confirm({
                                              type: "info",
                                              title: "Attention",
                                              icon: (
                                                  <ExclamationCircleOutlined />
                                              ),
                                              content:
                                                  "Etes vous sur de vouloir supprimer ce Centre de profit ? ",
                                              onOk: () => {
                                                  ondeleteProfitCenter(
                                                      record.profitCenter_id
                                                  );
                                                  setVisibleModalDelete(false);
                                              },
                                              okText: "Oui",
                                              cancelText: "Non",
                                          });
                                      }}
                                      icon={<DeleteOutlined />}
                                  />
                              </Tooltip>
                      </div>
                  ),
              }
            : {},
    ];
    const showModalAddCatToProf = () => {
        let niveaus = niveaux.map((el) => (
            <Option label={el} value={parseInt(el)}>
                {el}
            </Option>
        ));
        setNiveau(niveaus);
        setaddCategtoProf(true);
    };
    const form = (
        <Form
            aria-disabled="true"
            layout="vertical"
            form={moyenForm}
            onFinish={onUpdateProfitCenter}
        >
            <div>
                <Row>
                    {" "}
                    <Col span={24} className="px-2">
                        <Form.Item
                            label="Nom de centre de profit"
                            name="profitCenter_nom"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Champ obligatoire avec maximum de caractère 255 !",
                                    max: 254,
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                type="text"
                                maxLength="255"
                                disabled={visible}
                            />
                        </Form.Item>
                    </Col>
                    {/* <Col span={10} offset={2} className="px-5">
            <Form.Item style={{ marginTop: "-20px" }} name="profitCenter_etat">
              {"État :     "}
              <Switch
                className={etatProf ? "notemptyPop " : "popoverHeaderEmpty"}
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                checked={etatProf}
                onChange={handleProvinceChangeEtat}
                size="default"
                style={{ width: "80px" }}
              />
            </Form.Item>
          </Col> */}
                </Row>
                <Row>
                    <Col span={24} className="px-2">
                        <Form.Item
                            label="Description de centre de profit"
                            name="profitCenter_description"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Champ obligatoire avec maximum de caractère 255 !",
                                    max: 254,
                                },
                            ]}
                        >
                            <TextArea
                                rows={4}
                                size="large"
                                type="text"
                                maxLength="255"
                                disabled={visible}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Col span={12} className="px-2">
                    {/* <Form.Item
            label="État de centre de profit"
            name="profitCenter_etat"
            rules={[{ required: true, message: "Champ obligatoire !" }]}
          >
            <></>
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              // defaultChecked={etatProf}
              checked={etatProf}
              onChange={handleProvinceChangeEtat}
              size="default"
              style={{ width: "80px" }}
            />
           
          </Form.Item> */}
                </Col>
            </div>

            <br />
            {!visible && (
                <Form.Item label="Ajouter catégorie" className="px-2">
                    <Switch
                        size="default"
                        checkedChildren="Avec"
                        unCheckedChildren="Sans"
                        defaultChecked={addCategoires}
                        onChange={onChange}
                        style={{ width: "80px" }}
                        disabled={
                            recordData && recordData.categories.length !== 0
                        }
                    />
                </Form.Item>
            )}
            {locationPa && !visible && addCategoires && (
                <Form.Item
                    className="px-2"
                    label="Catégories"
                    name="category_id"
                >
                    <Row>
                        <Col span={22}>
                            <Select
                                size="large"
                                value={selectedValues}
                                mode="multiple"
                                allowClear
                                placeholder="Selectioner une catégorie"
                                onChange={handleChange}
                                optionLabelProp="label"
                            >
                                {provinceData}
                            </Select>
                        </Col>
                        {true && (
                            <Col span={2}>
                                <Tooltip title="Ajouter une nouvelle catégorie">
                                    <Button
                                        size="large"
                                        icon={<PlusOutlined />}
                                        onClick={showModalAddCatToProf}
                                    ></Button>
                                </Tooltip>
                            </Col>
                        )}
                    </Row>
                </Form.Item>
            )}
        </Form>
    );

    const [moyenCatForm] = Form.useForm();
    const closeModaladdCategtoProf = () => {
        setaddCategtoProf(false);
        moyenCatForm.resetFields();
    };
    const addCate = async (value) => {
        let value1 =
            mode === "edit"
                ? { ...value, profitCenter_id: [recordData.profitCenter_id] }
                : { ...value };
        let msg = await categorieService.addCategorie(value1);

        if (msg.status === 200 || 201) {
            messagealert.addSuccess("catégorie a été bien ajoutée");
            // let cats = msg.data.categories.map((el) => el.category_id);
            // setSelectedValues(cats);
            if (mode === "edit") {
                let profC = await getOneProfitCenter(
                    recordData.profitCenter_id
                );

                setrecordData(profC);
                let catsIds = profC.categories
                    .map((el) => el.category_id)
                    .filter((el) => !selectedValues.includes(el));
                // catsIds = [...catsIds.filter((el) => selectedValues.includes(el))];
                setSelectedValues([...selectedValues, ...catsIds]);
            }
            await getAllProfitCenter();
            getAllCategoris().then((ellm) => {
                const provinceDat = ellm
                    .map((el) => (
                        <Option
                            label={el.categorie_nom}
                            value={parseInt(el.category_id)}
                        >
                            {el.categorie_nom}
                        </Option>
                    ))
                    .reverse();
                setprovinceData(provinceDat);
            });
            setaddCategtoProf(false);
        } else {
            messagealert.errorMessage();
        }
        setaddCategtoProf(false);
        moyenCatForm.resetFields();
    };
    const catForm = (
        <Form layout="vertical" form={moyenCatForm} onFinish={addCate}>
            <Row>
                <Col span={12} className="px-2">
                    <Form.Item
                        label="Nom De Catégorie"
                        name="categorie_nom"
                        rules={[
                            { required: true, message: "Champ obligatoire !" },
                        ]}
                    >
                        <Input size="large" type="text" required />
                    </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                    <Form.Item
                        label="Description De Catégorie"
                        name="categorie_description"
                        rules={[
                            {
                                required: true,
                                message:
                                    "Champ obligatoire avec maximum de caractère 255 !",
                                max: 254,
                            },
                        ]}
                    >
                        <Input
                            size="large"
                            type="text"
                            required
                            maxLength="255"
                            disabled={visible}
                        />
                    </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                    <Form.Item
                        label="Type De Catégorie"
                        name="categorie_type"
                        rules={[
                            { required: true, message: "Champ obligatoire !" },
                        ]}
                    >
                        <Select size="large" required disabled={visible}>
                            {categorieTypes}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                    <Form.Item
                        label="Niveau De catégorie"
                        name="categorie_niveau"
                        rules={[
                            {
                                required: true,
                                message: "Champ accepte que des chiffres",
                            },
                        ]}
                    >
                        <Select size="large">{niveau}</Select>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (
        <Spin spinning={!profitCenters} indicator={antIcon} size="large">
            <>
                {Can("3_1") || !!currentUser.type ? (
                    <Card
                        headStyle={{ backgroundColor: "#dee0e9" }}
                        title="Liste des centres de profit"
                        loading={!profitCenters}
                        extra={
                            locationPa &&
                            (Can("3_2") || !!currentUser.type) && (
                                <Button
                                    className="mx-2"
                                    shape="round"
                                    type="primary"
                                    onClick={() => {
                                        setMode("add");
                                        setVisibleModalDelete(true);
                                        dispatch(getAllCategories());
                                        showModalUpdate();
                                    }}
                                >
                                    Ajouter un centre de profit
                                </Button>
                            )
                        }
                    >
                        <AppTable data={profitCenters} columns={columns} />
                    </Card>
                ) : (
                    <NotAuthorized />
                )}

                <Modal
                    footer={null}
                    visible={visible}
                    onCancel={handleCancel}
                    width="80%"
                    getContainer={false}
                >
                    {profitCenter && profitCenter.profitCenter_nom}
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Informations générales" key="1">
                            <CardUi data={profitCenter} />{" "}
                        </TabPane>
                        <TabPane tab="Catégories" key="2">
                            <Card headStyle={{ backgroundColor: "#dee0e9" }}>
                                {" "}
                                <Categorie
                                    showModalAddProf={showModalUpdate}
                                    showMoreDetailsProf={showMoreDetails}
                                    getAllProfitCenter={getAllProfitCenter}
                                    cats={categories}
                                    prof_id={id}
                                />{" "}
                            </Card>
                        </TabPane>
                    </Tabs>
                </Modal>
                {
                    <Modal
                        title={
                            <>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        {mode === "edit" && recordData
                                            ? "Éditer   " +
                                              recordData.profitCenter_nom
                                            : "Ajouter un centre de profit"}
                                    </div>
                                    <div className="mx-5">
                                        <Form.Item name="profitCenter_etat">
                                            <Switch
                                                className={
                                                    etatProf
                                                        ? "notemptyPop "
                                                        : "popoverHeaderEmpty"
                                                }
                                                checkedChildren="Active"
                                                unCheckedChildren="Inactive"
                                                checked={etatProf}
                                                onChange={
                                                    handleProvinceChangeEtat
                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </>
                        }
                        mode={mode}
                        visible={upadtaAdd}
                        onCancel={handleCancel}
                        width="40%"
                        getContainer={false}
                        onOk={moyenForm.submit}
                        okText="Enregistrer"
                        cancelText="Annuler"
                        okButtonProps={{ disabled: !upadtaAddButton }}
                    >
                        {form}{" "}
                    </Modal>
                }
            </>
            <Modal
                className="py-5"
                title={
                    recordData
                        ? " Ajouter une catégorie" + recordData.profitCenter_nom
                        : "Ajouter une catégorie"
                }
                visible={addCategtoProf}
                onCancel={closeModaladdCategtoProf}
                width="900px"
                getContainer={false}
                onOk={moyenCatForm.submit}
                okText="Enregistrer"
                cancelText="Annuler"
                okButtonProps={{ disabled: !upadtaAddButton }}
            >
                {catForm}{" "}
            </Modal>
        </Spin>
    );
};
export default ProfitCenter;
