import authHeader from "./auth-header";
import axios from "axios";
import { environment } from "../env/config.env";

const API_URL = environment.api;
let   EntrepriseId = (localStorage.getItem('entreprise'))

const addContrat = ( contrat ) => {
  return axios.post(API_URL + "contrat/" + EntrepriseId , contrat ,{ headers: authHeader() });
};

const getContrats = () => {
  EntrepriseId = (localStorage.getItem('entreprise'))
  return axios.get(API_URL + "contartsActive/" + EntrepriseId ,  { headers: authHeader() });
};

const getContrat = ( id ) => {
  return axios.get(API_URL + "contrat/" + id , { headers: authHeader() });
};

const updateContrat = (id , contrat ) => {
  return axios.put(API_URL + "contrat/" + id , contrat, { headers: authHeader() });
};

const deleteContrat = (id) => {
  return axios.delete(API_URL + "contrat/" + id , { headers: authHeader() });
};

const exportMethodes = {
  addContrat,
  getContrats,
  getContrat,
  updateContrat,
  deleteContrat,
};
export default exportMethodes;