import { Button, Col, DatePicker, Form, Input, Row, Spin, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import EcheanceService from "../../services/echeance.service";
import exportMessages from "../../UIcomponent/messages/messages";

const EcheanceForm = ({ depenseId, echeances, total }) => {
  const [echeanceForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [totalEcheance , setTotalEcheance] = useState(0)
  const history = useHistory();

  useEffect(() => {
    calculEcheance()
    return () => {};
  }, [echeances]);



const calculEcheance= ()=>{
  let initialValue = 0
 const echFormValue =  (echeanceForm.getFieldValue("echeances").filter((el)=>el))
  let  totalEcheance= Math.round (echFormValue.reduce((acc , courant)=>acc+(+courant.montant) , initialValue))
  setTotalEcheance(totalEcheance)
  return totalEcheance
}  
  const onFinish = (values) => {
if (calculEcheance() !== +total.split(' ')[0]){
  exportMessages.errorMessage("veuillez  les champs")
  return
}

    setLoading(true);
    if (echeanceForm.isFieldsTouched()) {
      const echeances = JSON.parse(JSON.stringify(values.echeances));
      const echeancesToSend = [];
      for (const element of echeances) {
        if (element !== null)
          if (element.hasOwnProperty("libelle")) echeancesToSend.push(element);
      }
      sendData(echeancesToSend);
    } else goToDashboard();
  };

  const sendData = async (echeances) => {
    const response = await EcheanceService.updatEcheances(echeances);
    if (response.data.result) {
      goToDashboard();
    } else {
      exportMessages.errorMessage("une erreur est surevenue");
      setLoading(false);
    }
  };

  const goToDashboard = () => {
    exportMessages.addSuccess("échéances valider avec success");
    history.push("/Depensev2");
    setLoading(false);
  };

  const checkStatus = (status) =>{
    if( status === "payé" || status === "en cours" ) setIsTouched(true)
    return true ? (status === "payé" || status === "en cours") : false
  }

  const roundValues = () => {
    
    let rest = 0;
    const echeances = JSON.parse(
      JSON.stringify(echeanceForm.getFieldValue("echeances"))
    );
    for (const echeance of echeances) {
      if (echeance !== null)
        if (echeance.hasOwnProperty("libelle")) {
          echeance.montant = Math.round(echeance.montant);
          echeance.date_limite = moment(echeance.date_limite);
          rest += echeance.montant;
        }
    }
    const montantGlobal = Number(total.split(" ")[0]);
    echeances[echeances.length - 1].montant =
      echeances[echeances.length - 1].montant + (montantGlobal - rest);
    echeanceForm.setFieldsValue({ echeances });
    
   
  };
  
  return (
    <Spin spinning={isLoading}>
      <h5>
        <span>Liste des écheances :</span>
        <span className="float-right">
          Montant global : <b> {total} </b>
        </span>
      </h5>
      <br />
      <Form
        form={echeanceForm}
        name="echeanceForm"
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
      >
        <Form.List name="echeances">
          {(fields) => (
            <Row>
              {echeances.map((echeance, index) => {
                return (
                  <>
                    <Col span={1}>
                      <Form.Item
                        label=" "
                        name={[echeance.echeance_id, "echeance_id"]}
                        initialValue={echeance.echeance_id}
                      >
                        <Tag color="#108ee9" className="mt-2 ">
                          {index + 1}
                        </Tag>

                        <Input size="large" type="hidden"  />
                      </Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item className="px-2 my-0">
                        <Form.Item
                          label="Libelle"
                          name={[echeance.echeance_id, "libelle"]}
                          rules={[
                            { required: true, message: "Champ obligatoire !" },
                          ]}
                          {...echeance}
                          initialValue={echeance.libelle}
                        >
                          <Input size="large" disabled={checkStatus(echeance.etat)} />
                        </Form.Item>
                      </Form.Item>
                    </Col>
                    <Col span={7} className="px-2">
                      <Form.Item
                        label="Montant"
                        name={[echeance.echeance_id, "montant"]}
                        rules={[
                          { required: true, message: "Champ obligatoire !" },
                        ]}
                        {...echeance}
                        initialValue={echeance.montant}
                      > 
                        <Input size="large" disabled={checkStatus(echeance.etat)} />
                      </Form.Item>
                    </Col>
                    <Col span={7} className="px-2">
                      <Form.Item
                        label="Date limite"
                        name={[echeance.echeance_id, "date_limite"]}
                        rules={[
                          { required: true, message: "Champ obligatoire !" },
                        ]}
                        {...echeance}
                        initialValue={moment(echeance.date_limite)}
                      >
                        <DatePicker
                          placeholder=""
                          size="large"
                          style={{ width: "100%" }}
                          disabled={checkStatus(echeance.etat)}
                        />
                      </Form.Item>
                    </Col>
                  </>
                );
              })}
            </Row>
          )}
        </Form.List>
        <Row className="d-flex justify-content-between">
          <h6>
            Montant global : <b> {total} </b>
          </h6>
          <span>
            <Button
              type="dashed"
              onClick={() => roundValues()}
              className="mx-2 px-4"
              disabled={isTouched}
            >
              Arrondir les valeurs
            </Button>
            <Button type="primary" htmlType="submit" className="mx-2 px-4" >
              Valider
            </Button>
          </span>
        </Row>
      </Form>
    </Spin>
  );
};
export default EcheanceForm;
