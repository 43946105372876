import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;
const sendData = async (data, id, idModule) => {
  let y;
  let res = [];
  for (let i = 0; i < data.length; i++) {
    let formData = new FormData();

    formData.append("file", data[i]);
    y = await axios.post(
      API_URL + "upload/" + id + "/" + idModule,
      formData,

      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      { headers: authHeader() }
    );
    res.push(y);
  }
  return res;
};
const upload = async (file, id, idModule) => {
  console.log(file);
  let response = await sendData(file, id, idModule);
  return response;
};

const getFiles = () => {
  return axios.get(API_URL + "files", { headers: authHeader() });
};
const downloadRandomImage = (fileName) => {
  return getFile(fileName.file_id).then((response) => {
    // const filename = response.headers.split("filename=")[1];

    let url = window.URL.createObjectURL(new Blob(response.data));
    let a = document.createElement("a");
    a.href = url;
    a.download = fileName.name;
    a.click();
  });
};
const getFile = async (fileId, idModule) => {
  let x = await axios.get(API_URL + "files/" + fileId + "/" + idModule, {
    responseType: "arraybuffer",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/pdf",
    },
  });
  return x;
};
const deleteFile = (file, idModule) => {
  return axios.delete(API_URL + "files/" + file.file_id + "/" + idModule, {
    headers: authHeader(),
  });
};
export default {
  upload,
  downloadRandomImage,
  getFiles,
  getFile,
  deleteFile,
};
