import authHeader from "./auth-header";
import axios from "axios";
import { environment } from "../env/config.env";
import moment from "moment";

const API_URL = environment.api;

const updatEcheances = ( echeance ) => {
  return axios.post(API_URL + "echeance" , echeance, { headers: authHeader() });
};
const testPaiement = () => {
  const id = 2
  const paiement = {
    montant:200,
    libelle:"paiement",
    description:"une desc",
    datePaiement: moment("2022-09-05").toDate(),
    dateEncaissement:moment("2022-09-05").toDate(),
    methodePaiement:3,
  }
  return axios.post(API_URL + "paiement/"+ id , paiement , { headers: authHeader() });
}

const echeancePaiement = {
    updatEcheances,
    testPaiement
};
export default echeancePaiement;